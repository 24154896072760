import { useState, useEffect } from 'react'
import 'rc-slider/assets/index.css'
import { Row } from 'components'
import { images } from 'assets'
import { formatDecimal, formatWholeNumber, getSliderValue, Mixpanel } from 'utils'
import LinqtoSlider from 'components/Global/LinqtoSlider'
import { useSelector } from 'react-redux'

const OrderDetails = ({ setAmount, setShares, shares, sliderIncrement, sliderMinimum, sliderMaxIndex, offers, setSharePrice, onTierPress, totalSavings, setTotalSavings, totalTillNextTier, setTotalTillNextTear, isPreferred, setIsPreferred, tiers, setTiers, name, isFirstOffer, discountPercent, setDiscountPercent, discountedAmount, setDiscountedAmount }) => {
  const [sliderIndex, setSliderIndex] = useState(0)
  const [amountUsd, setAmountUsd] = useState(sliderMinimum)
  const { details } = useSelector(state => state.investSlice)
  const { discountTiers } = details || {}

  useEffect(() => {
    if (sliderIndex === 0) updateSliderIndex(0)
  }, [])
  // This is called when someone drags the slider AND when someone clicks the Plus/Minus buttons
  const updateSliderIndex = (index, type = '') => {
    // If it is out of bounds, don't allow it
    if (index < 0 || index > sliderMaxIndex) {
      return
    }
    const sliderVal = getSliderValue({ offers, sliderMinimum, sliderIncrement }, index)
    if (type !== '') {
      Mixpanel.track('Click Slider', { Type: type, 'Company Name': name })
    }
    // Set the values
    setAmountUsd(sliderVal.amount)
    setAmount(sliderVal.amount)
    setSliderIndex(index)
    setShares(sliderVal.shares)
    setSharePrice(sliderVal.sharePrice)
    setTotalTillNextTear(sliderVal.totalTillNextTier)
    setTotalSavings(sliderVal.totalSavings)
    setIsPreferred(sliderVal.isPreferred)
    setTiers(sliderVal.tiers)

    const discount = discountPercent > 0 ? Math.floor((sliderVal?.amount * discountPercent) * 100) / 100 : 0
    setDiscountedAmount(discount)
    handleDiscountConversion(sliderVal)
  }

  const handleDiscountConversion = sliderVal => {
    const findCurrentDiscountTier = discountTiers?.filter((tier) => tier?.minimumPurchase < sliderVal.amount)
    if (findCurrentDiscountTier && findCurrentDiscountTier.length === 1) {
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (findCurrentDiscountTier?.[0]?.percent) {
        setDiscountedAmount(Math.floor((sliderVal.amount * findCurrentDiscountTier?.[0]?.percent) * 100) / 100)
        setDiscountPercent(findCurrentDiscountTier?.[0]?.percent)
      } else if (findCurrentDiscountTier?.[0]?.amount) {
        setDiscountedAmount(Math.floor(findCurrentDiscountTier[0].amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else if (findCurrentDiscountTier && findCurrentDiscountTier.length > 1) {
      const newDiscountTier = findCurrentDiscountTier[findCurrentDiscountTier.length - 1]
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (newDiscountTier?.percent) {
        setDiscountedAmount(Math.floor((sliderVal.amount * newDiscountTier?.percent) * 100) / 100)
        setDiscountPercent(newDiscountTier?.percent)
      } else if (newDiscountTier?.amount) {
        setDiscountedAmount(Math.floor(newDiscountTier.amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else {
      setDiscountedAmount(0)
      setDiscountPercent(0)
    }
  }

  let savingsCopy = ''

  if (isPreferred) {
    savingsCopy = <div className='invest-order-row__tier-price__copy-container'>
      <img src={images['team-apollo-black']} height={16} width={16} />
      <span>Team Apollo Savings</span>
    </div>
  } else {
    savingsCopy = <span>Tiered Pricing Savings</span>
  }

  const plusBtnDisabled = sliderIndex >= sliderMaxIndex
  const minusBtnDisabled = sliderIndex <= 0

  return (
    <>
      <Row className='invest-order-row'>
        <span>Amount in USD</span>
        <span className='b_18_semibold'>{formatDecimal(amountUsd)}</span>
      </Row>
      <Row className='invest-order-row'>
        <span>Estimated shares</span>
        <span>{formatWholeNumber(shares)}</span>
      </Row>
      <Row className='invest-order-row slider-row'>
        <LinqtoSlider
          min={0}
          max={sliderMaxIndex}
          step={1}
          defaultValue={0}
          onChange={(val) => updateSliderIndex(val)}
          onAfterChange={() => Mixpanel.track('Click Slider', { Type: 'Slider', 'Company Name': name })}
          value={sliderIndex}
          onMinusBtnClick = {() => updateSliderIndex(sliderIndex - 1, 'Minus')}
          onPlusBtnClick = {() => updateSliderIndex(sliderIndex + 1, 'Plus')}
          disabled={false}
          plusBtnDisabled={plusBtnDisabled}
          minusBtnDisabled={minusBtnDisabled}
        />
      </Row>
      {(totalTillNextTier > 0 || totalSavings > 0 || discountedAmount > 0) && <div className='invest-order-row__separator'/>}
      {(totalTillNextTier > 0 || totalSavings > 0) && <Row className='invest-order-row__tier-price'>
        <div className='invest-order-row__tier-price__left'>
          {savingsCopy}
          <span className={`b_14_regular invest-order-row__tier-price__left__link${tiers?.length >= 1 && !isFirstOffer ? '__not-clickable' : ''}`} onClick={onTierPress}>{formatDecimal(totalTillNextTier, true, 0)} away from next pricing tier!</span>
        </div>
        <span className='invest-order-row__tier-price__right'>{totalSavings > 0 && '-'}{formatDecimal(totalSavings)}</span>
      </Row>}
      {(discountPercent > 0 || discountedAmount > 0) && <Row className='invest-order-row top-aligned'>
        <span>Promo Discount<span className='b_14_regular promo-text'> (Applied on next screen)</span></span>
        <span className='invest-order-row__tier-price__right'>
          {discountPercent > 0 && `-${discountPercent * 100}% (${formatDecimal(discountedAmount)})`}
          {!discountPercent && discountedAmount > 0 && `-${formatDecimal(discountedAmount)}`}
        </span>
      </Row>}
    </>
  )
}

export default OrderDetails
