import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import parseOrders from 'actions/parsing/parseOrders.js'
import parsingPortfolio from 'actions/parsing/parsingPortfolio.js'
import api from '../apis/private.js'
import { Mixpanel } from 'utils'

const initialState = {
  recentOrders: [],
  companies: [],
  company: {},
  filterOrders: [],
  totalInvestmentValue: 0,
  pageLoading: true,
  exitedCompanies: [],
  canSellShares: false,
  activeOffers: [],
  entities: [],
  soldCompanies: [],
  totalBalance: 0,
  cashPaymentEnabled: false,
  needToCompleteInvestorProfile: false,
  hasWalletDeposit: false,
  totalCashAvailable: 0,
  userCashAvailable: 0,
  total: 0,
  announcements: []
}

export const getPortfolio = createAsyncThunk(
  'getPortfolio',
  (_, { dispatch, fulfillWithValue }) => {
    return api
      .get('/page/portfolio')
      .then((res) => {
        const {
          companies,
          recentOrders,
          totalInvestmentValue,
          isLoggedIn,
          exitedCompanies,
          entities,
          soldCompanies,
          cashPaymentEnabled,
          needToCompleteInvestorProfile,
          hasWalletDeposit,
          totalCashAvailable,
          userCashAvailable,
          total,
          announcements
        } = res.data
        Mixpanel.setPeople({ 'Is Investor': totalInvestmentValue > 0 })
        Mixpanel.track('View Portfolio Page')
        return fulfillWithValue({
          companies,
          totalInvestmentValue,
          recentOrders: parseOrders(recentOrders),
          isLoggedIn,
          exitedCompanies,
          entities,
          soldCompanies,
          cashPaymentEnabled,
          needToCompleteInvestorProfile,
          hasWalletDeposit,
          totalCashAvailable,
          userCashAvailable,
          total,
          announcements
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const getProductOrders = createAsyncThunk(
  'getProductOrders',
  ({ id }, { dispatch, fulfillWithValue }) => {
    return api
      .get(`/page/portfolio/${id}`)
      .then((res) => {
        return fulfillWithValue(parsingPortfolio(res.data))
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolio.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getPortfolio.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getPortfolio.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getProductOrders.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getProductOrders.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getProductOrders.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default portfolioSlice.reducer
