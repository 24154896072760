import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/private.js'
import parseConfirmOrder from 'actions/parsing/parseConfirmOrder'
import parseErrorMessages from 'actions/parsing/parseErrorMessages'
import parseOrderConfirmed from '../actions/parsing/parseOrderConfirmed'

const initialState = {
  pageLoading: false,
  confirmOrderLoading: false,
  company: {
    companyId: 0,
    iconUrl: '',
    logoUrl: '',
    name: '',
    url: ''
  },
  documents: [
    {
      name: '',
      url: ''
    }
  ],
  isAccredited: true,
  isUpholdOtpRequired: true,
  order: {
    amount: 0,
    entityName: '',
    linqtoBucksUsed: 0,
    linqtoFee: 0,
    msToLive: 0,
    paymentType: 'WIRE',
    sharePrice: 0,
    shares: 0,
    temporaryUpholdTransactionId: 0,
    total: 0,
    upholdCardCurrency: '',
    upholdCardId: '',
    upholdCardLabel: '',
    upholdCommission: 0,
    upholdExchangeRate: 0,
    upholdFee: 0,
    riaName: '',
    promoCode: null,
    isPromoCodeValid: null
  },
  placeOrderError: {
    type: '',
    message: ''
  },
  commitLoading: false,
  orderId: 0,
  portfolio: null,
  accStatus: '',
  accounts: [],
  maxOrderLinqtoBucksPercent: 0,
  linqtoBucks: 0
}

export const confirmOrder = createAsyncThunk('confirmOrder', ({ upholdId, companyId, type, amount, entityId, useLinqtoBucks, shares, promoCode }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const body = {
    companyId: companyId,
    paymentType: type,
    upholdCardId: upholdId,
    amount,
    useLinqtoBucks,
    shares: shares,
    promoCode
  }

  if (entityId !== 'individual') {
    body.entityId = entityId
  }
  return api
    .post('/page/confirmOrder', body)
    .then((res) => {
      return fulfillWithValue(parseConfirmOrder(res.data))
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        return rejectWithValue(parseErrorMessages(data))
      } else {
        dispatch(handleServerError(err.response.data.error))
        return rejectWithValue(err.response)
      }
    })
})

export const commitOrder = createAsyncThunk('commitOrder', ({ companyId, type, amount, temporaryUpholdTransactionId, upholdOtpToken, entityId, useLinqtoBucks, shares }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const body = {
    companyId,
    amount,
    paymentType: type,
    upholdOtpToken,
    temporaryUpholdTransactionId,
    useLinqtoBucks: useLinqtoBucks,
    shares
  }
  if (entityId !== 'individual') {
    body.entityId = entityId
  }
  return api
    .post('/page/confirmOrder/commit', body)
    .then(res => {
      return fulfillWithValue({ orderId: res.data.orderId })
    })
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        let order = {}
        if (data.amount) {
          order = { amount: data.amount, sharePrice: data.sharePrice, shares: data.shares }
        }
        return rejectWithValue({ ...parseErrorMessages(data), order })
      } else {
        dispatch(handleServerError(err.response.data.error))
        return rejectWithValue(err.response)
      }
    })
})

export const getOrderConfirmation = createAsyncThunk('getOrderConfirmation', ({ orderId }, { dispatch }) => {
  return api.get(`/page/orderConfirmed/${orderId}`)
    .then(response => parseOrderConfirmed(response.data))
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const placeOrderSlice = createSlice({
  name: 'placeOrderSlice',
  initialState,
  reducers: {
    updateUpholdRequired: (state) => {
      state.isUpholdOtpRequired = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmOrder.pending, (state) => {
        if (state?.order?.paymentType === 'UPHOLD') {
          state.pageLoading = false
        } else {
          state.pageLoading = true
        }
        state.confirmOrderLoading = true
      })
      .addCase(confirmOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          confirmOrderLoading: false,
          ...payload,
          placeOrderError: payload.order?.isPriceChange ? parseErrorMessages({ error: 'PLACE_ORDER_PARAMETERS_CHANGED' })?.placeOrderError : {}
        }
      })
      .addCase(confirmOrder.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          confirmOrderLoading: false,
          ...payload
        }
      })
      .addCase(commitOrder.pending, (state) => {
        state.commitLoading = true
      })
      .addCase(commitOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          commitLoading: false,
          ...payload
        }
      })
      .addCase(commitOrder.rejected, (state, { payload }) => {
        return {
          ...state,
          commitLoading: false,
          ...payload
        }
      })
      .addCase(getOrderConfirmation.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getOrderConfirmation.fulfilled, (state, { payload }) => {
        return { ...state, ...payload, pageLoading: false }
      })
      .addCase(getOrderConfirmation.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export const { updateUpholdRequired } = placeOrderSlice.actions

export default placeOrderSlice.reducer
