import { Modal } from 'components'

const UnicornMosaicModal = ({ setShowModal }) => {
  const hideModal = () => {
    setShowModal(false)
  }
  return (
    <Modal mode='primary' size='md' innerStyle='mosaic-modal' hideModal={hideModal} modalHeader='What is a Mosaic Score?' clickToClose pressToClose crossToClose>
      <div className='mosaic-modal-web gray3'>
        <div className='mosaic-modal-section b_18_regular'>
          Mosaic score predicts the health and future success potential of private tech companies using 4 models:
          <span className='b_18_semibold'>{' '}Momentum,</span>
          <span className='b_18_semibold'>{' '}Market,</span>
          <span className='b_18_semibold'>{' '}Money,</span> and
          <span className='b_18_semibold'>{' '}Management.</span>
        </div>
        <span className='b_18_semibold'>{' '}Momentum</span> is informed by news/media, sentiment, partnership & customer momentum, and social media.
        <span className='b_18_semibold'>{' '}Market</span> refers to the quality of the market - is it a hot industry? The
        <span className='b_18_semibold'>{' '}Money</span> model assesses the financial health of a company.  And the
        <span className='b_18_semibold'>{' '}Management</span> model centers around the founding and management team and their work
        accomplishments, like previous exits, funding rounds, industry experience, as well as their educational background and network.
      </div>
    </Modal>
  )
}

export default UnicornMosaicModal
