import Breadcrumb from 'components/Global/Breadcrumb'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { getSellOfferDetail } from 'slices/sellOfferSlice'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SellOfferHeader from './partials/SellOfferHeader'
import SellOfferCompletedSales from './SellOfferSummary/SellOfferCompletedSales'
import SellOfferSummaryContainer from './SellOfferSummary/SellOfferSummaryContainer'

const SellOfferDetail = () => {
  const dispatch = useDispatch()
  const { companyUrlName, companyName, pageLoading, completedOrders } = useSelector(state => state.sellOffer)
  const { id } = useParams()
  const items = [{ name: 'My Portfolio', route: '/portfolio' }, { name: companyName, route: `/portfolio/${companyUrlName}` }]

  useEffect(() => {
    dispatch(getSellOfferDetail(id)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Sell Offer Details Page (ATS)', { 'Company Name': payload.companyName })
      }
    })
  }, [id])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Offer Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Offer Confirmed')} />
      <div className='page-container sell-offer-container'>
        <div className='inner-container'>
          <Breadcrumb items={items} />
          <SellOfferHeader title='Sell Offer Details' showLearnMoreLink />
          <div className='sell-offer-page-container'>
            <SellOfferSummaryContainer
              id={id}
              titleCopy='Offers remain active until cancelled or sold. All sales are final.'
              offerCreated
              isActiveSellOffer
            />
            {completedOrders && completedOrders.length ? <SellOfferCompletedSales /> : null}
            <div className='b_18_regular space-above-sm'>
            Have a question about your offer?{' '}
              <Link onClick={() => Mixpanel.track('Click Contact Us', { Location: 'Sell Offer Details' })}
                to={`/contact?topic=Order Support&message=Re: Sell Offer ID ${id}`}
                className='link primary contact-us'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellOfferDetail
