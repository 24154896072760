import ButtonGroup from '../ButtonGroup'
import RadioButton from 'components/Global/Inputs/RadioButton'
import { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { saveAccreditedInvestorStatusChoice, getInvestorStatus } from 'slices/investorStatusSlice'
import UKResidentModal from './UKResidentModal'
import { ukResidentModalDetails } from '../../data'
import { Mixpanel } from 'utils'

const UKResidentsSection = () => {
  const [show, setShow] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const methods = useForm()
  const dispatch = useDispatch()
  const { watch, register, handleSubmit } = methods
  const choice = watch('choice')
  const investorOption = watch('investorOption')
  const confirmVerification = watch('confirm')

  const {
    accreditedCountry
  } = useSelector((state) => state.investorStatusSlice)

  useEffect(() => {
    if (choice === 'NOT_ACCREDITED') {
      setDisabled(false)
    }

    if (investorOption && confirmVerification) {
      setDisabled(false)
    }

    if (investorOption && !confirmVerification && choice !== 'NOT_ACCREDITED') {
      setDisabled(true)
    }
  }, [choice, investorOption, confirmVerification])

  const onSubmit = async data => {
    Mixpanel.track('Onboarding Click Submit Verification', { 'Accreditation Method': 'Auto', 'Accredited Country': accreditedCountry, 'Accreditation Status': investorOption })
    await dispatch(saveAccreditedInvestorStatusChoice({ choice: data.investorOption }))
    await dispatch(getInvestorStatus())
  }

  return (
    <>
      {show && <UKResidentModal hideModal={() => setShow(false)} data={ukResidentModalDetails.find(d => d.key === investorOption)} />}
      <FormProvider {...methods}>
        <form className='uk-residents-section' onSubmit={handleSubmit(onSubmit)}>
          <div className='header'>Choose your accreditation status</div>
          <select className='select' {...register('choice')}>
            <option value=''>Please select an option</option>
            <option value='SELF_CERTIFIED'>Self-certified</option>
            {/* <option value='NOT_ACCREDITED' >Not accredited (limited experience)</option> */}
          </select>
          {choice === 'SELF_CERTIFIED' &&
            <div>
              <span className='b_22_semibold uk-residents-section__self-certified-options'>I am a self-certified investor <span className='eu-residents-container__experienced-investor-options b_18_regular'>(please choose one option):</span></span>
              <div className='radio-group radio-button-container'>
                <RadioButton name='investorOption' value='EXPERIENCED_INVESTOR' label='Sophisticated Investor (most common)' />
                <RadioButton name='investorOption' value='HIGH_NETWORTH' label='High Net Worth Individual' />
              </div>
            </div>}
          {investorOption && choice !== 'NOT_ACCREDITED' &&
          <>
            <div className='content-separator'></div>
            <div className='input-group checkbox'>
              <label className='b_16_regular checkbox-label'>
                <input type='checkbox' {...register('confirm')} />
                <div className='checkmark' />
                <span className='b_18_regular checkbox-label__text'>
                  I confirm I have read the&nbsp;<span className='b_18_semibold checkbox-label__link' onClick={() => setShow(true)}>verification statement</span>&nbsp;and I am qualified to invest.
                </span>
              </label>
            </div>
          </>
          }
          {/* {choice === 'NOT_ACCREDITED' && <div className='accreditation-terms'>
            In order to comply with regulations, some or all investment opportunities may not be available to non-accredited investors.  If you meet the qualifications in the future, you can update your accreditation status in your investor profile.
          </div>} */}
          <div className='btn-group'>
            <ButtonGroup loading={false} disabled={disabled}/>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export default UKResidentsSection
