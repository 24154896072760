import { images } from 'assets'

const ScheduleMeetingsButton = ({
  dismissScheduleMeeting,
  scheduleMeeting,
  text
}) => {
  return (
    <div className='schedule-meetings-button-wrapper'>
      <span
        className='schedule-meetings-close'
        onClick={dismissScheduleMeeting}
      >
        <img
          alt='Close Schedule Meetings'
          src={images['schedule-meetings-close']}
        />
      </span>
      <div className='schedule-meetings-button' onClick={scheduleMeeting}>
        <img
          alt='Schedule Meetings Calendar'
          src={images['schedule-meetings-calendar']}
        />
        <span>{text}</span>
      </div>
    </div>
  )
}

export default ScheduleMeetingsButton
