import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { formatDecimal, formatWholeNumber, Mixpanel, renderTotalsOrDash } from 'utils'
import 'rc-slider/assets/index.css'
import NumberInput from 'components/Global/Inputs/NumberInput'
import { useFormContext } from 'react-hook-form'
import LinqtoSlider from 'components/Global/LinqtoSlider'
import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import ReactHtmlParser from 'react-html-parser'

const SellOfferShares = ({ calculateTotals, disabled, subTotal, disableSharesToSell, minimumSellOfferAmount, sliderIndex, setSliderIndex, disableSharesToSellInput, calculateMinimumShareAmount }) => {
  const { setValue, watch, clearErrors } = useFormContext()
  const shareOwningAccount = watch('shareOwningAccount')
  const { sellableShareCount } = shareOwningAccount || {}
  const { companyName, shareOwningAccounts, minimumTotalSellPrice } = useSelector((state) => state.sellOffer)
  const sliderMaxIndex = sellableShareCount

  useEffect(() => {
    if (shareOwningAccounts.length === 1) {
      setSliderIndex(shareOwningAccounts?.[0]?.sellableShareCount)
    }
  }, [])

  // handle clearing sharesForAccount error if slider amount > sellableShareCount
  useEffect(() => {
    if (+watch('shareOwningAccount.sellableShareCount') >= minimumSellOfferAmount) {
      clearErrors('sharesForAccount')
    }
  }, [+watch('shareOwningAccount.sellableShareCount'), minimumSellOfferAmount, sliderIndex])

  const updateSliderIndex = (index, type = '') => {
    if (index < 1 || index > sliderMaxIndex) {
      return
    }
    if (!disableSharesToSell) {
      setSliderIndex(+index)
      setValue('sharesForAccount', index)
    }
    calculateTotals()
    if (type !== '') {
      Mixpanel.track('Click Slider', { Type: type, 'Company Name': companyName })
    }
  }

  const validateShareValues = (inputVal) => {
    let updatedValue = inputVal
    if (inputVal > sellableShareCount) {
      updatedValue = sellableShareCount
    } else if (inputVal <= 0) {
      updatedValue = 1
    }
    setValue('sharesForAccount', updatedValue)
    setSliderIndex(+updatedValue)
    calculateTotals()
  }

  const marketLastSoldPrice = +watch('marketLastSoldPrice')
  const max = sellableShareCount > 0 ? sellableShareCount : 1000
  const min = disableSharesToSell ? 1 : minimumSellOfferAmount
  const plusBtnDisabled = sliderIndex >= max
  const minusBtnDisabled = sliderIndex <= 1 || subTotal - minimumTotalSellPrice < marketLastSoldPrice

  const { activeOfferId } = shareOwningAccount || {}

  // Minimum Copy Logic
  let minimumCopy = null
  if (shareOwningAccount && disableSharesToSell) {
    minimumCopy = `All shares must be sold when total is less than ${formatDecimal(minimumTotalSellPrice, true, 0)}`
  } else if (shareOwningAccount && (sliderIndex === minimumSellOfferAmount)) {
    minimumCopy = `Minimum sell offer is ${formatDecimal(minimumTotalSellPrice, true, 0)}`
  } else {
    minimumCopy = null
  }

  return (
    <div className='sell-offer-section'>
      <div className='b_18_semibold sell-offer-section-title'>
        <span>2</span> Choose shares to sell
      </div>
      <div className='sell-offer-order'>
        <div className='box invest-order-box'>
          {shareOwningAccounts && shareOwningAccounts.length > 1 ? <><div className='sell-offer-row'>
            <span className='dark'>Account</span>
            <span className='dark'>
              <select onChange={(e) => {
                const shareOwningAccount = shareOwningAccounts.find(account => account.accountOwnerName === e.target.value) || null
                const marketLastSoldPrice = +watch('marketLastSoldPrice')
                setValue('shareOwningAccount', shareOwningAccount)
                if (shareOwningAccount) {
                  setValue('sharesForAccount', shareOwningAccount.sellableShareCount)
                  setSliderIndex(shareOwningAccount.sellableShareCount)
                } else {
                  setSliderIndex(null)
                  setValue('sharesForAccount', null)
                }
                const minShareAmount = calculateMinimumShareAmount(marketLastSoldPrice, shareOwningAccount?.sellableShareCount || 0, minimumTotalSellPrice)
                disableSharesToSellInput(marketLastSoldPrice * shareOwningAccount?.sellableShareCount || 0 || 0, minShareAmount === shareOwningAccount?.sellableShareCount || 0 || 0)
              }}>
                <option value={''}>Select Account</option>
                {shareOwningAccounts.map((account, i) => (
                  <option key={i} value={account.accountOwnerName}>{account.accountOwnerName}</option>
                ))}
              </select>
            </span>
          </div>
          {activeOfferId && <ErrorMessage message={ReactHtmlParser(`*You already have an <a class="link primary"
            href=${`/offer/${activeOfferId}`}>active offer</a> for this investment with this account. Each investment under this account can only have one active sell offer at a time.`)} />}
          </> : null}
          <div className='sell-offer-row'>
            <span className='dark'>Available Shares</span>
            <span className='dark'>
              {renderTotalsOrDash(sellableShareCount, formatWholeNumber(sellableShareCount))}
            </span>
          </div>
          <div className='sell-offer-row'>
            <span className='dark'>Shares to Sell</span>
            <span className='input'>
              <NumberInput
                min={+watch('shareOwningAccount.sellableShareCount') <= minimumSellOfferAmount ? 0 : minimumSellOfferAmount}
                isDecimal
                disabled={disabled || disableSharesToSell}
                name='sharesForAccount'
                ariaLabel='sharesForAccount'
                handleKeyPress={(e) => {
                  if (e.key === '.' || e.key === '-') {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  updateSliderIndex(e.target.value)
                }}
                onBlur={(e) => {
                  validateShareValues(e.target.value)
                }}
              />
            </span>
          </div>
          {minimumCopy && <div className='sell-offer-row'><span>{minimumCopy}</span></div>}
          <div className='slider-row'>
            <LinqtoSlider
              min={min}
              max={max}
              step={1}
              onChange={(val) => updateSliderIndex(val)}
              onAfterChange={() => Mixpanel.track('Click Slider', { Type: 'Slider', 'Company Name': companyName })}
              value={sliderIndex}
              onMinusBtnClick = {() => updateSliderIndex(+sliderIndex - 1, 'Minus')}
              onPlusBtnClick = {() => updateSliderIndex(+sliderIndex + 1, 'Plus')}
              disabled={disabled || disableSharesToSell}
              plusBtnDisabled={plusBtnDisabled}
              minusBtnDisabled={minusBtnDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellOfferShares
