import Button from 'components/Global/Button'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ButtonGroup = ({ loading, disabled, onClick }) => {
  const history = useHistory()
  const { accreditedStatus } = useSelector((state) => state.investorStatusSlice)

  const redirectToProfile = () => {
    // change action if user is on web or app/webview
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToProfile')
    } else {
      if (accreditedStatus === 'ACCREDITED') {
        return history.push('/profile')
      }
      history.push('/profile?redirect=/investor-status')
    }
  }

  return (
    <div className='btn-group right'>
      <Button mode='tertiary' onClick={redirectToProfile} disabled={loading}>Back</Button>
      <Button loading={loading} disabled={disabled} onClick={onClick} type='submit'>
        Submit
      </Button>
    </div>
  )
}

export default ButtonGroup
