import RadioButton from 'components/Global/Inputs/RadioButton'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

const AccreditationOptions = ({ setShowModal }) => {
  const { loading } = useSelector((state) => state.userSlice)
  const {
    formState: { errors }
  } = useFormContext()

  const AccrediationTerms = () => {
    return (
      <>
        <span>
          I meet
          <span onClick={() => setShowModal(true)}>
            <a> accreditation terms.</a>
          </span>
        </span>
      </>
    )
  }

  return (
    <div className='sixteen wide column'>
      <div className='signup-accreditation'>
        <strong>Please select your accreditation status:</strong>
        {errors?.accreditOption && <div className='b_16_semibold err-msg'>{errors.accreditOption.message}</div>}
        <div className='radio-group'>
          <div className='radio-group'>
            <RadioButton
              name='accreditOption'
              label={<AccrediationTerms />}
              value='isAccredited'
              disabled={loading}
            />
            <RadioButton
              name='accreditOption'
              label='I am not accredited.'
              value='notAccredited'
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccreditationOptions
