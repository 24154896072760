import { images } from 'assets'

const AccountBanner = ({ hideBanner, bannerAction }) => {
  return (
    <div className='account-banner-container'>
      <div className='account-banner-header'>
        <div className='heading_7'>Account created!</div>
        <span className='account-banner-close' onClick ={() => hideBanner('accountBanner')}>
          <img className='about-hero' alt='Close' src={images['banner-close']} />
        </span>
      </div>
      <div className='account-banner-body'>
        <div>In order to invest,  please complete your investor profile.</div>
        <a className='account-banner-link' onClick ={() => bannerAction('accountBanner')}>Start now</a>
      </div>
    </div>
  )
}

export default AccountBanner
