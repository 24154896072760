import { useSelector } from 'react-redux'
import CopyLinkInput from 'components/Global/Inputs/CopyLinkInput'
import { Mixpanel } from 'utils'

const AddClientsLink = () => {
  const { riaReferralUrl } = useSelector((state) => state.ria)
  const trackCopy = () => {
    Mixpanel.track('Copy Advisor Referral Link')
  }
  return (
    <div className='add-new-client-link-container'>
      <div className='heading_7'>Add Clients</div>
      <div className='add-new-client-link'>
        <div className='add-new-client-link-copy'>
          <div className='b_18_regular'>
            Add clients by sending them this link. Once they sign up and agree
            to be linked, you will see them in the list above.
          </div>
        </div>
        <div className='add-new-client-link-link'>
          <CopyLinkInput value={riaReferralUrl} label='New Client Link' onClick={trackCopy} />
        </div>
      </div>
    </div>
  )
}

export default AddClientsLink
