const RewardHistoryColumns = () => {
  return (
    <div className='nonclickable-grid-container__columns rewards_history'>
      <span>DATE</span>
      <span>EVENT</span>
      <span className='align-right'>AMOUNT</span>
      <span>NOTE</span>
    </div>
  )
}

export default RewardHistoryColumns
