import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import parsingInvestorStatus from 'actions/parsing/parsingInvestorStatus.js'
import api from '../apis/private.js'
import axios from 'axios'
import FormData from 'form-data'

const initialState = {
  accreditedAt: '',
  accreditedCountry: '',
  accreditedStatus: '',
  RDocuments: [],
  ODocuments: [],
  selfAccreditation: '',
  uploadingStatus: 'init',
  requestStatus: 'init',
  hasRequestedFinancialAdvisor: false,
  allowDocumentDelete: true,
  crdNumber: 0,
  uploadErr: false,
  isSignedIn: false,
  hasKyc: false,
  isUserProfileComplete: false,
  pageLoading: false,
  loading: false,
  verifiedDocument: '',
  accreditedCountrySource: '',
  accreditationRegion: '',
  isRetailPurchaser: false,
  email: '',
  legalName: '',
  initialValues: {
    crdNumber: ''
  }
}

export const getInvestorStatus = createAsyncThunk(
  'getInvestorStatus',
  (refreshStatus = '', { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/accreditedInvestorStatus')
      .then((res) => {
        return fulfillWithValue(parsingInvestorStatus(res.data), { refreshStatus })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const saveSelfAccreditation = createAsyncThunk(
  'saveSelfAccreditation',
  (formValue, { dispatch, rejectWithValue }) => {
    return api
      .post('/page/accreditedInvestorStatus/selfAccreditation', formValue)
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const saveSecurityLicense = createAsyncThunk(
  'saveSecurityLicense',
  (formValue, { dispatch, rejectWithValue }) => {
    return api
      .post('/page/accreditedInvestorStatus/securitiesLicense', formValue)
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const uploadDocument = createAsyncThunk(
  'uploadDocument',
  (file, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const data = new FormData()
    data.append('file', file[0])
    data.append('name', file.documentType)

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/page/accreditedInvestorStatus/documents`,
      data: data,
      headers: { hostname: window.location.hostname }
    }
    if (window.location.hostname === 'localhost') {
      config.headers = { access_token: localStorage.getItem('linqto_token') }
    }
    return axios(config)
      .then((res) => {
        return fulfillWithValue(parsingInvestorStatus(res.data))
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const deleteDocument = createAsyncThunk(
  'deleteDocument',
  (id, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .delete('/page/accreditedInvestorStatus/documents', { data: { id } })
      .then((res) => {})
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const requestReview = createAsyncThunk(
  'requestReview',
  (_, { dispatch, rejectWithValue }) => {
    return api
      .post('/page/accreditedInvestorStatus/documents/requestReview')
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)
export const saveAccreditedInvestorStatusChoice = createAsyncThunk('saveAccreditedInvestorStatusChoice', (data, { dispatch, rejectWithValue }) => {
  return api.post('/page/accreditedInvestorStatus/accreditate', data)
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const requestFinancialAdvisor = createAsyncThunk(
  'requestFinancialAdvisor',
  (formValue, { dispatch, rejectWithValue }) => {
    return api
      .post('/financialAdvisorRequest', formValue)
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const InvestorStatusSlice = createSlice({
  name: 'investorStatusSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInvestorStatus.pending, (state, { meta }) => {
        if (meta.arg === 'noRefresh') {
          state.pageLoading = false
        } else {
          state.pageLoading = true
        }
      })
      .addCase(getInvestorStatus.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload,
          initialValues: { crdNumber: payload.crdNumber }
        }
      })
      .addCase(getInvestorStatus.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(saveSelfAccreditation.pending, (state) => {
        state.loading = true
      })
      .addCase(saveSelfAccreditation.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(saveSelfAccreditation.rejected, (state) => {
        state.loading = false
      })
      .addCase(saveSecurityLicense.pending, (state) => {
        state.loading = true
      })
      .addCase(saveSecurityLicense.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(saveSecurityLicense.rejected, (state) => {
        state.loading = false
      })
      .addCase(requestReview.pending, (state) => {
        state.loading = true
      })
      .addCase(requestReview.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(requestReview.rejected, (state) => {
        state.loading = false
      })
      .addCase(saveAccreditedInvestorStatusChoice.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(saveAccreditedInvestorStatusChoice.fulfilled, (state) => {
        state.pageLoading = false
      })
      .addCase(saveAccreditedInvestorStatusChoice.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(requestFinancialAdvisor.pending, (state) => {
        state.requestStatus = 'loading'
      })
      .addCase(requestFinancialAdvisor.fulfilled, (state) => {
        state.requestStatus = 'success'
      })
      .addCase(requestFinancialAdvisor.rejected, (state) => {
        state.requestStatus = 'failed'
      })
  }
})

export default InvestorStatusSlice.reducer
