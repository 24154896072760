import ProductDocuments from 'components/OrderDetail/partials/ProductDocuments'
import { useSelector } from 'react-redux'

const Agreements = () => {
  const { documents } = useSelector(
    (state) => state.placeOrderSlice
  )
  return (
    <div className='sell-offer-section'>
      <div className='heading_7 sell-offer-section-title'>
        <span>3</span> Agreements
      </div>
      <div className='sell-offer-order'>
        <ProductDocuments documents={documents} type='order-confirmation' />
      </div>
    </div>
  )
}

export default Agreements
