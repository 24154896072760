import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import parseRewardHistory from 'actions/parsing/parseRewardHistory'
import api from '../apis/private'

const initialState = {
  transactions: [],
  linqtoBucks: '',
  referralUrl: '',
  signUpBonus: '',
  firstOrderBonus: '',
  significantInvestorMinimum: '',
  significantInvestorPercent: ''
}

export const getRewards = createAsyncThunk('getRewards', (_, { dispatch }) => {
  return api
    .get('/page/rewards')
    .then(res => {
      const transactions = (res.data.transactions) ? parseRewardHistory(res.data.transactions) : {}
      return { ...res.data, transactions }
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRewards.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getRewards.fulfilled, (_, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getRewards.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default rewardsSlice.reducer
