import { useSelector } from 'react-redux'
import { formatDecimal, formatWholeNumber } from 'utils'

const InvestmentDetails = () => {
  const { order } = useSelector(
    (state) => state.placeOrderSlice
  )
  const { sharePrice, shares, amount } = order

  return (
    <div className='sell-offer-section wih-margin'>
      <div className='heading_7 sell-offer-section-title'>
        <span>1</span> Investment Details
      </div>
      <div className='sell-offer-order'>
        <div className='box invest-order-box'>
          <div className='sell-offer-row'>
            <span>Price Per Share</span>
            <span>
              {formatDecimal(sharePrice)}
            </span>
          </div>
          <div className='sell-offer-row spaced'>
            <span>Estimated Shares*</span>
            <span>{formatWholeNumber(shares)}</span>
          </div>
          <div className='sell-offer-row'>
            <span>Amount</span>
            <span className='b_18_semibold'>{formatDecimal(amount)}</span>
          </div>
        </div>
        <div className='sell-offer-copy'> *You are purchasing the number of units in the series that represents the equivalent number of shares identified. </div>
      </div>
    </div>
  )
}

export default InvestmentDetails
