import { MFANotes } from '../../../data'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  sendSecurityPageMfa
} from 'slices/securitySlice'

const MfaModalNotes = ({
  steps,
  type,
  interval,
  userMfaId
}) => {
  const [sentTimeStamp, setSentTimeStamp] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const { submitOTPLoading } = useSelector(
    (state) => state.securitySlice
  )
  const dispatch = useDispatch()

  const handleSendAgain = () => {
    if (submitOTPLoading) {
      return
    }
    clearInterval(interval)
    const date = new Date()
    setSentTimeStamp(date.getTime())
    setCurrentTime(date.getTime())
    dispatch(sendSecurityPageMfa(userMfaId))
    interval = setInterval(() => {
      const newDate = new Date()
      setCurrentTime(newDate.getTime())
    }, 1000)
  }

  const renderResend = () => {
    const seconds = Math.floor((currentTime - sentTimeStamp) / 1000)
    if (sentTimeStamp === 0 || seconds > 30) {
      return <span className='b_16_regular' onClick={handleSendAgain}>Click to send again.</span>
    } else if (seconds < 30 || seconds === 30) {
      return (
        <span className='b_16_regular time-counter'>
          resent {Math.floor((currentTime - sentTimeStamp) / 1000)}s ago
        </span>
      )
    }
  }

  if ((type === 'sms' && steps === 2) || type === 'email') {
    return (
      <div className='b_16_semibold note'>
        {MFANotes[type]} {renderResend()}
      </div>
    )
  }
  return null
}

export default MfaModalNotes
