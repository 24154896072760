import { useState } from 'react'
import Tooltip from 'components/Global/Tooltip'
import { useSelector } from 'react-redux'
import { formatDecimal, formatShares } from 'utils'
import moment from 'moment'

const SellOfferSummaryContainer = ({ id = null, titleCopy = '', offerCreated = false, isActiveSellOffer = false }) => {
  const [showHover, setShowHover] = useState(false)
  const [showFlatFeeHover, setFlatFeeShowHover] = useState(false)
  let { sharePrice, shareCount, subTotal, transactionFee, transactionFeeRate, totalProceeds, projectedGain, offerStatus, createdAt, deactivatedAt, transactionFlatFee, accountOwnerName } = useSelector(state => state.sellOffer)

  // format values
  sharePrice = formatDecimal(sharePrice)
  shareCount = formatShares(shareCount)
  subTotal = formatDecimal(subTotal)
  transactionFee = formatDecimal(transactionFee)
  transactionFlatFee = formatDecimal(transactionFlatFee)
  totalProceeds = formatDecimal(totalProceeds)
  projectedGain = formatDecimal(projectedGain)

  let bottomContainer = null
  const statusLabel = offerStatus === 'Canceled' ? 'Cancelled' : offerStatus

  if (isActiveSellOffer) {
    bottomContainer = (
      <div className='sell-offer-summary-container__row line-above'>
        <div>
          <p className='sell-offer-summary-container__row__copy bold'>Projected Proceeds</p>
          {offerStatus !== 'Canceled' && <p className='sell-offer-summary-container__row__copy light'>To be deposited in your cash account</p>}
        </div>
        <span className='sell-offer-summary-container__row__copy bold'>{totalProceeds}</span>
      </div>
    )
  } else {
    bottomContainer = <>
      <div className='sell-offer-summary-container__row'>
        <div>
          <p className='sell-offer-summary-container__row__copy bold'>Total Proceeds</p>
          {offerStatus !== 'Canceled' && <p className='sell-offer-summary-container__row__copy light'>To be deposited in your cash account</p>}
        </div>
        <span className='sell-offer-summary-container__row__copy bold'>{totalProceeds}</span>
      </div>
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Projected Gain</span>
        <span className='sell-offer-summary-container__row__copy'>{projectedGain}</span>
      </div>
    </>
  }

  return (
    <div className='sell-offer-summary-container'>
      {offerStatus !== 'Pending' && <div className={`sell-offer-summary-container__badge ${offerStatus?.toLowerCase()}`}>
        <span>{statusLabel}</span>
      </div>}
      <span className='sell-offer-summary-container__copy'>{titleCopy}</span>
      {id && <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Offer ID</span>
        <span className='sell-offer-summary-container__row__copy'>{id}</span>
      </div>}
      {offerCreated && <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Offer Created</span>
        <span className='sell-offer-summary-container__row__copy'>{moment(createdAt).format('MM/DD/YYYY')}</span>
      </div>}
      { offerStatus === 'Canceled' && deactivatedAt && <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Cancelled on</span>
        <span className='sell-offer-summary-container__row__copy'>{moment(deactivatedAt).format('MM/DD/YYYY')}</span>
      </div>}
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Account</span>
        <span className='sell-offer-summary-container__row__copy'>{accountOwnerName}</span>
      </div>
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Asking price per share (USD)</span>
        <span className='sell-offer-summary-container__row__copy'>{sharePrice}</span>
      </div>
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Shares</span>
        <span className='sell-offer-summary-container__row__copy'>{shareCount}</span>
      </div>
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Subtotal</span>
        <span className='sell-offer-summary-container__row__copy'>{subTotal}</span>
      </div>
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>
          Transaction Fee
          <Tooltip content={`This ${transactionFeeRate}% fee supports the maintenance of Linqto’s private market place.`} showHover={showHover} setShowHover={setShowHover} />
        </span>
        <span className='sell-offer-summary-container__row__copy'>-{transactionFee}</span>
      </div>
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>
        Regulatory Filing Fee
          <Tooltip content='This flat fee helps cover local and federal regulatory filing expenses required for sales of private equity. If your offer sells across multiple orders, this fee is applied prior to distributing proceeds.' showHover={showFlatFeeHover} setShowHover={setFlatFeeShowHover} />
        </span>
        <span className='sell-offer-summary-container__row__copy'>-{transactionFlatFee}</span>
      </div>
      {bottomContainer}
    </div>
  )
}

export default SellOfferSummaryContainer
