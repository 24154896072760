import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/private.js'
import axios from 'axios'

const initialState = {
  pageLoading: false,
  title: '',
  dropDownLoading: false,
  countries: [],
  regions: [],
  downloadUrl: '',
  isLoggedIn: false,
  submitButtonLoading: false,
  uploadErr: false,
  contentCards: [],
  investContentCards: [],
  portfolioContentCards: [],
  isMixpanelExist: false
}

export const getCountries = createAsyncThunk(
  'getCountries',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/countries')
      .then((res) => {
        if (res && res.data) {
          return fulfillWithValue(res.data)
        }
        return []
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getRegions = createAsyncThunk(
  'getRegions',
  (country, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/regions?countryName=${country}`)
      .then((res) => {
        if (res && res.data) {
          return fulfillWithValue(res.data)
        }
        return []
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const uploadDocument = createAsyncThunk('uploadDocument', ({ acceptedFile, fileName, type, query, func }, { fulfillWithValue, rejectWithValue }) => {
  let apiEndPoint = ''
  switch (type) {
  case 'AI':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/accreditedInvestorStatus/documents`
    break
  case 'FA':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/financialAdvisor/documents`
    break
  case 'ID':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/kycStatus/documents`
    break
  case 'USER_UPLOADED':
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/userDocuments`
    break
  default:
    apiEndPoint = `${process.env.REACT_APP_API_URL}/page/accreditedInvestorStatus/documents`
  }
  const token = localStorage.getItem('linqto_token')
  let headers = type !== 'AI' && query
  if (type === 'AI' && window.location.hostname === 'localhost') {
    headers = { access_token: token }
  }
  const data = new FormData()
  data.append('file', acceptedFile)
  data.append('name', fileName)

  const config = {
    method: 'post',
    url: apiEndPoint,
    headers: { ...headers, hostname: window.location.hostname },
    onUploadProgress: (progressEvent) => {
      func(progressEvent)
    },
    data: data
  }
  return axios(config)
    .then(res => {
      return fulfillWithValue(res.data)
    })
    .catch(err => {
      return rejectWithValue(err.response)
    })
})

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    resetUploadErr: (state) => {
      state.uploadErr = false
    },
    setContentCards: (state, { payload }) => {
      if (payload && payload.length) {
        payload.forEach((card) => {
          if (Object.keys(card.extras)?.length > 0) {
            if (card?.extras?.invest_feed_web) {
              state.investContentCards = [...state.investContentCards, card]
            } else if (card?.extras?.portfolio_feed_web) {
              state.portfolioContentCards = [...state.portfolioContentCards, card]
            }
          }
        })
      }
      state.contentCards = [...payload]
    },
    setMixpanelExist: (state) => {
      state.isMixpanelExist = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.dropDownLoading = true
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        return { ...state, dropDownLoading: false, countries: payload }
      })
      .addCase(getCountries.rejected, (state) => {
        state.dropDownLoading = false
      })
      .addCase(getRegions.pending, (state) => {
        state.dropDownLoading = true
      })
      .addCase(getRegions.fulfilled, (state, { payload }) => {
        return { ...state, dropDownLoading: false, regions: payload }
      })
      .addCase(getRegions.rejected, (state) => {
        state.dropDownLoading = false
      })
      .addCase(uploadDocument.pending, (state) => {
        state.submitButtonLoading = true
      })
      .addCase(uploadDocument.fulfilled, (state, { payload }) => {
        return { ...state, submitButtonLoading: false, ...payload }
      })
      .addCase(uploadDocument.rejected, (state) => {
        state.submitButtonLoading = false
        state.uploadErr = true
      })
  }
})
export const { resetUploadErr, setContentCards, setMixpanelExist } = commonSlice.actions
export default commonSlice.reducer
