import UnicornDetailsFundingRounds from './UnicornDetailsFundingRounds'
import UnicornDetailsCompetitorFundings from './UnicornDetailsCompetitorFundings'
import UnicornDetailsCompetitorFundingsOther from './UnicornDetailsCompetitorFundingsOther'
import { formatNumberWithLetter } from 'utils'

const UnicornDetailsFunding = ({ company, isLoggedIn }) => {
  const blueStyle = !isLoggedIn ? 'blur-info' : ''
  return (
    <>
      <div className='unicorn-details-section section-seperator section-line-seperator'>
        <div className='grid'>
          <div className='row'>
            <div className='column sixteen unicorn-details-column'>
              <label>Revenue:</label>
              <div className={blueStyle}>{company.estimatedRevenue ? formatNumberWithLetter(company.estimatedRevenue, true, true) : '-'}</div>
            </div>
          </div>
        </div>
      </div>
      {company.fundingRounds && company.fundingRounds.length > 0 &&
        <div className='unicorn-details-section'>
          <div className='unicorn-details-column'>
            <label className='light'>Funding Rounds</label>
            <UnicornDetailsFundingRounds fundingRounds={company.fundingRounds} style={blueStyle}/>
          </div>
        </div>
      }
      {company.competitorFundingsUnicrons && company.competitorFundingsUnicrons.length > 0 &&
        <div className='unicorn-details-section'>
          <div className='unicorn-details-column'>
            <label className='light'>Unicorn Competitors & Peer Funding</label>
          </div>
          <UnicornDetailsCompetitorFundings competitorFundings={company.competitorFundingsUnicrons} style={blueStyle}/>
        </div>
      }
      {company.competitorFundingsOther && company.competitorFundingsOther.length > 0 &&
        <div className='unicorn-details-section'>
          <div className='unicorn-details-column'>
            <label className='light'>Non-Unicorn Competitors & Peers</label>
          </div>
          <UnicornDetailsCompetitorFundingsOther competitorFundings={company.competitorFundingsOther} style={blueStyle}/>
        </div>
      }
    </>
  )
}

export default UnicornDetailsFunding
