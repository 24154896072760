const UnicornDetailsCompetitorFundingsOther = ({ competitorFundings, style }) => {
  return (
    <>
      <div className='desktop-content'>
        <div className='grid unicorn-table'>
          <div className='row '>
            <div className='column five unicorn-details-column' style={{ paddingLeft: 0 }}><strong>Companies</strong></div>
            <div className='column five unicorn-details-column '> { competitorFundings && competitorFundings.map((f, i) =>
              <div key={`competitorOthrtFundings_${i}`} className={`column ${style}`}>{f.companyName}</div>
            )}
            </div>
          </div>
        </div>
      </div>
      <div className='unicorn-card-container'>
        <div className='unicorn-card' >
          <div className='grid'>
            {competitorFundings && competitorFundings.map((f, i) =>
              <div className='row unicorn-card-row' key={`mcompetitorOthrtFundings_${i}`}>
                <div className={`column eight ${style}`}>{i === 0 && <strong>Companies: </strong>}</div>
                <div className={`column eight ${style}`}>{f.companyName}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default UnicornDetailsCompetitorFundingsOther
