import NavItem from './NavItem'
import DropDownItem from './DropDownItem'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

const WebNav = ({
  setShowDropdown,
  showDropdown,
  setToggleStatus,
  toggleStatus,
  items,
  filterNavByStatus
}) => {
  const { pathname } = useLocation()
  return (
    <ul className='main-nav'>
      {items.map((item, index) => {
        if (item.submenu && item.submenu.length > 0) {
          return (
            <DropDownItem
              item={item}
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              key={`d-${item.tag}${index}`}
              filterNavByStatus={filterNavByStatus}
              isActive={!!(item?.submenu?.find(i => !!(i.route === pathname)))}
            />
          )
        }
        return (
          <NavItem
            item={item}
            setToggleStatus={setToggleStatus}
            toggleStatus={toggleStatus}
            key={`w-${item.tag}${index}`}
          />
        )
      })}
    </ul>
  )
}

export default WebNav
