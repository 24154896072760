import { useEffect, useState } from 'react'
import { PageLoading, Wrapper, ErrorModal } from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/orderConfirmationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import qs from 'qs'
import errorTypes from 'types/errorTypes'
import { Mixpanel, seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  confirmOrder,
  commitOrder,
  updateUpholdRequired
} from 'slices/placeOrderSlice'
import ReactGA from 'react-ga4'
import OrderConfirmationForm from './partials/OrderConfirmationForm'
import OrderConfirmationHeader from './partials/OrderConfirmationHeader'
import InvestmentDetails from './partials/InvestmentDetails'
import Agreements from './partials/Agreements'
import Payment from './partials/Payment '
import AddFundsLink from './partials/AddFundsLink'

const OrderConfirmation = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    order,
    placeOrderError,
    company
  } = useSelector((state) => state.placeOrderSlice)

  const query = location.search
  const { amount, shares, type, companyId, companyName, minimumInvestmentAmount, urlName, discountedAmount, discountNote } = qs.parse(query, { ignoreQueryPrefix: true })

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState({})
  const [useLinqtoBucks, setUeLinqtoBucks] = useState(false)
  const [promoCode, setPromoCode] = useState(null)
  const [promoCodeError, setPromoCodeError] = useState(null)
  const changedOrderParameters = {}

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    refreshOrder(true, useLinqtoBucks)
  }, [])

  useEffect(() => {
    Mixpanel.track('View Order Review Confirmation', {
      'Company Name': companyName,
      'Minimum investment amount': minimumInvestmentAmount,
      'Amount selected': amount,
      'Estimated shares': shares,
      'Promotion Discount amount': discountedAmount,
      'Promotion ID': discountNote
    })
  }, [])

  useEffect(() => {
    let timerId
    // Create a timeout to refresh the page data when the Order's expiration has been reached
    const { msToLive } = order
    if (msToLive) {
      timerId = setTimeout(() => {
        refreshOrder()
      }, msToLive)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [order])

  const updateOrderValues = ({ amount, shares }) => {
    changedOrderParameters.amount = amount
    changedOrderParameters.shares = shares
    const newurl = `/orderconfirmation/${location.pathname.split('/orderconfirmation/')[1]}?companyId=${companyId}&companyName=${companyName}&amount=${amount}&type=${type}&useLinqtoBucks=${useLinqtoBucks}&shares=${shares}&minimumInvestmentAmount=${minimumInvestmentAmount}&urlName=${urlName}`
    window.history.pushState({ path: newurl }, '', newurl)
  }
  const refreshOrder = (showPageLoader = true, linqtoBucks = false, promoCode = null) => {
    const upholdId = location.pathname.split('/orderconfirmation/')[1]
    if (showPageLoader) { setPageLoading(true) }
    dispatch(
      confirmOrder({
        upholdId,
        companyId,
        type,
        amount: changedOrderParameters?.amount || amount,
        entityId: selectedAccount?.entityId,
        useLinqtoBucks: linqtoBucks,
        shares: changedOrderParameters?.shares || shares,
        promoCode
      })
    ).then(({ meta, payload }) => {
      if (showPageLoader) { setPageLoading(false) }
      if (meta.requestStatus === 'fulfilled') {
        if (Object.keys(selectedAccount).length === 0)setSelectedAccount(payload?.accounts?.[0])
        if (payload.order?.isPriceChange) {
          updateOrderValues({ amount: payload?.order?.amount, shares: payload?.order?.shares })
          setShowErrorModal(true)
        }
        if (payload?.order?.promoCode) {
          Mixpanel.track('Click Apply Promo Code', { 'Promo Code': promoCode, 'Promo Code Results': payload?.order?.isPromoCodeValid ? 'Pass' : 'Fail' })
          setPromoCodeError(!payload?.order?.isPromoCodeValid)
        } else {
          setPromoCode('')
        }
      } else if (meta.requestStatus === 'rejected') {
        if (
          payload?.placeOrderError?.type ===
          'PLACE_ORDER_USER_PROFILE_NOT_COMPLETE'
        ) {
          Mixpanel.track('View Incomplete Profile Purchase Modal')
        } else {
          Mixpanel.track('Purchase Error', {
            'Error Type': payload?.placeOrderError?.type
          })
        }
        setShowErrorModal(true)
      }
    })
  }
  const hanldeCommitOrder = (formValues) => {
    let body = {
      companyId,
      type: order.paymentType,
      amount: order.amount,
      entityId: selectedAccount.entityId || 'individual',
      useLinqtoBucks: useLinqtoBucks,
      shares: shares
    }
    if (order.paymentType === 'UPHOLD') {
      body = {
        ...body,
        temporaryUpholdTransactionId: order.temporaryUpholdTransactionId,
        upholdOtpToken: formValues.upholdOTP
      }
    }

    dispatch(commitOrder(body)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        const orderId = payload.orderId
        ReactGA.event('purchase', {
          action: 'PURCHASE',
          label: `User purchase ${company.name} `,
          value: order.amount,
          transaction_id: orderId,
          currency: 'USD',
          linqto_bucks_used: order.linqtoBucksUsed || 0,
          items: [
            {
              item_id: companyId,
              item_name: company.name,
              currency: 'USD',
              item_category: order.paymentType,
              quantity: shares
            }
          ]
        })
        history.push(
          `/orderconfirmed/${orderId}?amount=${amount}&shares=${shares}&minimumInvestmentAmount=${minimumInvestmentAmount}&companyName=${company.name}&type=${order.paymentType}`
        )
      } else if (meta.requestStatus === 'rejected') {
        if (payload?.placeOrderError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED') {
          refreshOrder(false)
          updateOrderValues({ amount: payload?.order?.amount, shares: payload?.order?.shares })
        }

        Mixpanel.track('Purchase Error', {
          'Error Type': payload?.placeOrderError?.type
        })
        setShowErrorModal(true)
      }
    })
  }

  const closeModal = () => {
    let path
    switch (placeOrderError.type) {
    case errorTypes.PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_SHARES:
    case errorTypes.UPHOLD_INSUFFICIENT_FUNDS:
    case errorTypes.UPHOLD_INSUFFICIENT_UNLOCKED_FUNDS:
      history.goBack()
      break
    case errorTypes.UPHOLD_OTP_REQUIRED:
      dispatch(updateUpholdRequired())
      break
    case errorTypes.UPHOLD_OTP_INCORRECT:
      methods?.setError('upholdOTP', {
        type: 'Opt is incorrect',
        message: 'Please enter a valid code.'
      })
      break
    case errorTypes.NOT_LOGGED_IN_TOKEN_INVALID:
      path = '/signin'
      localStorage.setItem('prev-route', `/products/${urlName}`)
      break
    case errorTypes.PLACE_ORDER_EXCEEDED_NON_ACCREDITED_WIRE_ORDERS_CAP:
      path = '/investor-status'
      break
    case errorTypes.PLACE_ORDER_SANCTIONED_COUNTRY:
      path = '/contact'
      break
    case 'PLACE_ORDER_PARAMETERS_CHANGED':
      path = null
      break
    case 'Internal Server Error':
      path = `/products/${urlName}`
      break
    default:
      path = null
    }
    setShowErrorModal(false)
    if (path) {
      history.push(path)
    }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Confirm Order')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Confirm Order')} />
      <Wrapper>
        <div className='page-container sell-offer-container buy-order-container'>
          <div className='inner-container'>
            <OrderConfirmationHeader />
            <InvestmentDetails />
            <Payment
              setSelectedAccount={setSelectedAccount}
              selectedAccount={selectedAccount}
              useLinqtoBucks = {useLinqtoBucks}
              setUeLinqtoBucks={setUeLinqtoBucks}
              refreshOrder={refreshOrder}
              promoCode={promoCode}
              setPromoCode= {setPromoCode}
              promoCodeError = {promoCodeError}
              setPromoCodeError = {setPromoCodeError}
            />
            <AddFundsLink selectedAccount={selectedAccount}/>
            <Agreements />
            <FormProvider {...methods}>
              <OrderConfirmationForm hanldeCommitOrder={hanldeCommitOrder} selectedAccount={selectedAccount}/>
            </FormProvider>
          </div>
        </div>
        {showErrorModal && (
          <ErrorModal
            error={placeOrderError}
            hideModal={closeModal}
            crossToClose = {true}
            message='OK'
            centered={placeOrderError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED'}
          />
        )}
      </Wrapper>
    </>
  )
}

export default OrderConfirmation
