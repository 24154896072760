import AppleSignin from 'react-apple-signin-auth'
import { images } from 'assets'
import { Mixpanel } from 'utils'

const AppleSignInBtn = ({ copy, onOAuthSuccess }) => {
  return (
    <AppleSignin
      authOptions={{
        clientId: 'com.linqto.web.auth',
        scope: 'email name',
        redirectURI: window.location.origin,
        state: 'state',
        nonce: 'nonce',
        usePopup: true
      }}
      uiType='dark'
      noDefaultStyle={false}
      onSuccess={(response) => {
        let userInfo = response?.user?.name
        if (userInfo) {
          localStorage.setItem('appleAuthData', JSON.stringify(response))
        } else {
          const appleAuthData = localStorage.getItem('appleAuthData')
          userInfo = JSON.parse(appleAuthData)?.user?.name
        }
        onOAuthSuccess(response?.authorization.id_token, 'Apple', userInfo?.firstName, userInfo?.lastName)
      }}
      onError={(error) => console.error(error)}
      skipScript={false}
      iconProp={{ style: { marginTop: '10px' } }}
      render={(props) => <div className='b_16_semibold signup-oath-btn apple-oath-btn' {...props} onClick={() => {
        Mixpanel.track(`Click Apple OAuth on Sign ${copy === 'Sign in with  Apple' ? 'In' : 'Up'} Page`)
        props.onClick()
      }}><img
          src={images['apple-logo']}
        />{copy}</div>}
    />
  )
}

export default AppleSignInBtn
