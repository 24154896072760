import { App, GoogleRecaptcha } from 'components'
import ReactDOM from 'react-dom'
import './assets/scss/style.scss'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import history from './history'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { store } from './store'
import { GoogleOAuthProvider } from '@react-oauth/google'
const env = {
  'www.linqto.com': 'production',
  'app.linqto.com': 'production',
  'stage.linqto.com': 'stage',
  'beta.linqto.com': 'beta',
  'app.beta.linqto.com': 'beta',
  'alpha.linqto.com': 'alpha',
  'app.alpha.linqto.com': 'alpha',
  'dev.linqto.com': 'dev',
  'app.dev.linqto.com': 'dev'
}
// NOTE:init and configure sentry.io: https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#attach-stacktrace
if (env[window.location.host] !== 'dev') {
  Sentry.init({
    dsn: 'https://c3ef68580daf4804936dd7d14b6bc746@o1209309.ingest.sentry.io/6348375',
    integrations: [new BrowserTracing()],
    environment: env[window.location.host],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0
  })
}

const googleClientId = env[window.location.host] === 'dev' ? '1964738591-0upb2a0nh0bc1vqnpbhbqrl1kd5losor.apps.googleusercontent.com' : '268198152363-llf1st1jhvnq1gg4r14u54jmf3ajh3b5.apps.googleusercontent.com'

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <GoogleRecaptcha>
          <App />
        </GoogleRecaptcha>
      </GoogleOAuthProvider>
    </Router>
  </Provider>
  ,
  document.getElementById('root')
)
