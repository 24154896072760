import { useSelector } from 'react-redux'
import RewardHistoryColumns from './RewardHistoryColumns'
import RewardHistoryItem from './RewardHistoryItem'

const RewardHistory = () => {
  const { transactions } = useSelector(state => state.rewards)

  return (
    <div className='nonclickable-grid-container'>
      <div className='b_18_semibold nonclickable-grid-container__title'>My Bucks History</div>
      {transactions.length === 0 && (
        <div className='nonclickable-grid-container__no-data heading_7'>You don&apos;t have any reward transactions yet.</div>
      )}
      {transactions.length > 0 && (
        <div className='nonclickable-grid-container__list'>
          <RewardHistoryColumns />
          {transactions.map((t, i) => (
            <RewardHistoryItem key={i} transaction={t} />
          ))}
        </div>
      )}
    </div>
  )
}

export default RewardHistory
