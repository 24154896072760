import { Wrapper } from 'components'
import InvestDetailAboutHeader from './InvestDetailAboutHeader'
import InvestDetailAboutKeyFacts from './InvestDetailAboutKeyFacts'
import { useSelector } from 'react-redux'
const InvestDetailAbout = () => {
  const { details: { keyFacts } } = useSelector(state => state.investSlice)
  return (
    <Wrapper className='invest-tab' id='about'>
      <div className='grid outer-container'>
        <InvestDetailAboutHeader />
        <div className='row outer-container container-margin'>
          <div className='column sixteen'>
            {keyFacts &&
            <>
              <div className='subheader-title accessibleIndigo'>
                <h6>Key Facts</h6>
              </div>
              <div className='desc'>
                <InvestDetailAboutKeyFacts keyFacts={keyFacts} />
              </div>
            </>
            }
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailAbout
