import 'rc-slider/assets/index.css'
import { Wrapper, Content } from 'components'
import Button from 'components/Global/Button'
import OrderDetails from './OrderDetails'
import { useState } from 'react'
import PricingIndicatorModal from './PricingIndicatorModal'
import { useSelector, useDispatch } from 'react-redux'
import PlaceOrderHeader from './PlaceOrderHeader'
import Toggle from 'components/Global/Toggle'
import { toggleInterest } from 'slices/investSlice'
import { useHistory } from 'react-router-dom'
import { Mixpanel } from 'utils'
import InCompleteProfileSection from './InCompleteProfileSection'
import NotifyMeCopy from './NotifyMeCopy'
import StickyBeginOrderBtn from './StickyBeginOrderBtn'
import InCompleteAccreditationSection from './InCompleteAccreditationSection'

const PlaceOrder = ({ company, user, investAmount, setAmount, stickyHeader, setShares, shares, setSharePrice, sharePrice, totalTillNextTier, setTotalTillNextTear, totalSavings, setTotalSavings, isPreferred, setIsPreferred, tiers, setTiers, setShowErrorModal, discountPercent, setDiscountPercent, discountedAmount, setDiscountedAmount }) => {
  const [showTierModal, setShowTierModal] = useState(false)
  const { details } = useSelector(state => state.investSlice)
  const { featureFlags: { RetailPurchaseAllowed } } = useSelector(state => state.userSlice)
  const { isSoldOut, promoFlag, companyId, name, sliderMinimum, urlName, discountNote, offers, isInterested, retailPurchaseEnabled } = details
  const { isPreferredCustomer } = user || {}
  const { isProfileComplete, isAccredited, isRetailPurchaser } = details?.user || {}

  const dispatch = useDispatch()
  const history = useHistory()

  const firstOfferShares = offers?.[0]?.shares || 0
  const isFirstOffer = firstOfferShares >= shares

  const placeOrder = () => {
    if ((details?.user?.activeOfferId)) {
      setShowErrorModal(true)
    } else {
      Mixpanel.register({ 'Team Apollo': isPreferredCustomer })
      Mixpanel.track('Place Order Started', {
        'Minimum investment amount': sliderMinimum,
        'Amount selected': investAmount,
        'Estimated shares': shares,
        'Company Name': name
      })
      history.push({
        pathname: '/orderconfirmation/cash',
        search: `?companyId=${companyId}&companyName=${name}&amount=${investAmount}&type=CASH&shares=${shares}&minimumInvestmentAmount=${sliderMinimum}&urlName=${urlName}&discountedAmount=${discountedAmount}&discountNote=${discountNote}`
      })
    }
  }

  return (
    <Wrapper className='product-order invest-order'>
      {isSoldOut && <Toggle label={`Notify me ${promoFlag === 'COMING_SOON' ? 'when' : 'if'} this becomes available.`} handleChange={() => dispatch(toggleInterest({ companyUrlName: urlName, isInterested: !isInterested }))} checked={isInterested} /> }
      {showTierModal && tiers?.length >= 1 && isFirstOffer && <PricingIndicatorModal hideModal={() => setShowTierModal(false)} tiers={tiers} />}
      <Content className='box invest-order-box'>
        <PlaceOrderHeader sharePrice={sharePrice} />
        {!isSoldOut &&
          <OrderDetails
            {...company}
            {...user}
            setAmount={setAmount}
            setShares={setShares}
            shares={shares}
            setSharePrice={setSharePrice}
            onTierPress={() => isFirstOffer && setShowTierModal(true)}
            totalTillNextTier={totalTillNextTier}
            setTotalTillNextTear={setTotalTillNextTear}
            totalSavings={totalSavings}
            setTotalSavings={setTotalSavings}
            isPreferred={isPreferred}
            setIsPreferred={setIsPreferred}
            tiers={tiers}
            setTiers={setTiers}
            isFirstOffer={isFirstOffer}
            discountPercent={discountPercent}
            setDiscountPercent={setDiscountPercent}
            discountedAmount={discountedAmount}
            setDiscountedAmount={setDiscountedAmount}
          />}
        {!isSoldOut &&
          <Button
            customClass={`full-width ${stickyHeader ? 'sticky-content' : ''}` }
            onClick={placeOrder}
            disabled={!isProfileComplete || (!isAccredited && !isRetailPurchaser) || (!isAccredited && !RetailPurchaseAllowed) || (!isAccredited && !retailPurchaseEnabled) }
          >Begin Order</Button>
        }
        <InCompleteProfileSection />
        <InCompleteAccreditationSection />
        <NotifyMeCopy />
      </Content>
      <StickyBeginOrderBtn stickyHeader={stickyHeader} />
    </Wrapper>
  )
}
export default PlaceOrder
