import { useSelector } from 'react-redux'
import { formatDecimal, renderTotalsOrDash } from 'utils'
import Tooltip from '../../Global/Tooltip'
import { useState } from 'react'

const SellOfferTotals = ({
  subTotal,
  transactionFees,
  totalProceeds,
  costBasisOfShares,
  averageCost,
  projectedGain
}) => {
  const [showHover, setShowHover] = useState(false)
  const [showFlatFeeHover, setFlatFeeShowHover] = useState(false)
  const { transactionFeeRate, transactionFlatFee } = useSelector((state) => state.sellOffer)

  return (
    <div className='sell-offer-section flex-end'>
      <div className='sell-offer-totals sell-offer-order '>
        <div className='sell-offer-row'>
          <span>Subtotal</span>
          <span>{renderTotalsOrDash(subTotal, formatDecimal(subTotal))}</span>
        </div>
        <div className='sell-offer-row'>
          <span>
            Transaction Fee
            <Tooltip
              content={`This ${transactionFeeRate}% fee supports the maintenance of Linqto’s private market place.`}
              showHover={showHover}
              setShowHover={setShowHover}
            />
          </span>
          <span>{renderTotalsOrDash(transactionFees, formatDecimal(transactionFees), true)}</span>
        </div>
        <div className='sell-offer-row'>
          <span>
            Regulatory Filing Fee{' '}
            <Tooltip
              content='This flat fee helps cover local and federal regulatory filing expenses required for sales of private equity. If your offer sells across multiple orders, this fee is applied prior to distributing proceeds.'
              showHover={showFlatFeeHover}
              setShowHover={setFlatFeeShowHover}
            />
          </span>
          <span>{renderTotalsOrDash(transactionFlatFee, formatDecimal(transactionFlatFee), true)}</span>
        </div>
        <div className='sell-offer-row'>
          <span className='dark bold'>Total Proceeds</span>
          <span className='dark bold'>{renderTotalsOrDash(transactionFees, formatDecimal(totalProceeds))}</span>
        </div>
        <div className='sell-offer-row total-spaced'>
          <span>Cost Basis of Shares</span>
          <span>{renderTotalsOrDash(costBasisOfShares, formatDecimal(costBasisOfShares))}</span>
        </div>
        <div className='sell-offer-row'>
          <span>Average Cost / Share</span>
          <span>{renderTotalsOrDash(averageCost, formatDecimal(averageCost))}</span>
        </div>
        <div className='sell-offer-row'>
          <span>Your Projected Gain</span>
          <span>{renderTotalsOrDash(projectedGain, formatDecimal(projectedGain))}</span>
        </div>
        <div className='sell-offer-row spaced'>
          <span className='small'>
            Shares are sold in the order they were purchased.
          </span>
        </div>
      </div>
    </div>
  )
}

export default SellOfferTotals
