import React from 'react'
import '../../assets/scss/style.scss'

const SecondaryButton = ({ text, onClick }) => {
  return (
    <button className='b_18_semibold border-imaged-element secondary-button' onClick={onClick}>
      <span className='b_18_semibold key-facts__more-facts border-imaged-element accessibleIndigo'>{text}</span>
    </button>
  )
}

export default SecondaryButton
