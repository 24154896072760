import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import { seoTitleTemplate, Mixpanel, Braze } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import AddFundsModal from './partials/AddFundsModal'
import { PageLoading } from 'components'
import TransactionList from './partials/TransactionList'
import FBOTotalFunds from './partials/FBOTotalFunds'
import InCompleteProfileModal from './partials/InCompleteProfileModal'
import AccountErrorModal from './partials/AccountErrorModal'
import WithdrawModal from './partials/WithdrawModal'
import ContactTeamModal from './partials/ContactTeamModal'
import TopBanner from 'components/Global/TopBanner'
import UpholdWallet from './partials/UpholdWallet'
import {
  getCashAccount,
  getPlaidLinkToken,
  disconnectUphold,
  connectUphold,
  getLinqtoWallet,
  getPlaidAccounts,
  disconnectExternalAccount
} from 'slices/walletSlice'
import { getCoreUser } from 'slices/userSlice'
import Cookies from 'js-cookie'
import LinkedAccount from './partials/LInkedAccount/LinkedAccount'
import { usePlaidLink } from 'react-plaid-link'
import BankAccountModal from './partials/BankAccountModal'
import ReactHtmlParser from 'react-html-parser'
import UpholdWalletConnected from './partials/UpholdWalletConnected'

const AML_STATUS = {
  CS_NEEDS_REVIEW: 'Under Review',
  NEEDS_REVIEW: 'Under Review',
  APPROVED: 'Approved'
}

const FBOAccount = () => {
  const [loading, setLoading] = useState(false)
  const [showAddFundsModal, setShowAddFundsModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showBanner, setShowBanner] = useState(false)

  const [accountAction, setAccountAction] = useState(null)
  const [disconnectType, setDisconnectType] = useState('')
  const [showIncompleteProfileModal, setShowIncompleteProfileModal] =
    useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showPlaidErrorModal, setShowPlaidErrorModal] = useState(false)
  const [showPlaidUnderReviewModal, setShowPlaidUnderReviewModal] = useState(false)
  const { featureFlags } = useSelector(state => state.userSlice)
  const dispatch = useDispatch()

  const {
    ownerName,
    pageLoading,
    hasUphold,
    canAddFunds,
    isRetirementAccount,
    fundingFromUpholdAllowed,
    plaidLinkingAllowed,
    plaidLinkToken,
    pendingCashExternalAccounts,
    approvedCashExternalAccounts,
    upholdCards,
    upholdError,
    cashWithdrawalEnabled
  } = useSelector((state) => state.walletSlice)

  const location = useLocation()
  const history = useHistory()
  const query = location.search
  const entityId =
    qs.parse(query, { ignoreQueryPrefix: true })?.entityId || null

  useEffect(() => {
    dispatch(getCashAccount(entityId)).then(async ({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload.plaidLinkingAllowed && payload.canAddFunds) dispatch(getPlaidLinkToken(entityId))

        const isBankLinked = payload?.cashExternalAccounts?.filter((a) => a.amlStatus === 'APPROVED').length > 0 ? true
          : payload.cashExternalAccounts?.filter((a) => a.amlStatus === 'NEEDS_REVIEW').length > 0 ? 'Under Review' : false

        Mixpanel.track('View Cash Account page', {
          'Is Uphold Linked': payload.hasUphold ? payload.upholdError ? 'Needs Confirmation' : true : false,
          'Is Bank Account Linked': isBankLinked,
          'Account Type': entityId ? 'Entity' : 'Individual'
        })
        if (sessionStorage.getItem('connectUphold')) {
          if (!payload.upholdError) {
            if (payload.hasUphold) {
              Mixpanel.track('Account Successfully Linked', { 'Account Type': 'Uphold' })
              setAccountAction({ title: 'Uphold Successfully Linked', body: 'Your Uphold Account can now be used as a funding method.' })
              setShowBanner(true)
            }
          } else {
            setAccountAction({ title: 'Almost there!', body: `Please check your email. ${payload.upholdError}` })
            setShowBanner(true)
          }
          sessionStorage.removeItem('connectUphold')
        }

        if (localStorage.getItem('add-funds')) {
          if (localStorage.getItem('add-funds') === 'Uphold') {
            setAccountAction({ title: 'Deposit Complete', body: 'Your funds are now available.' })
          } else if (localStorage.getItem('add-funds').includes('Bank Account')) {
            if (featureFlags?.InstantAchFundingEnabled && localStorage.getItem('add-funds') !== 'Bank Account - PENDING') {
              setAccountAction({ title: 'Deposit Complete', body: 'Your funds are now available.' })
            } else {
              setAccountAction({ title: 'Deposit Initiated', body: 'ACH transfer funds become available in 1-3 business days. We\'ll email you when funds become available. ' })
            }
          }
          setShowBanner(true)
          localStorage.removeItem('add-funds')
        }
        if (localStorage.getItem('withdraw-funds')) {
          setAccountAction({ title: 'Withdraw Request Initiated', body: 'Your withdraw request is pending. Transfers typically take about 3 days.' })
          setShowBanner(true)
          localStorage.removeItem('withdraw-funds')
        }
        if (localStorage.getItem('add-manual-account')) {
          setAccountAction({ title: 'Account Pending', body: 'Your request has been received and can take up to 3 business days to review. We will email you when it is approved.' })
          setShowBanner(true)
          localStorage.removeItem('add-manual-account')
        }
      }

      if (history.action === 'POP') {
        dispatch(getCoreUser())
      }
    })
  }, [])

  const addFunds = () => {
    Mixpanel.track('Click Add Funds on Cash Account Page', { 'Can Add Funds': canAddFunds })
    Braze.track('Click Add Funds on Cash Account Page')
    if (canAddFunds && !isRetirementAccount) {
      history.push(`/cash-account/add-funds${entityId ? `?entityId=${entityId}` : ''}`)
    } else if (!canAddFunds && !isRetirementAccount) {
      Mixpanel.track('View Incomplete Profile Modal on Cash Account Page')
      setShowIncompleteProfileModal(true)
    } else if (!canAddFunds && isRetirementAccount) {
      setShowContactModal(true)
    }
  }

  const withdraw = () => {
    Mixpanel.track('Click Withdraw Funds on Cash Account Page')
    if (cashWithdrawalEnabled && (approvedCashExternalAccounts.length > 0 || featureFlags?.canManuallyCreateCashAccount)) {
      history.push(`/cash-account/withdraw${entityId ? `?entityId=${entityId}` : ''}`)
    } else {
      setShowWithdrawModal(true)
    }
  }

  const closeIncompleteModal = (type) => {
    setShowIncompleteProfileModal(false)
    if (type === 'mainBtn') {
      Mixpanel.track('Click Go to Investor Profile')
      history.push('/profile')
    }
  }

  const handleClick = () => {
    Mixpanel.track('Click Contact Us on Add Funds Retirement Modal')
    history.push(`/contact?topic=Cash Account Support&message=Re: Funding my ${ownerName} account. I'd like to make a deposit of $`)
  }

  const disconnectUpholdWallet = () => {
    window.scrollTo(0, 0)
    Mixpanel.track('Click Unlink Account', { 'Account Type': 'Uphold' })
    dispatch(disconnectUphold()).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Funding Account Unlinked', { 'Account Type': 'Uphold' })
        setShowBanner(true)
        setAccountAction({ title: 'Account Disconnected', body: 'This account will no longer be available as a funding method.' })
        dispatch(getLinqtoWallet())
      }
    })
  }

  const disconnectCashExternalAccount = async (body) => {
    window.scrollTo(0, 0)
    Mixpanel.track('Click Unlink Account', { 'Account Type': 'Bank Account' })
    dispatch(disconnectExternalAccount(body)).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Funding Account Unlinked', { 'Account Type': 'Bank Account' })
        setLoading(true)
        dispatch(getCashAccount(entityId)).then(({ meta }) => {
          if (meta.requestStatus === 'fulfilled') {
            setShowBanner(true)
            setAccountAction({ title: 'Account Disconnected', body: 'This account will no longer be available as a funding method.' })
            setLoading(false)
          }
        })
      }
    })
  }

  const connectUpholdWallet = () => {
    sessionStorage.setItem('connectUphold', true)
    Cookies.set('redirectPath', location.pathname)
    Mixpanel.track('Click Link Account', { 'Account Type': 'Uphold' })
    dispatch(connectUphold()).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        window.open(payload, '_self')
      }
    })
  }

  const onSuccess = useCallback(async (publicToken, metadata) => {
    window.scrollTo(0, 0)
    setLoading(true)
    const response = await dispatch(getPlaidAccounts({ publicToken: metadata.public_token, plaidAccountId: metadata.account_id, entityId }))
    setLoading(false)
    if (response.payload === 500) {
      setShowPlaidErrorModal(true)
    } else {
      setLoading(true)
      await dispatch(getCashAccount(entityId))
      setLoading(false)
      const { amlStatus } = response.payload
      if (amlStatus === 'NEEDS_REVIEW') {
        setShowPlaidUnderReviewModal(true)
      } else {
        Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Bank Account' })
        setShowBanner(true)
        setAccountAction({ title: 'Account(s) connected.', body: 'New account is now available as a funding method.' })
      }
    }
  }, [])

  const onExit = useCallback(() => {
    Mixpanel.track('Cancel Plaid Linking')
    setShowPlaidErrorModal(true)
  }, [])

  // Plaid configuration
  const config = {
    token: plaidLinkToken,
    onSuccess,
    onExit
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    Mixpanel.track('Click Link Account', { 'Account Type': 'Bank Account' })
    open()
  }

  if (pageLoading || loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Cash Account')} />
        <PageLoading />
      </>
    )
  }

  const handleDisconnect = (type) => {
    if (typeof type === 'object') {
      disconnectCashExternalAccount({ cashExternalAccountId: type.cashExternalAccountId })
    } else if (type === 'uphold') {
      disconnectUpholdWallet()
    }
  }

  return (
    <>
      {disconnectType && <BankAccountModal
        twoButtons
        title={`Disconnect ${disconnectType === 'uphold' ? 'Uphold' : 'Account'}`}
        copy={`Are you sure you want to disconnect ${disconnectType === 'uphold' ? 'Uphold' : `${disconnectType?.accountDescription}`} as a funding method?`}
        secondaryButtonCopy='Cancel'
        buttonCopy='Disconnect'
        handleSecondaryClick={() => setDisconnectType('')}
        handleClick={() => {
          handleDisconnect(disconnectType)
          setDisconnectType('')
        }}
        hideModal={() => setDisconnectType('')}
      />}
      {showPlaidUnderReviewModal && <BankAccountModal
        title='Review Required'
        copy={ReactHtmlParser(`It looks like the name on this account is not an exact match to the name on your Linqto account. Additional review is required before you can use this account to add funds.
        <br /><br />
        We will contact you once it has been reviewed (typically 1-2 days) and let you know if there is any more information required.`)}
        buttonCopy='Finish'
        handleClick={() => setShowPlaidUnderReviewModal(false)}
        hideModal={() => setShowPlaidUnderReviewModal(false)}
      />}
      {showPlaidErrorModal && <BankAccountModal
        title='Trouble Connecting to Bank'
        copy={ReactHtmlParser(`We are having trouble connecting to your bank and are unable to add your bank account at this time.<br /><br />
        Please come back and  try again later.`)}
        buttonCopy='Okay'
        handleClick={() => setShowPlaidErrorModal(false)}
        hideModal={() => setShowPlaidErrorModal(false)}
      />}
      <SeoMeta title={seoTitleTemplate(`Cash Account - ${ownerName}`)} />
      <div className='page-container wallet-container'>
        <div className='inner-container'>
          <div className='linqto-wallet-container'>
            {showBanner && (
              <TopBanner title={accountAction?.title} body={accountAction?.body} hideBanner={() => setShowBanner(false)} />
            )}
            <h1>Cash Account</h1>
            <div className='b_18_regular owner-name'>{ownerName}</div>
            <FBOTotalFunds addFunds={addFunds} withdraw={withdraw} />
            { canAddFunds && (((fundingFromUpholdAllowed || plaidLinkingAllowed) && !entityId) || (plaidLinkingAllowed && entityId)) &&
            <div className='uphold-wallet-container'>
              <div className='uphold-wallet-header'>
                <div className='b_18_semibold headline'>Linked Accounts</div>
              </div>
              {fundingFromUpholdAllowed && !entityId && (hasUphold && !upholdError) &&
                <UpholdWalletConnected
                  hasUphold={hasUphold}
                  upholdCards={upholdCards}
                  disconnectUpholdWallet={() => setDisconnectType('uphold')}
                />
              }
              {plaidLinkingAllowed && approvedCashExternalAccounts?.length > 0 && approvedCashExternalAccounts.map((account) => (
                <LinkedAccount
                  key={account.cashExternalAccountId}
                  connect={open}
                  disconnect={() => setDisconnectType(account)}
                  isConnected={account.amlStatus === 'APPROVED'}
                  title={account.accountDescription}
                  image='plaid-account-active'
                  linkCopy={AML_STATUS[account.amlStatus]}
                  disconnectCopy={account.amlStatus === 'APPROVED' ? 'Disconnect' : 'Under Review'}
                  copy={account.amlStatus === 'APPROVED'}
                />))}
              {fundingFromUpholdAllowed && !entityId && (!hasUphold || (hasUphold && upholdError)) &&
                <UpholdWallet
                  hasUphold={hasUphold}
                  upholdError={upholdError || ''}
                  connectUpholdWallet={connectUpholdWallet}
                  disconnectUpholdWallet={() => setDisconnectType('uphold')}
                />
              }
              {plaidLinkingAllowed && pendingCashExternalAccounts?.length > 0 && pendingCashExternalAccounts.map((account) => (
                <LinkedAccount
                  key={account.cashExternalAccountId}
                  connect={null}
                  disconnect={null}
                  isConnected={account.amlStatus === 'APPROVED'}
                  title={account.accountDescription}
                  image='plaid-account-under-review'
                  linkCopy={AML_STATUS[account.amlStatus]}
                  disconnectCopy={AML_STATUS[account.amlStatus]}
                  copy='Accounts are placed under review if the name on the bank account does not exactly match the name on the Linqto account. We will email once the review is complete, which typically takes 1-2 business days. '
                />))}
              {plaidLinkingAllowed && canAddFunds &&
                <LinkedAccount
                  connect={connectPlaid}
                  disconnect={disconnectUpholdWallet}
                  isConnected={false}
                  copy=''
                  title='Bank Account'
                  image='plaid-account-pending'
                  linkCopy='Add an Account'
                  disconnectCopy='Disconnect'
                  accountConntected={pendingCashExternalAccounts.length > 0 || approvedCashExternalAccounts.length > 0}
                />}
            </div>
            }
            <TransactionList />
          </div>
        </div>
      </div>
      {showAddFundsModal && (
        <AddFundsModal hideModal={() => setShowAddFundsModal(false)} />
      )}
      {showWithdrawModal && (
        <WithdrawModal hideModal={() => setShowWithdrawModal(false)} ownerName={ownerName}/>
      )}
      {showIncompleteProfileModal && (
        <InCompleteProfileModal hideModal={closeIncompleteModal} type='ADD_FUNDS' />
      )}
      {showErrorModal && (
        <AccountErrorModal hideModal={() => setShowErrorModal(false)} />
      )}
      {showContactModal && (
        <ContactTeamModal
          hideModal={() => setShowContactModal(false)}
          handleClick={handleClick}
        />
      )}
    </>
  )
}
export default FBOAccount
