import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { Modal } from 'components'
import { schema } from 'schemas/resetPasswordSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPasswordEmail } from 'slices/userSlice'
import ResetModalForm from './partials/ResetModalForm'
import ResetModalConfirmation from './partials/ResetModalConfirmation'
import { Mixpanel } from 'utils'
import ResetModalHeader from './partials/ResetModalHeader'

const ResetModal = ({ hideModal }) => {
  const [success, setSuccess] = useState(false)
  const dispatch = useDispatch()
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })

  const onSubmit = (formValues) => {
    dispatch(resetPasswordEmail(formValues)).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Submit Forgot Password Email')
        setSuccess(true)
      }
    })
  }

  return (
    <Modal
      mode='primary'
      size='md'
      modalHeader='Reset Password'
      innerStyle='reset-modal'
      hideModal={() => hideModal()}
      pressToClose
      crossToClose
    >
      {!success && (
        <FormProvider {...methods}>
          <ResetModalHeader />
          <ResetModalForm onSubmit={onSubmit} />
        </FormProvider>
      )}
      {success && <ResetModalConfirmation hideModal={hideModal} />}
    </Modal>
  )
}

export default ResetModal
