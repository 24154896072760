import { useSelector } from 'react-redux'

const UserAccrediationHeader = () => {
  const { accreditationCountry, clientFirstName, clientLastName } = useSelector((state) => state.ria)
  return (
    <>
      <h1>Verify Client Accreditaton</h1>
      <div className='b_18_regular'>
        Please select how your client qualifies as an accredited investor in
        <strong> {accreditationCountry}</strong>.
      </div>
      <div className='b_18_regular'>
        As the wealth advisor for  <strong>{clientFirstName} {clientLastName}</strong>, I attest that they:
      </div>
    </>
  )
}

export default UserAccrediationHeader
