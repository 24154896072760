import { useSelector } from 'react-redux'
import { formatDecimal } from 'utils'

const RewardHeader = () => {
  const { isLoggedIn, linqtoBucks } = useSelector(state => state.rewards)
  return (
    <div className='rewards-header'>
      <h1>Linqto Bucks Referral Program</h1>
      {isLoggedIn && <div className='heading_7 my-bucks '>My Bucks: {formatDecimal(linqtoBucks)}</div>}
    </div>
  )
}

export default RewardHeader
