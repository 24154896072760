import { images } from 'assets'

const TopBanner = ({
  hideBanner,
  bannerAction,
  title,
  body,
  showCTA = false
}) => {
  return (
    <div className='account-banner-container top-banner-contanier'>
      <div className='account-banner-header'>
        <div className='heading_7'>{title}</div>
      </div>
      <div className='account-banner-body'>
        <div>{body}</div>
        {showCTA && (
          <a className='account-banner-link' onClick={bannerAction}>
            Start now
          </a>
        )}
      </div>
      <span className='account-banner-close' onClick={hideBanner}>
        <img className='about-hero' alt='Close' src={images['banner-close']} />
      </span>
    </div>
  )
}

export default TopBanner
