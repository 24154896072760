import { useSelector } from 'react-redux'
const InvestDetailAboutHeader = () => {
  const { details } = useSelector(state => state.investSlice)
  const maxDescriptionLength = 620 // 6 lines & breaks into 2 praphas on the 7th line
  return (
    <div className='row'>
      <div className={`column sixteen desc ${details.description && details.description.length > maxDescriptionLength ? 'two-cols-text' : ''}`}>
        {details.description}
      </div>
    </div>
  )
}
export default InvestDetailAboutHeader
