import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

const SellOfferCTA = ({ onSubmit, disabled }) => {
  const history = useHistory()

  const { companyUrlName } = useSelector(
    (state) => state.sellOffer
  )

  const cancelSellOffer = () => {
    history.push(`/portfolio/${companyUrlName}`)
  }

  const {
    handleSubmit
  } = useFormContext()

  return (
    <div className='sell-offer-section flex-end'>
      <div className='sell-offer-cta'>
        <div className='btn-group two'>
          <Button
            mode='tertiary'
            onClick={() => cancelSellOffer()}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => handleSubmit(onSubmit)}
            disabled={disabled}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SellOfferCTA
