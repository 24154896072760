import { useEffect } from 'react'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import UserAccrediationHeader from './partials/UserAccrediationHeader'
import UserAccrediationReasons from './partials/UserAccrediationReasons'
import {
  riaRegulatedAccreditation,
  riaNoneRegulatedAccreditation
} from '../data'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/riaUserAccreditationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageLoading } from 'components'
import UserAccrediationCTA from './partials/UserAccrediationCTA'
import { getUserAccreditation, submitAttestation } from 'slices/riaSlice'

const RIAUserAccreditation = ({ match }) => {
  const methods = useForm({ resolver: yupResolver(schema) })
  const { handleSubmit } = methods
  const { pageLoading, isCountryRegulated } = useSelector(
    (state) => state.ria
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const userId = match.params.userId || ''

  useEffect(() => {
    if (userId) {
      dispatch(getUserAccreditation(userId))
      Mixpanel.track('View Verify Client Accreditation Page')
    }
  }, [])

  const redirect = (type) => {
    Mixpanel.track('Close Verify Client Accreditation Page', { 'Advisor Attestation Button Click': type })
    history.push('/ria-dashboard')
  }

  const onSubmit = (formValues) => {
    const body = { accreditationType: formValues.reason, clientUserId: userId }
    dispatch(submitAttestation(body))
      .then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          redirect('Submit')
        }
      })
  }

  if (pageLoading) return <PageLoading />

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Verify Client Accreditation')} />
      <div className='page-container ria-accreditation-container'>
        <div className='inner-container'>
          <UserAccrediationHeader />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <UserAccrediationReasons
                reasons={
                  isCountryRegulated
                    ? riaRegulatedAccreditation
                    : riaNoneRegulatedAccreditation
                }
              />
              <UserAccrediationCTA redirect={redirect} />
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  )
}

export default RIAUserAccreditation
