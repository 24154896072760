import { useSelector } from 'react-redux'
import Button from '../../../Global/Button'
import EmailInput from 'components/Global/Inputs/EmailInput'
import { useFormContext } from 'react-hook-form'

const ResetModalForm = ({ onSubmit }) => {
  const { submitLoading } = useSelector((state) => state.userSlice)
  const {
    handleSubmit,
    formState: { isDirty }
  } = useFormContext()

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className='grid reset-password-modal'>
        <div className='column eight sixteen-mobile'>
          <EmailInput
            name='email'
            label='Email'
            ariaLabel='email'
            disabled={submitLoading}
          />
        </div>
      </div>
      <div className='btn-group left'>
        <Button
          type='submit'
          loading={submitLoading}
          disabled={submitLoading || !isDirty}
        >
          Submit
        </Button>
      </div>
    </form>
  )
}

export default ResetModalForm
