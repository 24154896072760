const SelfAccreditationSection = ({
  selfAccreditationState,
  handleSaveSelfAccreditation,
  loading,
  accreditedStatus,
  selfAccreditationOptions
}) => {
  return (
    <div className='b_18_regular info-container'>
      <label>Choose your accreditation status</label>
      <select
        value={selfAccreditationState}
        onChange={(e) => handleSaveSelfAccreditation(e.target.value)}
        disabled={
          loading ||
          (accreditedStatus !== 'NOT_VERIFIED' && accreditedStatus !== 'FAILED')
        }
        role='selfAccreditation-dropdown'
        className='selfAccreditation-dropdown'
      >
        <option value='UNKNOWN' disabled>
          Please select an option
        </option>
        {Object.keys(selfAccreditationOptions).map((el, id) => (
          <option key={Object.keys(selfAccreditationOptions)[id]} value={el}>
            {selfAccreditationOptions[el]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelfAccreditationSection
