import { useState } from 'react'
import Tooltip from '../../Global/Tooltip'
const Entities = ({ entities, tooltip }) => {
  const [showHover, setShowHover] = useState(false)

  return (
    <div className='entity-list'>
      <div className='entity-list-title' onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)}>
        <div className='b_18_semibold list-title'>Available Investment Entities:</div>
        <Tooltip content={tooltip} showHover={showHover} setShowHover={setShowHover}/>
      </div>
      <div className='b_18_regular'>
        {entities && entities.length === 0 && <span className='b_18_semibold no-entity'>None yet</span>}
        {entities && entities.length > 0 && entities.map((e, index) => (
          <span key={index}>{e.name}</span>
        ))}
      </div>
    </div>
  )
}

export default Entities
