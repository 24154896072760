import { Modal } from 'components'
import Button from '../../Global/Button'
import { useHistory } from 'react-router-dom'

const SellOfferErrorModal = ({ setShowErrorModal, companyURL }) => {
  const history = useHistory()

  const hideModal = () => {
    setShowErrorModal(false)
    history.push(`/portfolio/${companyURL}`)
  }

  return (
    <Modal
      mode='primary'
      size='md'
      innerStyle='origination-modal'
      modalHeader=''
      hideModal={hideModal}
    >
      <div className='b_18_regular no-margin'>
        Selling shares is unavailable at this time. If you have any
        questions, please reach out to  <a className='link primary' href='mailto:support@linqto.com'>support@linqto.com</a>
      </div>
      <div className='btn-group centered'>
        <Button onClick={hideModal}>
          OK
        </Button>
      </div>
    </Modal>
  )
}

export default SellOfferErrorModal
