import UpholdWalletHeader from './UpholdWalletHeader'
import UpholdWalletNotConnected from './UpholdWalletNotConnected'

const UpholdWallet = ({ hasUphold, upholdError, connectUpholdWallet, disconnectUpholdWallet }) => {
  return (
    <>
      <UpholdWalletHeader hasUphold={hasUphold} connectUpholdWallet={connectUpholdWallet} disconnectUpholdWallet={disconnectUpholdWallet}/>
      {hasUphold && upholdError !== '' && <UpholdWalletNotConnected content={upholdError}/>}
      {!hasUphold && <UpholdWalletNotConnected connectUpholdWallet={connectUpholdWallet}/>}
    </>
  )
}

export default UpholdWallet
