import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/private.js'

const initialState = {
  company: {},
  isLoggedIn: false,
  companies: [],
  sectors: [],
  countries: [],
  totalUnicorns: 1,
  totalValuations: 1,
  hasMore: true,
  pageLoading: false,
  buttonLoading: false
}

export const getUnicorns = createAsyncThunk('getUnicorns', ({ pageNum = 0, newSearch = false, country = '', sector = '', query = '', sort = '', length = 12 }, { dispatch, fulfillWithValue, getState }) => {
  const url = `/page/unicorns?length=${length}&start=${pageNum * length}&filterCountry=${encodeURIComponent(country)}&filterSector=${encodeURIComponent(sector)}&searchTerm=${query}&orderBy=${sort}`
  const totalCompanies = pageNum * length

  if (getState().unicornsSlice.totalValuations !== 1 && (totalCompanies !== getState().unicornsSlice.companies.length) && !newSearch) {
    const totalUnicorns = getState().unicornsSlice.totalUnicorns
    const sectors = getState().unicornsSlice.sectors
    const countries = getState().unicornsSlice.countries
    const totalValuations = getState().unicornsSlice.totalValuations
    const companies = getState().unicornsSlice.companies
    const hasMore = true
    return Promise.resolve('Success').then(() => {
      return fulfillWithValue({ companies, totalUnicorns, sectors, countries, totalValuations, hasMore })
    })
  } else {
    return api
      .get(url)
      .then(res => {
        const totalUnicorns = res.data.totalUnicorns ? res.data.totalUnicorns : getState().unicornsSlice.totalUnicorns
        const sectors = res.data.sectors ? res.data.sectors : getState().unicornsSlice.sectors
        const countries = res.data.countries ? res.data.countries : getState().unicornsSlice.countries
        const totalValuations = res.data.totalValuations ? res.data.totalValuations : getState().unicornsSlice.totalValuations
        const companies = newSearch ? res.data.companies : getState().unicornsSlice.companies ? getState().unicornsSlice.companies.concat(res.data.companies) : res.data.companies
        const hasMore = res.data.companies.length === length
        return fulfillWithValue({ ...res.data, companies, totalUnicorns, sectors, countries, totalValuations, hasMore })
      })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
})

export const getUnicornsDetails = createAsyncThunk('getUnicornsDetails', ({ unicornId }, { dispatch, getState }) => {
  return api
    .get(`/page/unicorns/${unicornId}`)
    .then(res => res.data)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const setInterestedInUnicorn = createAsyncThunk('setInterestedInUnicorn', ({ urlName, interested }, { dispatch }) => {
  return api
    .post(`/page/unicorns/${urlName}`, { interested })
    .then((res) => {
      return { status: 200, data: res.data }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return err.response.data.error
    })
})

export const unicornsSlice = createSlice({
  name: 'unicorns',
  initialState,
  reducers: {
    resetUnicorns: (state) => {
      state.company = {}
      state.isLoggedIn = false
      state.companies = []
      state.sectors = []
      state.countries = []
      state.totalUnicorns = 1
      state.totalValuations = 1
      state.hasMore = true
      state.pageLoading = false
      state.buttonLoading = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnicorns.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getUnicorns.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getUnicorns.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getUnicornsDetails.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getUnicornsDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getUnicornsDetails.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(setInterestedInUnicorn.pending, (state) => {
        state.buttonLoading = true
      })
      .addCase(setInterestedInUnicorn.fulfilled, (state, { payload }) => {
        return {
          ...state,
          buttonLoading: false,
          ...payload
        }
      })
      .addCase(setInterestedInUnicorn.rejected, (state) => {
        state.buttonLoading = false
      })
  }
})

export const { resetUnicorns } = unicornsSlice.actions

export default unicornsSlice.reducer
