import Button from 'components/Global/Button'
import Modal from 'components/Global/Modal'

const BankAccountModal = ({ twoButtons = false, title, copy, buttonCopy, secondaryButtonCopy = '', handleClick, handleSecondaryClick, hideModal }) => {
  return (
    <Modal
      mode='primary'
      size='md'
      crossToClose
      modalHeader={title}
      hideModal={hideModal}
    >
      <div className='b_18_regular text-right'>
        {copy}
      </div>
      {twoButtons ? <div className='btn-group two'>
        <Button mode='primary-alt' onClick={handleSecondaryClick}>
          {secondaryButtonCopy}
        </Button>
        <Button onClick={handleClick}>
          {buttonCopy}
        </Button>
      </div> : <div className='btn-group centered'>
        <Button onClick={handleClick}>
          {buttonCopy}
        </Button>
      </div>}
    </Modal>
  )
}

export default BankAccountModal
