import React, { useEffect, useState } from 'react'
import { PageLoading } from 'components'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { getPortfolio } from 'slices/portfolioSlice'
import InvestmentTabContainer from './partials/InvestmentTabContainer'
import TotalInvestment from './partials/TotalInvestment'
import InvestmentNoData from './partials/InvestmentNoData'
import CurrentInvestmentsTab from './partials/CurrentInvestments/CurrentInvestmentsTab'
import HistoryTab from './partials/History/HistoryTab'
import EntityDropDown from './partials/EntityDropDown'
import DownloadSummary from './partials/DownloadSummary'
import LinqtoCarousel from 'components/Global/LinqtoCarousel'

const PortfolioComponent = () => {
  const [tab, setTab] = useState('current')
  const [groupBy, setGroupBy] = useState('account')
  const dispatch = useDispatch()
  const { portfolioContentCards } = useSelector(state => state.commonSlice)
  const { isLoggedIn, pageLoading, entities, recentOrders, cashPaymentEnabled, total, totalInvestmentValue } = useSelector(state => state.portfolioSlice)
  const hasEntities = entities?.length > 0

  useEffect(async () => {
    await dispatch(getPortfolio())
  }, [])

  const handleChange = type => setTab(type)
  const totalValue = cashPaymentEnabled ? total : totalInvestmentValue

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('My Portfolio')} />
        <PageLoading />
      </>
    )
  }

  let currentInvestmentComponent = null
  if (tab === 'current') {
    if (totalValue === 0) {
      currentInvestmentComponent = <InvestmentNoData tab={tab} />
    } else {
      currentInvestmentComponent = <CurrentInvestmentsTab groupBy={groupBy} />
    }
  } else {
    currentInvestmentComponent = <HistoryTab tab={tab} groupBy={groupBy}/>
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('My Portfolio')} />
      <div className='portfolio-container'>
        <div className='page-container'>
          <div className='inner-container'>
            {isLoggedIn && portfolioContentCards?.length ? (
              <div className={`carousel-container m-80 ${portfolioContentCards?.length === 1 ? 'mb-24' : ''}`}>
                <LinqtoCarousel data={portfolioContentCards} page='Portfolio' />
              </div>) : null}
            <div className='page-header'>
              <h1>My Portfolio</h1>
              <div className='page-actions'>
                {hasEntities && <EntityDropDown groupBy={groupBy} setGroupBy={setGroupBy}/>}
                {recentOrders?.length > 0 && <DownloadSummary />}
              </div>
            </div>
            <TotalInvestment />
            <InvestmentTabContainer onChange={handleChange} tab={tab} />
            <div className='investments-container'>
              {currentInvestmentComponent}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PortfolioComponent
