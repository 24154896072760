import Button from 'components/Global/Button'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const SummaryFormCTA = ({ onSubmit, cancelAddFunds, type }) => {
  const { otpRequired, loading } = useSelector(
    (state) => state.walletSlice
  )

  const {
    handleSubmit,
    formState: { errors },
    watch
  } = useFormContext()
  const upholdOTPValue = watch('upholdOTP')

  return (
    <div className='sell-offer-cta'>
      <div className='btn-group two'>
        <Button
          mode='tertiary'
          type='button'
          onClick={cancelAddFunds}
        >
          Back
        </Button>
        <Button
          onClick={(e) => handleSubmit(onSubmit)}
          disabled={
            loading ||
            [Object.keys(errors).length] > 0 ||
            (type === 'Uphold' && otpRequired && (!upholdOTPValue || upholdOTPValue?.length < 6))
          }
          loading={loading}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

export default SummaryFormCTA
