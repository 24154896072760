import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { useLocation, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Mixpanel, seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { schema } from 'schemas/withdrawShema'
import { yupResolver } from '@hookform/resolvers/yup'
import { getWithdrawPage, commitWithdrawRequest } from 'slices/walletSlice'
import FBOWithdrawHeader from './partials/FBOWithdrawHeader'
import FBOWithdrawForm from './partials/FBOWithdrawForm'
import FBOWithdrawCommitErrorModal from './partials/FBOWithdrawCommitErrorModal'

const FBOWithdraw = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const query = location.search
  const entityId = qs.parse(query, { ignoreQueryPrefix: true })?.entityId || null

  const { pageLoading, availableWithdrawBalance } = useSelector((state) => state.walletSlice)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const methods = useForm({ mode: 'all', resolver: yupResolver(schema), context: { availableWithdrawBalance, selectedAccount } })

  useEffect(() => {
    dispatch(getWithdrawPage(entityId))
    Mixpanel.track('View Withdraw Funds Page')
  }, [])

  const onSubmit = async (formValues) => {
    const formAmount = formValues.amount.charAt(0) === '$' ? formValues.amount.substring(1) : formValues.amount
    dispatch(commitWithdrawRequest({ cashExternalAccountId: selectedAccount.id, transferAmount: formAmount })).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Withdrawal Request Successful', { Amount: formValues.amount })
        localStorage.setItem('withdraw-funds', true)
        history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
      } else if (meta.requestStatus === 'rejected') {
        Mixpanel.track('Withdrawal Error', { 'Error Type': payload?.error })
        setShowErrorModal(true)
      }
    })
  }

  const handleSelectAccount = account => {
    setSelectedAccount(account)
    Mixpanel.track('Select Withdraw Account')
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Withdraw Funds')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Withdraw Funds')} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          <FBOWithdrawHeader />
          <div className='add-funds-container'>
            <FormProvider {...methods}>
              <FBOWithdrawForm
                onSubmit={onSubmit}
                selectedAccount={selectedAccount}
                setSelectedAccount={handleSelectAccount}
                entityId={entityId}
              />
            </FormProvider>
          </div>
        </div>
      </div>
      {showErrorModal && <FBOWithdrawCommitErrorModal hideModal={ () => setShowErrorModal(false) } />}
    </>
  )
}

export default FBOWithdraw
