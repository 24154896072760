import {
  SET_LOADING_SPINNER,
  HIDE_LOADING_SPINNER,
  HANDLE401,
  SYSTEM_ERROR,
  RESET_LOADING
} from 'types/actionTypes'
import history from '../history'
// COMMON ACTIONS THAT ARE USED THROUGHT THE WEBSITE

export const showLoadingSpinner = () => ({
  type: SET_LOADING_SPINNER,
  payload: true
})

export const hideLoadingSpinner = () => ({
  type: HIDE_LOADING_SPINNER,
  payload: false
})

export const handleServerError = (status) => async (
  dispatch
) => {
  switch (status) {
  case 'NOT_LOGGED_IN_TOKEN_INVALID':
    if (history.location.pathname !== '/signin') {
      const returnUrl = window.location.href.replace(window.location.origin, '')
      console.log('returnUrl', returnUrl)
      localStorage.setItem('prev-route', returnUrl)
    }
    localStorage.removeItem('linqto_token')
    dispatch({
      type: HANDLE401
    })
    history.push('/signin')
    break
  case 'SIGNUP_INCOMPLETE_KYC_REQUIRED':
    history.push('/identity')
    break
  case 'SIGNUP_INCOMPLETE_SELF_ACCREDITATION_REQUIRED':
    history.push('/confirm-investor-status')
    break
  case 'Internal Server Error':
    dispatch({
      type: SYSTEM_ERROR,
      payload: { systemErr: true }
    })
    break
  case 'MFA_REQUIRED':
    history.push('/signin/mfas')
    break
  default:
    console.log('[handleServerError]: default', status)
  }
}

export const resetLoading = () => dispatch => {
  dispatch({
    type: RESET_LOADING
  })
}
