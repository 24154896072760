// import { toCamalCase } from 'utils'

const parseOffers = (orders) => {
  return orders.reduce((result, o) => {
    result.push({
      orderId: o.offerId,
      createdAt: o.createdAt ? o.createdAt : '',
      shares: o.shares ? o.shares : '',
      price: o.total !== null ? o.total : '',
      sharePrice: o.sharePrice !== null ? o.sharePrice : '',
      remainingTotal: o.remainingTotal !== null ? o.remainingTotal : '',
      remainingShares: o.remainingShares !== null ? o.remainingShares : ''
    })

    return result
  }, [])
}

export default parseOffers
