import NumberInput from 'components/Global/Inputs/NumberInput'
import { useSelector } from 'react-redux'
import { formatDecimal } from 'utils'
import { useFormContext } from 'react-hook-form'

const SellOfferPrice = ({ calculateTotals, subTotal, setDisableSharesToSell, calculateMinimumShareAmount, disableSharesToSellInput }) => {
  const { marketLastSoldPrice, marketCurrentMaxPrice, marketCurrentMinPrice, minimumTotalSellPrice } =
    useSelector((state) => state.sellOffer)

  const { setValue, watch } = useFormContext()

  const validatePriceValues = (inputVal) => {
    let updateValue = Number(inputVal)?.toFixed(2).toString()
    const inputEndsWithZeroAndIncludesZeroDecimal = updateValue?.length === 4 && updateValue?.includes('0.00') && updateValue?.endsWith('0')
    if (inputVal === '0' || inputEndsWithZeroAndIncludesZeroDecimal) {
      updateValue = 0.01
    } else if (inputVal === '') {
      updateValue = marketLastSoldPrice
    }
    setValue('marketLastSoldPrice', Number(updateValue).toFixed(2))
    calculateTotals()
  }

  const renderCurrentOffers = () => {
    if (marketCurrentMinPrice && marketCurrentMaxPrice) {
      return `${formatDecimal(marketCurrentMinPrice)} - ${formatDecimal(
        marketCurrentMaxPrice
      )}`
    } else if (!marketCurrentMinPrice && marketCurrentMaxPrice) {
      return formatDecimal(marketCurrentMaxPrice)
    } else if (marketCurrentMinPrice && !marketCurrentMaxPrice) {
      return formatDecimal(marketCurrentMinPrice)
    } else if (!marketCurrentMinPrice && !marketCurrentMaxPrice) {
      return 'None'
    }
  }
  return (
    <div className='sell-offer-section'>
      <div className='b_18_semibold sell-offer-section-title'>
        <span>1</span> Set your asking price
      </div>
      <div className='sell-offer-order'>
        <div className='box invest-order-box'>
          <div className='sell-offer-row'>
            <span className='dark'>Asking price/share</span>
            <span className='dark input'>
              USD $
              <NumberInput
                isDecimal
                name='marketLastSoldPrice'
                ariaLabel='marketLastSoldPrice'
                onChange={(e) => {
                  calculateTotals()
                }}
                onBlur={(e) => {
                  const sellableShareCount = +watch('shareOwningAccount.sellableShareCount')
                  const marketLastSoldPrice = +watch('marketLastSoldPrice')
                  setValue('sharesForAccount', sellableShareCount)
                  const minShareAmount = calculateMinimumShareAmount(marketLastSoldPrice, sellableShareCount, minimumTotalSellPrice)
                  if (minShareAmount) {
                    disableSharesToSellInput(marketLastSoldPrice * sellableShareCount, minShareAmount === sellableShareCount)
                  }
                  validatePriceValues(e.target.value)
                }}
                handleKeyPress={(e) => {
                  if (e.key === '-') {
                    e.preventDefault()
                  }
                }}
              />
            </span>
          </div>
          <div className='sell-offer-row spaced'>
            <span>Last price on Linqto</span>
            <span>{formatDecimal(marketLastSoldPrice)}</span>
          </div>
          <div className='sell-offer-row'>
            <span>Current Active Sell Offer(s)</span>
            <span>{renderCurrentOffers()}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellOfferPrice
