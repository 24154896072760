import { useEffect, useState } from 'react'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import ReferralInfo from '../OnBoarding/SignUp/partials/ReferralInfo'
import ReferralError from '../OnBoarding/SignUp/partials/ReferralError'
import AddAdvisorForm from './partials/AddAdvisorForm'
import { useQuery } from 'hooks/useQuery'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageLoading } from 'components'
import SuccessModal from 'components/Global/SuccessModal'
import { connectWealthAdvisor, getRiaInfo } from 'slices/riaSlice'

const RIATc = () => {
  const { isSignedIn, isUserReturned } = useSelector((state) => state.userSlice)
  const { confirmationToken, riaNameFirst, riaNameLast, pageLoading, riaFirmName } =
    useSelector((state) => state.ria)
  const [referralError, setReferralError] = useState(false)
  const [referralErrorMessage, setReferralErrorMessage] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const riaReferralCode = query.get('ria') || null

  useEffect(() => {
    if (riaReferralCode) {
      dispatch(getRiaInfo(riaReferralCode)).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          if (payload?.relationshipStatus === 'PENDING') {
            Mixpanel.track('View Add Wealth Advisor Page', { 'Advisor Referral Code': riaReferralCode })
          } else if (payload?.relationshipStatus === 'APPROVED') {
            localStorage.removeItem('ria-route')
            setReferralError(true)
            setReferralErrorMessage({
              message:
                "You're already linked to a wealth advisor.  Please contact us if you have any questions.",
              title: '',
              actionLabel: 'Contact Us',
              action: 'close'
            })
          } else if (payload?.notificationMessage) {
            localStorage.removeItem('ria-route')
            setReferralError(true)
            setReferralErrorMessage({
              message: payload?.notificationMessage,
              title: 'Notice',
              actionLabel: 'Contact Us',
              action: 'close'
            })
          }
        }
      })
    } else {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    if (isUserReturned) {
      if (!isSignedIn) {
        history.push(`/signup?ria=${riaReferralCode}`)
      }
    }
  }, [isUserReturned, confirmationToken])

  const acceptAdvisor = (approve) => {
    dispatch(connectWealthAdvisor({ confirmationToken, approve })).then(
      ({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('Click Add Advisor Button', { 'Advisor Referral Code': riaReferralCode, 'Advisor Firm': riaFirmName, 'Agree to Link Advisor': approve })
          if (approve) {
            setShowModal(true)
          } else {
            history.push('/products')
          }
          localStorage.removeItem('ria-route')
        }
      }
    )
  }

  const closeModal = () => {
    setReferralError(false)
    history.push('/contact')
  }

  if (pageLoading) return <PageLoading />

  return (
    <>
      <SeoMeta
        title={seoTitleTemplate('Linqto Advisor Terms and Conditions')}
      />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='signup-container'>
            {confirmationToken && (
              <>
                <ReferralInfo
                  referrerName={`${riaNameFirst} ${riaNameLast}`}
                  referrerLogo=''
                  riaFirmName={riaFirmName}
                  type='advisor-terms'
                />
                <AddAdvisorForm acceptAdvisor={acceptAdvisor} />
              </>
            )}
            {referralError && (
              <ReferralError
                referralErrorMessage={referralErrorMessage}
                closeModal={closeModal}
              />
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <SuccessModal
          message={`${`${riaNameFirst} ${riaNameLast}`} is now your registered advisor on Linqto.`}
          hideModal={() => history.push('/products')}
        />
      )}
    </>
  )
}

export default RIATc
