import * as yup from 'yup'
import { checkIfNotNumber } from 'utils'

export const schema = yup.object().shape({
  amount: yup
    .string()
    .required('Please enter an amount.')
    .test(
      'ChechNumber',
      'Please enter an amount.',
      (val) => !checkIfNotNumber(val?.charAt(0) === '$' ? val.substring(1) : val)
    ).test(
      'len',
      'Amount cannnot exceed $1,000,000. ',
      (val) => {
        const updatedValue = val?.charAt(0) === '$' ? val.substring(1) : val
        return updatedValue <= 1000000
      }
    )
})
