import { Modal, Row } from 'components'
import Button from '../../Global/Button'
import ReactHtmlParser from 'react-html-parser'

const UKResidentModal = ({ hideModal, data: { title, body } }) => {
  return (
    <Modal mode='error' size='md' crossToClose hideModal={hideModal} modalHeader={title}>
      <div className='modal-text'>{ReactHtmlParser(body)}</div>
      <Row className='btn-group centered'><Button onClick={hideModal}>Continue to Verify</Button></Row>
    </Modal>
  )
}

export default UKResidentModal
