import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageLoading } from 'components'
import { Mixpanel, seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import OrderHistory from '../Portfolio/partials/History/OrderHistory'
import ActiveOffers from '../Portfolio/partials/History/ActiveOffers'
import ProductOrderHeader from './partials/ProductOrderHeader'
import ProductOrderSummary from './partials/ProductOrderSummary'
import { getProductOrders } from 'slices/portfolioSlice'

const ProductOrderList = ({ match }) => {
  const { pageLoading, company, entities } = useSelector((state) => state.portfolioSlice)
  const dispatch = useDispatch()
  useEffect(async () => {
    const id = match.params.companyId || ''
    const res = await dispatch(getProductOrders({ id }))
    const { payload } = res
    if (payload && payload?.company) {
      Mixpanel.track('View Portfolio Company Page', { 'Company Name': payload?.company?.name })
    }
  }, [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('My Portfolio')} />
        <PageLoading />
      </>
    )
  }
  if (!company) {
    return null
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate(company.name)} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          <ProductOrderHeader company={company} />
          <ProductOrderSummary company={company} />
          <ActiveOffers />
          <OrderHistory showCompany={false} groupBy={entities.length > 0 ? 'account' : 'investment' } />
        </div>
      </div>
    </>
  )
}

export default ProductOrderList
