import { images } from 'assets'

const FailedContent = () => {
  return (
    <div className='reset-container'>
      <img alt='email-success' src={images.failed} className='reset-icon'></img>
      <p>The link has expired.</p>
    </div>
  )
}

export default FailedContent
