import parseOrders from 'actions/parsing/parseOrders.js'
import parseOffers from 'actions/parsing/parseOffers.js'

const parsingPortfolio = (data) => {
  return {
    company: data?.company,
    recentOrders: data.recentOrders ? parseOrders(data.recentOrders) : [],
    activeOffers: data.activeOffers ? parseOffers(data.activeOffers) : [],
    entities: data.entities ? data.entities : []
  }
}

export default parsingPortfolio
