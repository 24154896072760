import Breadcrumb from 'components/Global/Breadcrumb'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { ConditionalRender } from 'components'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const ProductOrderHeader = ({ company }) => {
  const items = [{ name: 'My Portfolio', route: '/portfolio' }]
  const history = useHistory()
  const { company: { canSellShares, atsPoolEnabled, atsEnabled } } = useSelector(state => state.portfolioSlice)

  const handleBuyMore = () => {
    history.push(`/products/${company.urlName}`)
    Mixpanel.track('Click Buy More on Portfolio/Company Page')
  }

  const handelSell = () => {
    Mixpanel.track('Click Sell Button on Portfolio Company Page', { 'Company Name': company.name })
    if (atsPoolEnabled) {
      history.push(`/sell-order/create/${company.urlName}`)
    } else {
      history.push(`/sell-offer/${company.urlName}`)
    }
  }

  return (
    <>
      <Breadcrumb items={items} />
      <div className='order-list-container'>
        <h1 className='order-list-title'>
          <img
            className='company-image'
            alt={`Linqto ${company.name} series`}
            src={company.iconUrl}
          ></img>
          {company.name}
        </h1>
        <ConditionalRender isVisible={company.status === 'OPEN' && !canSellShares && !atsEnabled}>
          <Button
            onClick={() => history.push(`/products/${company.urlName}`)}
          >
            Invest More
          </Button>
        </ConditionalRender>
        <ConditionalRender isVisible={company.status === 'OPEN' && canSellShares && atsEnabled }>
          <div className='btn-group two'>
            <Button
              mode='primary-alt'
              onClick={handleBuyMore}
            >
              Buy More
            </Button>
            <Button
              onClick={handelSell}
            >
              Sell
            </Button>
          </div>
        </ConditionalRender>
      </div>
    </>
  )
}

export default ProductOrderHeader
