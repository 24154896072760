import { formatNumberWithLetter } from 'utils'

const UnicornDetailsHeaderSummary = ({ company, isLoggedIn }) => {
  const blueStyle = !isLoggedIn ? 'blur-info' : ''
  return (
    <div className='inner-container'>
      <div className='b_18_semibold pager-header-summary'>
        <div className='unicorn-details-column'>
          <label>Founded Year</label>
          <div className={blueStyle}>{company.foundedYear ? company.foundedYear : '-'}</div>
        </div>
        <div className='unicorn-details-column'>
          <label>Sector</label>
          <div className={blueStyle}>{company.sector ? company.sector : '-'}</div>
        </div>
        <div className='unicorn-details-column'>
          <label>Revenue</label>
          <div className={blueStyle}>{company.estimatedRevenue ? formatNumberWithLetter(company.estimatedRevenue, true, true, 1, true) : '-'}</div>
        </div>
        <div className='unicorn-details-column'>
          <label>Valuation</label>
          <div className={blueStyle}>{company.valuation ? formatNumberWithLetter(company.valuation, true, true, 1, true) : '-'}</div>
        </div>
      </div>
    </div>
  )
}

export default UnicornDetailsHeaderSummary
