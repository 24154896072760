import { useState } from 'react'
import Tooltip from 'components/Global/Tooltip'
import { useSelector } from 'react-redux'
import { formatDecimal, formatShares } from 'utils'
import { useFormContext } from 'react-hook-form'
import SellOrderForm from './SellOrderForm'

const SellOrderContainer = ({ type = 'create', setSelectedAccount, selectedAccount, selectedAvailableShares, setSelectedAvailableShares, renderErrorModal }) => {
  const [showHover, setShowHover] = useState(false)
  const [showFlatFeeHover, setFlatFeeShowHover] = useState(false)
  let { sharePrice, shareCount, subTotal, transactionFee, transactionFeeRate, totalProceeds, projectedGain, offerStatus, transactionFlatFee, accountOwnerName } = useSelector(state => state.sellOffer)

  const transactionFeeValue = transactionFee
  const transactionFlatFeeValue = transactionFlatFee
  sharePrice = sharePrice === 0 ? '-' : formatDecimal(sharePrice)
  shareCount = type === 'create' ? selectedAvailableShares === 0 ? '-' : formatShares(selectedAvailableShares) : shareCount
  subTotal = subTotal === 0 ? '-' : formatDecimal(subTotal)
  transactionFee = transactionFee === 0 ? '-' : formatDecimal(transactionFee)
  transactionFlatFee = transactionFlatFee === 0 ? '-' : formatDecimal(transactionFlatFee)
  totalProceeds = totalProceeds === 0 ? '-' : formatDecimal(totalProceeds)
  projectedGain = projectedGain === 0 ? '-' : formatDecimal(projectedGain)

  let bottomContainer = null

  const { formState: { errors } } = useFormContext()
  const minimumOrderError = errors?.sharesForAccount?.type === 'orderMinimum' || false

  if (type === 'create') {
    bottomContainer = (
      <div className='sell-offer-summary-container__row'>
        <div>
          <p className='sell-offer-summary-container__row__copy bold'>Estimated Total Proceeds</p>
        </div>
        <span className='sell-offer-summary-container__row__copy bold'>{totalProceeds}</span>
      </div>
    )
  } else {
    bottomContainer = <>
      <div className='sell-offer-summary-container__row'>
        <div>
          <p className='sell-offer-summary-container__row__copy bold'>Estimated Total Proceeds</p>
          {offerStatus !== 'Canceled' && <p className='sell-offer-summary-container__row__copy light'>To be deposited in your cash account</p>}
        </div>
        <span className='sell-offer-summary-container__row__copy bold'>{totalProceeds}</span>
      </div>
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Projected Gain</span>
        <span className='sell-offer-summary-container__row__copy'>{projectedGain}</span>
      </div>
    </>
  }

  return (
    <div className='sell-offer-summary-container'>
      {type === 'create' &&
        <SellOrderForm
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          selectedAvailableShares={selectedAvailableShares}
          setSelectedAvailableShares={setSelectedAvailableShares}
          renderErrorModal={renderErrorModal}
        /> }
      {type === 'summary' && <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Account</span>
        <span className='sell-offer-summary-container__row__copy'>{accountOwnerName}</span>
      </div>
      }
      {type === 'create' &&
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Available Shares</span>
        <span className='sell-offer-summary-container__row__copy'>{shareCount}</span>
      </div>
      }
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Estimated Share Price</span>
        <span className='sell-offer-summary-container__row__copy'>{sharePrice}</span>
      </div>
      {type === 'summary' &&
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Shares to Sell</span>
        <span className='sell-offer-summary-container__row__copy'>{shareCount}</span>
      </div>
      }
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>Subtotal</span>
        <span className={`sell-offer-summary-container__row__copy ${minimumOrderError ? 'error' : ''}`}>{subTotal}</span>
      </div>
      {minimumOrderError && <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy error'>Minimum sell order is $5,000</span>
      </div>
      }
      {transactionFeeValue > 0 && <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>
          Transaction Fee
          <Tooltip content={`This ${transactionFeeRate}% fee supports the maintenance of Linqto’s private market place.`} showHover={showHover} setShowHover={setShowHover} />
        </span>
        <span className='sell-offer-summary-container__row__copy'>-{transactionFee}</span>
      </div>
      }
      {transactionFlatFeeValue > 0 &&
      <div className='sell-offer-summary-container__row'>
        <span className='sell-offer-summary-container__row__copy'>
          Regulatory Filing Fee
          <Tooltip content='This flat fee helps cover local and federal regulatory filing expenses required for sales of private equity. If your offer sells across multiple orders, this fee is applied prior to distributing proceeds.' showHover={showFlatFeeHover} setShowHover={setFlatFeeShowHover} />
        </span>
        <span className='sell-offer-summary-container__row__copy'>-{transactionFlatFee}</span>
      </div>
      }
      {bottomContainer}
    </div>
  )
}

export default SellOrderContainer
