export const status = {
  NET_WORTH:
    'I am an individual with a net worth (either individually or with my spouse, if any), and all property and other assets excluding my primary residence, that exceeds $1,000,000 including investments.',
  ANNUAL_INCOME:
    'I am an individual with annual income that was at least $200,000 in each of the two most recent years, and I expect such income in the current year.',
  JOINT_INCOME:
    'I am an individual with an annual income, jointly with my spouse, of at least $300,000 in each of the two most recent years, and I expect such income in the current year.',
  SECURITIES_LICENSE:
    'I am an individual that holds in good standing a Series 7, 65, or 82 license.',
  FOREIGN_INVESTOR:
    'I am a non-US investor and qualify as the equivalent of an Accredited Investor in my country of residence.'
}

export const month = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

export const days = {
  1: 31,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31
}

export const ACCstatus = {
  VERIFIED_MANUAL: 'Verified',
  VERIFIED_AUTO: 'Verified',
  NOT_VERIFIED: 'Not Verified',
  FAILED: 'Not Verified',
  ACCREDITED: 'Verified',
  UNDER_REVIEW: 'Under Review'
}

export const SEOvalues = {
  PAGE_TITLE: 'Linqto | Private Investing',
  PAGE_META_DESCRIPTION: 'Private investing made simple',
  PAGE_KEYWORDS: '',
  OG_TITLE: 'Invest In Start-ups At Affordable Minimums'
}

export const deleteAccountReasons = {
  TOO_MANY_EMAIL: 'Too many emails',
  MISSED_PROMOTION: 'I missed out on a promotion',
  INVESTMENT_MINIMUM: 'Investment minimum of $5k is too high',
  NOT_QUALIFIED: "I don't qualify as an accredited investor",
  NO_COMPANIES: 'No companies I want to invest in right now',
  UNABLE_TO_FUND: 'Unable to fund orders with a preferred payment method',
  OTHER: 'Other (Please specify below)'
}

export const selfAccreditationOptions = {
  ANNUAL_INCOME: '$200K Individual Income for 2 Years',
  JOINT_INCOME: '$300K Combined Spousal Income for 2 Years',
  NET_WORTH: '$1M Net Worth',
  SECURITIES_LICENSE: 'Series 7, 65 or 82 License',
  NOT_ACCREDITED: 'Not accredited (limited experience)'
}

export const quality = [
  {
    title: 'Simple',
    discription:
      'Investors are verified with a few simple steps. Our 100% online process takes less than 10 minutes to complete.',
    img: 'simple'
  },
  {
    title: 'Secure',
    discription:
      'With 256-bit encryption, Linqto offers the most contemporary and comprehensive security in the industry.',
    img: 'secure'
  },
  {
    title: 'Cost-Effective',
    discription:
      'Linqto is a leading solutions provider, offering accredited investor verification at no cost.',
    img: 'cost-effective'
  },
  {
    title: 'User-Friendly',
    discription:
      "With our accredited investor dashboard, view your status at a glance. We'll remind you when it's time to renew.",
    img: 'user-friendly'
  },
  {
    title: 'Private',
    discription:
      'Safety and security. Get verified while maintaining control of your personal information. You control when and who you share your accreditation with.',
    img: 'private'
  },
  {
    title: 'Access to Shares of Private Companies',
    discription:
      'Once your accreditation is verified, all Linqto users have access to investment opportunities, accessing the private market and increasing diversification.',
    img: 'access'
  }
]

export const USDoceumentTypes = {
  NET_WORTH: [
    'Account Statement',
    'Cryptocurrency Wallet',
    'Property Title',
    'Mortgage Statement',
    'Other'
  ],
  ANNUAL_INCOME: [
    'W2 Last Year',
    'W2 Previous Year',
    '1099 Last Year',
    '1099 Previous Year',
    'Tax return Last Year',
    'Tax return Previous Year',
    'Other'
  ],
  JOINT_INCOME: [
    'W2 Last Year',
    'W2 Previous Year',
    'Spouse W2 Last Year',
    'Spouse W2 Previous Year',
    '1099 Last Year',
    '1099 Previous Year',
    'Spouse 1099 Last Year',
    'Spouse 1099 Previous Year',
    'Tax return Last Year',
    'Tax return Previous Year',
    'Spouse Tax Return Last Year',
    'Spouse Tax Return Previous Year',
    'Other'
  ],
  OTHER_REG: [
    'Proof of Income',
    'Proof of Net Worth',
    'Other'
  ]
}

export const USDoceumentTypesExplanation = {
  VERIFIES_INCOME: [
    'W2',
    '1099',
    'Tax returns for individual or business'
  ],
  VERIFIES_NET_WORTH: [
    'Statement (officially issued, e.g. bank, broker, retirement - no screenshots)',
    'Cryptocurrency Wallet',
    'Proof of Property Ownership (title/deed, purchase document, mortgage statement)',
    'Other (e.g. auto, artwork, appraisals on gold)'
  ],
  ACCEPTED_TYPES: 'Please upload documents in .jpg, .jpeg, .png or .pdf format.'
}

export const ukResidentModalDetails = [
  {
    key: 'EXPERIENCED_INVESTOR',
    title: 'Sophisticated Investor',
    body: `
    <p>I declare that I am a self-certified sophisticated investor for the purposes of the restriction on promotion of non-mainstream pooled investments.</p>
      <p>I understand that this means:</p>
      <ul>
        <li>I can receive promotional communications made by a person who is authorized by the Financial Conduct Authority which relates to investment activity in non-mainstream pooled investments.</li><br>
        <li>The investments to which the promotions will relate may expose me to a significant risk of losing all the property invested.</li>
      </ul>
      <br>
      <p>I am a self-certified sophisticated investor because at least one of the following applies:</p>
      <ul>
        <li>I am a member of a network or syndicate of business angels and have been so for at least the last six months prior to the date below; </li><br>
        <li>I have made more than one investment in an unlisted company in the two years prior to the date below;</li><br>
        <li>I am working, or have worked in the two years prior to the date below, in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises;</li><br>
        <li>I am currently or have been in the two years prior to the date below, a director of a company with an annual turnover of at least £1 million.</li>
      </ul>
      <br>
      <p>I accept that the investments to which the promotions will relate may expose me to a significant risk of losing all the money or other property invested.</p>
      <p>I am aware that it is open to me to seek advice from someone who specializes in advising on non-mainstream pooled investments.</p>
    `
  },
  {
    key: 'HIGH_NETWORTH',
    title: 'High Net Worth Individual',
    body: `
      <p>I declare that I am a certified high net worth individual for the purposes of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005:</p>
      <br>
      <p>I understand this means:</p>
      <ul>
        <li>I can receive financial promotions that may not have been approved by a person authorized by the Financial Services Authority</li>
        <li>The content of such financial promotions may not conform to rules issued by the Financial Services Authority:</li>
        <li>By signing this statement I may lose significant rights;</li>
        <li>I may have no right to complain to either of the following:</li>
        <ul>
          <li>the Financial Services Authority; or</li>
          <li>the Financial Ombudsman Scheme;</li>
        </ul>
        <li>I may have not right to seek compensation from the Financial Services Compensation Scheme.</li>
      </ul>
      <br>
      <p>I am a certified high net worth individual because at least one of the following applies:</p>
      <ul>
        <li>I had, during the financial year immediately preceding the date below, an annual income to the value of £100,000 or more. </li>
        <li>I  held, throughout the financial year immediately preceding the date below, net assets to the value of £250,000 or more. Net assets for these purposes do not include: </li>
        <ul>
          <li>the property which is my primary residence, or any money raised through a loan secured on that property; or  </li>
          <li>any rights of mine under a qualifying contract of insurance within the meaning of the Financial Services and Markets Act 2000 (Regulated Activities) Order 2001; or </li>
          <li>any benefits (in the form of pensions or otherwise) which are payable on the termination of my service or on my death or retirement and to which I am (or my dependents are), or may be, entitled; or</li>
        </ul>
      </ul>
      <br>
      <p>I accept that the investments to which the promotions will relate may expose me to a significant risk of losing all of the money or other property invested.</p>
      <p>I am aware that it is open to me to seek advice from an authorized person who specializes in advising on non-mainstream pooled investments.</p>
    `
  }
]

export const EUResidentsInputs = {
  checkbox1: 'I have carried out transactions of significant size in private market assets at an average frequency of 10 per quarter over the previous four quarters ',
  checkbox2: 'I have a financial portfolio exceeding €500,000',
  checkbox3: 'I work or have worked in the professional sector for at least one year.',
  radio1: 'My ordinary business or professional activity includes, or is reasonably expected to include, acquiring, underwriting, managing, holding or disposing of investment, whether as principal or agent, or the giving of advice concerning investments',
  radio2: 'I am the trustee of a trust where the aggregate value of the cash and investments which form part of the trusts assets are in excess of €1,000,000.',
  radio3: 'I am an individual whose net worth, or joint net worth with my spouse, is greater than €1,000,000, excluding my principal place of residence.',
  radio4: 'I am a participant who has a current aggregate of €100,000 invested in one or more Experienced Investor funds.'
}

export const EUResidentModalData = (option, name, country) => {
  const arr = [
    {
      key: 'EXPERIENCED_INVESTOR',
      title: 'EU Experienced Investor',
      body: `
      <p>My name is ${name} and I am a citizen or resident of ${country}.</p>
      <p>I elect to be treated as an Experienced Investor, as defined by EU securities regulations, 
        for the purpose of making investments in unlisted, private company securities offered on the Linqto investment platform.</p>
      <p>I confirm that I am an informed investor and possess the knowledge of and experience in financial and business matters 
        to allow me to properly evaluate the merits and risks of investments in unlisted, private companies.</p>
      <p>I acknowledge and accept such risks.</p>
      <p>I further certify that I meet at least one of the following criteria, which I have checked off appropriately below:</p>
      `
    },
    {
      key: 'PROFESSIONAL_CLIENT',
      title: 'EU Professional Client',
      body: `
      <p>My name is ${name} and I am a citizen or resident of ${country}.</p>
      <p>I elect to be treated as a Professional Client, as defined by EU securities regulations, for the purpose of making investments in unlisted, 
        private company securities offered on the Linqto investment platform.</p>
      <p>I confirm that I am an informed investor and possess the knowledge of and experience in financial and business matters to allow me 
        to properly evaluate the merits and risks of investments in unlisted, private companies.</p>
      <p>I acknowledge and accept such risks.</p>
      <p>I further certify that I meet at least one of the following criteria, which I have checked off appropriately below:</p>
      `
    }
  ]
  return arr.find(obj => obj.key === option)
}

export const MFAContentDescription = (type, steps, isMobile) => {
  const MFAContentDescriptionObj = {
    totp: [
      '',
      isMobile ? '' : 'Please scan the QR code with your authenticator app.',
      'Please enter the six digit one-time passcode from your Authenticator App.'
    ],
    email: [''],
    sms: ['', 'Please enter the six digit one-time passcode sent to']
  }
  return MFAContentDescriptionObj[type][steps - 1]
}

export const MFANotes = {
  email: 'Did not see the email?',
  sms: 'Did not see the text message?'
}

export const MFALabel = {
  totp: [
    'Step 1: Download Authenticator App',
    'Step 2: Add account to your Authenticator app',
    'Step 3: One-time passcode'
  ],
  email: ['Please enter the one-time passcode sent to'],
  sms: ['Step 1: Please select your country and enter your phone number. SMS Multi-Factor Authentication is only supported for the countries in the drop down list below.', 'Step 2: One-time passcode']
}

export const riaRegulatedAccreditation = {
  NET_WORTH: 'Qualify as an accredited investor via sufficient net worth',
  ANNUAL_INCOME: 'Qualify as an accredited investor via sufficient income',
  SECURITIES_LICENSE: 'Qualify via sophisticated investor or professional licenses'
}

export const riaNoneRegulatedAccreditation = {
  NON_REG: 'Reside in a country that does not have any regulations requiring user to be accredited'
}

export const comparison = [
  { title: 'Minimum Investment', linqto: '$5K', others: '$100K+' },
  { title: 'Company Selection', linqto: 'Full Control', others: 'No Control' },
  { title: 'Brokerage Fee', linqto: 'None', others: 'Up to 5%' },
  { title: 'Management Fee', linqto: 'None', others: '1-2%' },
  { title: 'Administrative Fee', linqto: 'None', others: '< 0.50%' },
  { title: 'Carried Interest', linqto: 'None', others: '10-20%' },
  { title: 'Lockup Period', linqto: 'None', others: 'Up to 10 Years' }
]
export const partners = [
  { image: 'partners-farther', content: 'Farther is the first wealth management solution to offer both the guidance of an experienced advisor and an elegant, easy-to-use technology platform.  Linqto is proud to offer “Startup Equity” investment opportunities to Farther advisors and their clients.', website: 'https://www.farther.com/' },
  { image: 'partners-millennium', content: 'Millennium Trust is an expert provider of custody solutions committed to the evolving needs of advisors, financial institutions, businesses, and individual investors.  Linqto is excited to be a member of the Millennium Alternative Investment Network (MAIN).', website: 'https://www.mtrustcompany.com/' },
  { image: 'partners-wealth', content: 'The Wealth With No Regrets® Planning process  shows financially successful professionals, executives, business owners, and independent women how to live more confidently by having a custom tailored financial plan, income plan, tax plan, legacy plan and legal plan.', website: 'https://wealthwithnoregrets.com/' }
]

export const BDTeam = [
  { name: 'Spencer Francis', image: 'partners-bd-spencer', title: 'Director, Linqto Wealth', email: 'spencer@linqto.com' },
  { name: 'Will Petruski', image: 'partners-bd-will', title: 'Vice President, Linqto Professional', email: 'will@linqto.com' },
  { name: 'Nick Burrafato', image: 'partners-bd-nick', title: 'Director, Linqto Member Sales', email: 'nick@linqto.com' },
  { name: 'Leo LaForce', image: 'partners-bd-leo', title: 'Chief Revenue Officer', email: 'leo@linqto.com' }
]

export const feature = [
  { image: 'partners-wealth-advisor', content: 'Linqto Investment LLC delivers tailored “Advisor First” private market solutions for:', list: ['independent RIAs and their clients', 'private funds', 'wealth-tech platforms'] },
  { image: 'partners-family-office', content: 'Linqto crafts customized investment programs for:', list: ['custodians', 'IRA providers', 'family offices', 'affinity groups', 'members of industry associations such as financial planning and healthcare'] }
]
export const promo = [{ promoCode: 'internalLqbPromo1A', amount: 500 }, { promoCode: 'internalLqbPromo1B', amount: 1000 }]

export const smsCountriesList = ['US', 'CA', 'GB', 'AU', 'ZA', 'NL', 'DE', 'IE']

export const registrationEmailDomains = {
  gmail: { url: 'https://mail.google.com', label: 'Gmail' },
  yahoo: { url: 'https://mail.yahoo.com', label: 'Yahoo' },
  icloud: { url: 'https://icloud.com/mail', label: 'iCloud' },
  outlook: { url: 'https://outlook.com/mail', label: 'Outlook' },
  hotmail: { url: 'https://outlook.com/mail', label: 'Outlook' },
  protonmail: { url: 'https://account.proton.me', label: 'ProtonMail' },
  aol: { url: 'https://mail.aol.com', label: 'Aol' }
}

export const sellOrderErrors = {
  SHARE_PRICE_NOT_AVAILABLE: { type: 'SHARE_PRICE_NOT_AVAILABLE', title: 'Oops! Something went wrong.', message: 'We’re sorry we are unable to process this request. Please try again later.', mixPanel: 'Share Price Not Available' },
  PLACE_ORDER_INSUFFICIENT_FUNDS: { type: 'PLACE_ORDER_INSUFFICIENT_FUNDS', title: 'Oops! Something went wrong.', message: 'We’re sorry we are unable to process this request. Please try again later.', mixPanel: 'Insufficient Funds in Liquidity Pool' },
  PLACE_ORDER_ATS_OR_POOL_NOT_ENABLED: { type: 'PLACE_ORDER_ATS_OR_POOL_NOT_ENABLED', title: 'Oops! Something went wrong.', message: 'We’re sorry we are unable to process this request. Please try again later.', mixPanel: 'ATS Disabled' },
  PLACE_ORDER_PARAMETERS_CHANGED: { type: 'PLACE_ORDER_PARAMETERS_CHANGED', title: 'Share Price Updated', message: 'The share price for this company has been updated. Please review your sell order.', mixPanel: 'Share price changed' }
}
export const LqbSignupPromoEnabled = false

export const trustedContactRelationships = ['Spouse', 'Son/Daughter', 'Parent', 'Relative', 'Friend', 'Lawyer / Accountant / Financial Advisor', 'Other']

export const usResidencyStatus = {
  NON_RESIDENT_ALIEN: 'Non-Resident Alien',
  RESIDENT_ALIEN: 'Resident Alien'
}

export const annualIncomeOptions = {
  UNKNOWN: 'Please select',
  LESS_THAN_100K: '$0-$99,000',
  BETWEEN_200K_500K: '$100,000-$199,999',
  BETWEEN_100K_200K: '$200,000-$499,999',
  BETWEEN_500K_1M: '$500,000-$999,999',
  OVER_1M: '$1,000,000+'
}

export const netWorthOptions = {
  UNKNOWN: 'Please select',
  LESS_THAN_200K: '$0-$199,999',
  BETWEEN_200K_500K: '$200,000-$499,999',
  BETWEEN_500K_1M: '$500,000-$999,999',
  BETWEEN_1M_5M: '$1,000,000-$4,999,999',
  BETWEEN_5M_15M: '$5,000,000-$14,999,999',
  MORE_THAN_15M: '$15,000,000+'
}

export const allocationOptions = {
  UNKNOWN: 'Please select',
  LESS_THAN_25K: '$0-$24,999',
  BETWEEN_25K_50K: '$25,000-$49,999',
  BETWEEN_50K_100K: '$50,000-$99,999',
  BETWEEN_100K_200K: '$100,000-$199,999',
  BETWEEN_200K_500K: '$200,000-$499,999',
  BETWEEN_500K_1M: '$500,000-$999,999',
  BETWEEN_1M_5M: '$1,000,000-$4,999,999',
  BETWEEN_5M_15M: '$5,000,000-$14,999,999',
  MORE_THAN_15M: '$15,000,000+'
}
