import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/private.js'
import parseDocuments from 'actions/parsing/parseDocuments.js'
const initialState = {
  pageLoading: false,
  taxDocuments: [],
  uploadedDocuments: [],
  monthlyStatmentDocuments: []
}

export const getDocuments = createAsyncThunk('getDocuments', (refreshStatus = '', { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .get('/page/userDocuments')
    .then(res => {
      return fulfillWithValue(parseDocuments(res.data), { refreshStatus })
    })
    .catch(err => {
      console.log(err)
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const deleteDocument = createAsyncThunk('deleteDocuments', (documentId, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .delete('/page/userDocuments', { data: { documentId } })
    .then(res => {
      return fulfillWithValue(parseDocuments(res.data))
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDocuments.pending, (state, { meta }) => {
        if (meta.arg === 'noRefresh') {
          state.pageLoading = false
        } else {
          state.pageLoading = true
        }
      })
      .addCase(getDocuments.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getDocuments.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default documentsSlice.reducer
