import { useFormContext } from 'react-hook-form'

const RadioButton = ({
  label,
  name,
  value,
  disabled = false,
  ariaLabel = ''
}) => {
  const { register } = useFormContext()
  return (
    <div className='radio-option'>
      <label className='radio-option_label-container b_18_regular'>
        <input
          type='radio'
          {...register(name)}
          value={value}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        {label}
      </label>
    </div>
  )
}

export default RadioButton
