import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { truncate, toTitleCase, MOBILE_WIDTH_SIZE, formatDecimal } from 'utils'
const ProductCard = ({ p, showProductDetails }) => {
  const { isSignedIn } = useSelector((state) => state.userSlice)
  const { width } = useWindowSize()
  const promoStyle = {
    RECENTLY_ADDED: 'recently_added',
    TOP_SELLER: 'top_seller',
    FULLY_SUBSCRIBED: 'fully_subscribed',
    LIMITED_SHARES: 'limited_shares',
    COMING_SOON: 'coming_soon',
    NONE: ''
  }

  const isFullySubscribed = p?.promoFlag === 'FULLY_SUBSCRIBED'
  const displayComingSoon = (p?.promoFlag === 'COMING_SOON' && p?.sharePrice === undefined)

  return (
    <div className='relative'>
      {p.promoFlag !== 'NONE' && <span className={`flag ${promoStyle[p.promoFlag]}`}>{toTitleCase(p.promoFlag.replace(/_/g, ' ')) }</span> }
      <div className='product-card' key={p.urlName} onClick ={() => showProductDetails(p.urlName)}>
        <div className='product-title'>
          {width <= MOBILE_WIDTH_SIZE && <span className='heading_8_eyebrow product-title__sector'>{p.vertical}</span>}
          {p.logoUrl && <img className='product-title__icon' alt={p.name} src={p.logoUrl} />}
        </div>
        {width >= MOBILE_WIDTH_SIZE && <div className='product-description'>
          <span className='heading_8_eyebrow product-description__sector'>{p.vertical}</span>
          <span className='product-description__description'>{truncate(p.description, 110)}</span>
        </div>}
        <div className='product-share-container'>
          {isFullySubscribed ? <span className='product-share-container__share-price-copy medium'>{width >= MOBILE_WIDTH_SIZE ? 'Fully Subscribed' : 'Sold Out'}</span> : <span className={`b_14_regular product-share-container__share-price${displayComingSoon ? '-copy medium' : ''} ${!isSignedIn && 'blur-info'}`}>{p?.sharePrice ? formatDecimal(p?.sharePrice) : 'Coming soon'}</span>}
          <span className='product-share-container__share-price-copy'>Share Price</span>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
