const AccountFundingBanner = ({ bannerAction, needToCompleteInvestorProfile, needToCompleteFundingStep }) => {
  return (
    <div className='account-funding-banner-container'>
      <div className='account-funding-banner-content' onClick={() => bannerAction('fundingBanner')}>
        <div className='account-funding-banner-copy'>
          <div className='title'>Welcome to Linqto!</div>
          <p className='subtitle'>
            A few steps before you invest:
          </p>
        </div>
        <div className='account-funding-banner-progress'>
          <ui className='progress-bar'>
            <li className='b_16_regular checked'><br/>Sign Up</li>
            <li className={`b_16_regular ${needToCompleteInvestorProfile ? 'active' : 'checked'}`}><br/>Complete Profile</li>
            <li className={`b_16_regular ${needToCompleteFundingStep ? 'active' : 'inactive'}`}><br/>Add Funds</li>
            <li className='b_16_regular inactive'><br/>Place Order</li>
          </ui>
        </div>
      </div>
    </div>
  )
}

export default AccountFundingBanner
