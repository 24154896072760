import { useEffect } from 'react'
import { PageLoading, RewardHeader, RewardShare, RewardHistory } from 'components'
import ContactSupport from 'components/Global/ContactSupport'
import { Mixpanel, seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { getRewards } from 'slices/rewardsSlice'
import { useDispatch, useSelector } from 'react-redux'

const Rewards = () => {
  const dispatch = useDispatch()
  const { pageLoading, isLoggedIn, transactions } = useSelector(state => state.rewards)
  useEffect(() => {
    dispatch(getRewards())
    Mixpanel.track('View Linqto Bucks Page')
  }, [])

  if (pageLoading) {
    return <>
      <SeoMeta title={seoTitleTemplate('Linqto Bucks Referrral Program')} />
      <PageLoading />
    </>
  }

  return (
    <div className='page-container rewards-container'>
      <div className='inner-container'>
        {isLoggedIn &&
        <>
          <RewardHeader />
          <RewardShare />
          {transactions.length > 0 &&
            <RewardHistory />
          }
          <ContactSupport />
        </>
        }
      </div>
    </div>
  )
}

export default Rewards
