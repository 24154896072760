import { Mixpanel } from 'utils'

const UnicornDetailsTabs = ({ activeIndex, setActiveIndex, company }) => {
  const setTab = (index, tab) => {
    setActiveIndex(index)
    Mixpanel.track('Click Market Insights Company Tab', { 'Company Name': company.name, 'Tab Name': tab })
  }

  return (
    <div className='inner-container'>
      <div className='page-tabs section-seperator'>
        <div className={`tab ${activeIndex === 0 ? 'b_18_semibold active' : ''}`} onClick={() => setTab(0, 'Summary')}>
          <span>Summary</span>
        </div>
        { (company.estimatedRevenue ||
          (company.fundingRounds && company.fundingRounds.length > 0) ||
          (company.competitorFundingsUnicrons && company.competitorFundingsUnicrons.length > 0) ||
          (company.competitorFundingsOther && company.competitorFundingsOther.length > 0)
        ) &&
          <div className={`b_18_regular tab ${activeIndex === 1 ? 'b_18_semibold active' : ''}`} onClick={() => setTab(1, 'Funding & Competitors')}>
            <span className='lg-tab-span'>Funding & Competitors</span>
            <span className='sm-tab-span'>Funding</span>
          </div>
        }
        {((company.boardMemebers && company.boardMemebers.length > 0) ||
        (company.partners && company.partners.length > 0)) &&
          <div className={`b_18_regular tab ${activeIndex === 2 ? 'b_18_semibold active' : ''}`} onClick={() => setTab(2, 'Partners & Customers')}>
            <span className='lg-tab-span'>Partners & Customers</span>
            <span className='sm-tab-span'>Partners</span>
          </div>
        }
      </div>
    </div>
  )
}

export default UnicornDetailsTabs
