import { useEffect, useState } from 'react'
import '../../assets/scss/style.scss'
import PropTypes from 'prop-types'
import { images } from 'assets'
import Tooltip from './Tooltip'
const Input = ({ className = '', label, input = { onChange: null }, type, placeholder, meta = { error: null, touched: false }, disabled, children, onChange = null, onFocus = null, onBlur = null, onClick = null, maxLength, checked = false, value, autoFocus = false, autoComplete = null, redirect = '', viewPassword, disableLabel = false, tooltip = false, content = '', style = {}, ariaLabel = '' }) => {
  const [showHover, setShowHover] = useState(false)
  useEffect(() => {
    if (label?.includes('optional')) {
      const label = document.getElementById('referred-by-label')
      let str = label.innerHTML
      if (str?.indexOf('optional') >= -1) {
        str = str.replace(/\(([^)]+)\)/g, '<span style="color:#899A9C;">(optional)</span>')
      }
      document.getElementById('referred-by-label').innerHTML = str
    }
  }, [label])

  if (type === 'checkbox') {
    if (disableLabel) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }} className={`input-group checkbox ${(checked && disabled) && 'default-check'} ${className && className} ${(meta.touched && meta.error) && 'error'}`}>
          <label className='b_16_regular checkbox-label'>
            <input placeholder={placeholder} onChange={onChange || input.onChange} onFocus={input.onFocus} value={value || input.value} defaultChecked={input.value} type={type} disabled={disabled} />
            <div className={`checkmark ${checked && 'disabled'}`} />
          </label>
          <div className='children'>{children}</div>
        </div>
      )
    } else {
      return (
        <div className={`input-group checkbox ${(checked && disabled) && 'default-check'} ${className && className} ${(meta.touched && meta.error) && 'error'}`}>
          <label className='b_16_regular checkbox-label'>
            <input placeholder={placeholder} onChange={onChange || input.onChange} onBlur={onBlur || input.onBlur} onFocus={input.onFocus} value={value || input.value} defaultChecked={checked} type={type} disabled={disabled} />
            <div className={`checkmark ${checked && 'disabled'}`} />
            <div className='children'>{children}</div>
          </label>
        </div>
      )
    }
  }

  if (type === 'radio') {
    return (
      <div className={`radio-option ${className && className} ${(meta.touched && meta.error) && 'error'}`}>
        <input placeholder={placeholder} onChange={onChange && onChange} onFocus={onFocus && onFocus} value={input.value} checked={checked} type={type} disabled={disabled} {...input} />
        <label onClick={onClick && onClick} className='radio__label'>
          {label}
        </label>
      </div>
    )
  }

  if (type === 'textarea') {
    return (
      <div className={`input-group ${className} ${(meta.touched && meta.error) && 'error'}`}>
        <label>{label}</label>
        <textarea aria-label={ariaLabel || `${label?.replace(/\s+/g, '-').toLowerCase()}-input`} onChange={onChange && onChange} onFocus={onFocus && onFocus} disabled={disabled} maxLength={maxLength} {...input} placeholder={placeholder}></textarea>
        {(meta.touched && meta.error) && <div className='b_16_semibold err-msg'>{meta.error}</div>
        }
      </div>)
  }

  if (label?.includes('Password')) {
    return (
      <div className={`input-group ${className} ${(meta.touched && meta.error) && 'error'}`}>
        <label htmlFor={label?.toLowerCase()}>{label}</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input aria-label={ariaLabel || `${label?.replace(/\s+/g, '-').toLowerCase()}-input`} style={{ width: '100%', padding: '0px 45px 0px 16px' }} {...input} onChange={onChange || input.onChange} onFocus={onFocus && onFocus} placeholder={placeholder} value={value || input.value} type={type} disabled={disabled} className={label === 'Amount(USD)' ? 'text-end' : null} maxLength={maxLength} autoFocus={autoFocus} autoComplete={autoComplete} />
          <img onClick={viewPassword} style={{ marginLeft: '-40px', cursor: 'pointer' }} alt='close-icon' src={type === 'password' ? images['show-password'] : images['closed-eye']} height={25} width={25} />
        </div>
        {(meta.touched && meta.error) && <div className='b_16_semibold err-msg'>{meta.error}</div>}
        {(meta.touched && meta.warning && redirect) && <div className='b_16_semibold err-msg'>{meta.warning}</div>}
      </div>
    )
  }
  if (tooltip) {
    style.cursor = 'pointer'
  }
  return (
    <div className={`input-group ${className} ${(meta.touched && meta.error) && 'error'}`}>
      <label htmlFor={label?.toLowerCase()} id={label?.includes('optional') ? 'referred-by-label' : ''} style={style}><span onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)}>{label}</span> {tooltip && <Tooltip content={content} showHover={showHover} setShowHover={setShowHover}/>}</label>
      <input aria-label={ariaLabel || `${label?.replace(/\s+/g, '-').toLowerCase()}-input`} {...input} onChange={onChange || input.onChange} onFocus={onFocus && onFocus} onBlur={onBlur || input.onBlur} placeholder={placeholder} value={value || input.value} type={type} disabled={disabled} className={label === 'Amount(USD)' ? 'text-end' : null} maxLength={maxLength} autoFocus={autoFocus} autoComplete={autoComplete} />
      {(meta.touched && meta.error) && <div className='b_16_semibold err-msg'>{meta.error}</div>}
      {(meta.touched && meta.warning && redirect) && <div className='b_16_semibold err-msg'>{meta.warning}</div>}
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'checkbox', 'tel', 'textarea', 'radio', 'hidden']),
  label: PropTypes.string
}

export default Input
