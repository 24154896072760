import { Mixpanel, getDomain } from 'utils'
import { NavLink } from 'react-router-dom'
const NavItem = ({ setToggleStatus, toggleStatus, item }) => {
  const clickMainNav = () => {
    Mixpanel.track('Click Main Nav', { 'Main Nav Item': item?.name })
    setToggleStatus(false)
  }
  // Render Log Out
  if (item.action) {
    return (
      <div
        key={`nav-${item.tag}`}
        className={`item dropdown ${item.tag === 'signout' ? 'red' : ''}`}
        onClick={item.action}
      >
        {item.name}
      </div>
    )
  }
  // Render links that take user to outside site -> blog.linqto.com
  if (item?.routeType === 'external') {
    return (
      <a
        key={`nav-${item.tag}`}
        href={item.route}
        onClick={() => setToggleStatus(false)}
        className={`item  ${item.tag} ${toggleStatus ? 'mobile' : ''}`}
      >
        {item.style && item.style === 'button' ? (
          <div className='nav-button'>{item.name}</div>
        ) : (
          item.name
        )}
      </a>
    )
  }

  if (item.cms && window.location.hostname.includes('app')) {
    return (
      <a
        key={`nav-${item.tag}`}
        href={getDomain(item.route, item?.cms)}
        onClick={clickMainNav}
        className={`item  ${item.tag} ${toggleStatus ? 'mobile' : ''}`}
      >
        {item.style && item.style === 'button' ? (
          <div className='nav-button'>{item.name}</div>
        ) : (
          item.name
        )}
      </a>
    )
  }
  return (
    <NavLink
      key={`nav-${item.tag}`}
      to={item.route}
      onClick={clickMainNav}
      className={`item  ${item.tag} ${toggleStatus ? 'mobile' : ''}`}
    >
      {item.style && item.style === 'button' ? (
        <div className='nav-button'>{item.name}</div>
      ) : (
        item.name
      )}
    </NavLink>
  )
}

export default NavItem
