import { formatDecimal, toCamalCase } from 'utils'
import { useSelector } from 'react-redux'

const PlaceOrderHeader = ({ sharePrice }) => {
  const { details, linqtoImpliedValuation } = useSelector(state => state.investSlice)
  const { isSoldOut, promoFlag } = details

  const showBlur =
  promoFlag === 'FULLY_SUBSCRIBED' ||
  (promoFlag === 'COMING_SOON' && linqtoImpliedValuation === 'TBD') ||
  (promoFlag === 'COMING_SOON' && sharePrice === '')

  return (
    <div className='invest-order-header'>
      <div className='b_18_semibold'>
        <span className='b_14_regular sub-header'>Status</span>
        <span className={promoFlag === 'FULLY_SUBSCRIBED' ? 'fully_subscribed' : ''}>{!isSoldOut ? 'Available' : toCamalCase(promoFlag.replace(/_/g, ' ')) }</span>
      </div>
      <div className='b_18_semibold'>
        <span className='b_14_regular sub-header'>Implied Valuation</span>
        <span className={showBlur ? 'blured' : ''}>{linqtoImpliedValuation}</span>
      </div>
      <div className='heading_7'>
        <span className='b_14_regular sub-header'>Share Price</span>
        <span className={`heading_7 ${showBlur && 'blured'}`}>{sharePrice ? formatDecimal(sharePrice) : '$0.00'}</span>
      </div>
    </div>
  )
}

export default PlaceOrderHeader
