import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate } from 'utils'
import { deleteAccountReasons } from '../../data'
import { submitDeleteAccount } from 'slices/securitySlice'
import Button from '../../Global/Button'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import SuccessModal from './SuccessModal'
import DeleteAccountReasons from './DeleteAccountReasons'
import { FormProvider, useForm } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import { schema } from 'schemas/deleteAccountSchema'
import { yupResolver } from '@hookform/resolvers/yup'

const url = 'https://hs-20548088.s.hubspotemail.net/hs/manage-preferences/unsubscribe'

const DeleteAccount = () => {
  const dispatch = useDispatch()
  const [showConfimModal, setShowConfimModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { loading } = useSelector((state) => state.securitySlice)
  const methods = useForm({ resolver: yupResolver(schema) })
  const { watch, handleSubmit, unregister, setValue } = methods

  const reason = watch('reason')
  const otherReason = watch('otherReason')

  useEffect(() => {
    if (reason !== 'Other (Please specify below)') {
      setValue('otherReason', '')
      unregister('otherReason', { keepError: false })
    }
  }, [reason])

  const onSubmit = () => setShowConfimModal(true)

  const submitRequestDelete = async () => {
    const option = reason === 'Other (Please specify below)' ? otherReason : reason
    await dispatch(submitDeleteAccount(option))
    setShowConfimModal(false)
    setShowDeleteModal(true)
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Delete Account')} />
      <FormProvider {...methods}>
        <form className='page-container' onSubmit={handleSubmit(onSubmit)}>
          <div className='inner-container'>
            <div className='box delete-account-box'>
              <div className='heading_7'>We&apos;re sorry to see you go.</div>
              <div className='b_18_regular'>
                Why are you requesting account deletion?
              </div>
              <DeleteAccountReasons reasons={deleteAccountReasons} url={url} />
              <TextInput name='otherReason' disabled={reason !== 'Other (Please specify below)' || loading} ariaLabel='OtherReason' />
              <div className='cta-container'>
                <Button loading={loading} disabled={loading || reason === null}>
                  Request Account Deletion
                </Button>
              </div>
            </div>
          </div>
          {showConfimModal && <ConfirmDeleteModal submitRequestDelete={submitRequestDelete} setShowConfimModal={setShowConfimModal} loading={loading} />}
          {showDeleteModal && <SuccessModal />}
        </form>
      </FormProvider>
    </>
  )
}

export default DeleteAccount
