import { formatDecimal, renderTotalsOrDash } from 'utils'
import { useSelector } from 'react-redux'

const SellOrderTotals = () => {
  const { projectedGain, avgOriginalHeldPricePerShare, totalOriginalHeldSharePrice } = useSelector(state => state.sellOffer)
  return (
    <>
      <div className='sell-offer-totals sell-offer-order '>
        <div className='sell-offer-row total-spaced'>
          <span>Cost Basis of Shares</span>
          <span>{renderTotalsOrDash(avgOriginalHeldPricePerShare, formatDecimal(totalOriginalHeldSharePrice))}</span>
        </div>
        <div className='sell-offer-row'>
          <span>Average Cost / Share</span>
          <span>{renderTotalsOrDash(totalOriginalHeldSharePrice, formatDecimal(avgOriginalHeldPricePerShare))}</span>
        </div>
        <div className='sell-offer-row'>
          <span>Your Projected Gain</span>
          <span>{renderTotalsOrDash(projectedGain, formatDecimal(projectedGain))}</span>
        </div>
      </div>
      <div className='sell-offer-totals'>
        <div className='sell-offer-row spaced'>
          <span className='customer-support-copy'>
          Shares are sold in the order they were purchased.
          </span>
        </div>
      </div>
    </>
  )
}

export default SellOrderTotals
