import ProgressBar from '../../Profile/partials/ProgressBar'
import AccreditationStatus from './AccreditationStatus'
import { useSelector } from 'react-redux'
import Tooltip from 'components/Global/Tooltip'
import { useState } from 'react'

const content = 'Your legal name is used for identity verification, tax reporting, and other legal documents. Contact support for questions.'

const InvestorStatusHeader = ({
  isUserProfileComplete,
  hasKyc,
  isAccredited,
  accreditedStatus
}) => {
  const { email, legalName } = useSelector(state => state.investorStatusSlice)
  const [show, setShow] = useState(false)
  return (
    <div className='investor-status-header-container'>
      <div className='profile-progress-container'>
        <h1 id='investor-status-title' className='subinfo'>
          Investor Profile
          <div className='b_16_regular web-account-email'>
            <span>
              Account Email: <span className='profile-email'>{email}</span>
            </span>
            <br />
            <span>
              Legal Name: <span className='profile-email'>{legalName}</span>
            </span>
            <Tooltip
              content={content}
              showHover={show}
              setShowHover={setShow}
              configurable={false}
            />
          </div>
        </h1>
        <hr className='title-separator' />
        <ProgressBar
          activeStep='Verify'
        />
      </div>
      <h3 className='investor-status-sub-title'>
        Verify Accreditation
        <AccreditationStatus accreditedStatus={accreditedStatus} />
      </h3>
    </div>
  )
}

export default InvestorStatusHeader
