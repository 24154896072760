import SecondaryButton from 'components/Global/SecondaryButton'
import { useState } from 'react'

const InvestDetailAboutKeyFacts = ({ keyFacts }) => {
  const [showAllKeyFacts, setShowAllKeyFacts] = useState(false)
  let croppedKeyFacts = [...keyFacts.slice(0, 3)]

  if (showAllKeyFacts) {
    croppedKeyFacts = [...keyFacts]
  } else {
    croppedKeyFacts = [...keyFacts.slice(0, 3)]
  }

  return (
    <>
      <ul className='key-facts__list'>
        {croppedKeyFacts && croppedKeyFacts.map((p, i) =>
          <li className='b_16_regular key-facts__fact' key={`key-fact${i}`}>{p.fact}</li>
        )}
      </ul>
      <SecondaryButton text={`${showAllKeyFacts ? 'Show Less' : 'Load More'} Key Facts`} onClick={() => setShowAllKeyFacts(!showAllKeyFacts)} />
    </>
  )
}
export default InvestDetailAboutKeyFacts
