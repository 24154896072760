import { images } from 'assets'

const UpholdWalletHeader = ({
  hasUphold,
  connectUpholdWallet,
  disconnectUpholdWallet
}) => {
  return (
    <>
      <div>
        <div className='uphold-wallet-row uphold-wallet-row-header'>
          <div>
            <img src={images['wallet-uphold']} alt='uphold' />
            Uphold
          </div>
          <div>
            {!hasUphold && (
              <a className='link primary' onClick={connectUpholdWallet}>
                Link Account
              </a>
            )}
            {hasUphold && (
              <a className='link primary' onClick={disconnectUpholdWallet}>
                Disconnect
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default UpholdWalletHeader
