import ProgressBar from './ProgressBar'
import Tooltip from 'components/Global/Tooltip'
import { useState } from 'react'

const content = 'Your legal name is used for identity verification, tax reporting, and other legal documents. Contact support for questions.'

const ProfileHeader = ({
  email,
  legalName,
  isAccredited,
  hasKyc,
  showFinancialPage,
  activeStep
}) => {
  const [show, setShow] = useState(false)
  return (
    <div className='header-progressbar-wrapper'>
      <h1 className='subinfo'>
        Investor Profile
        <div className='b_16_regular web-account-email'>
          <span>
            Account Email: <span className='profile-email'>{email}</span>
          </span>
          <br />
          <span>
            Legal Name: <span className='profile-email'>{legalName}</span>
          </span>
          <Tooltip
            content={content}
            showHover={show}
            setShowHover={setShow}
            configurable={false}
          />
        </div>
      </h1>
      <hr className='title-separator' />
      {(hasKyc && !isAccredited) && <ProgressBar
        activeStep={activeStep}
      />}
      <div className='mobile-account-email'>
        <span>
          Account Email: <span className='profile-email'>{email}</span>
        </span>
        <br />
        <span>
          Legal Name: <span className='profile-email'>{legalName}</span>
        </span>
        <Tooltip
          content={content}
          showHover={show}
          setShowHover={setShow}
        />
      </div>
    </div>
  )
}

export default ProfileHeader
