import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import { useEffect } from 'react'
import { Mixpanel } from 'utils'

const ReferralError = ({ referralErrorMessage, closeModal }) => {
  useEffect(() => {
    if (referralErrorMessage) {
      Mixpanel.track('Referral Error', { 'Error Type': referralErrorMessage?.message })
    }
  }, [referralErrorMessage])

  return (
    <Modal innerStyle='signup-error' size='sm' modalHeader={referralErrorMessage?.title} mode='primary' crossToClose hideModal={closeModal}>
      <div className='items-center'>
        <p>
          {referralErrorMessage?.message}
        </p>
      </div>
      <div className='btn-group centered'>
        <Button onClick={closeModal}>
          {referralErrorMessage?.actionLabel}
        </Button>
      </div>
    </Modal>
  )
}

export default ReferralError
