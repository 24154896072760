import TextInput from 'components/Global/Inputs/TextInput'
import PasswordInput from 'components/Global/Inputs/PasswordInput'
import { useFormContext } from 'react-hook-form'
import Checkbox from 'components/Global/Inputs/Checkbox'
import Button from 'components/Global/Button'
import AccreditationOptions from './AccreditationOptions'
import SignupTerms from './SignupTerms'
import { useSelector } from 'react-redux'
import EmailInput from 'components/Global/Inputs/EmailInput'
import SignUpOAuthInfo from './SignUpOAuthInfo'
import SignInLink from './SignInLink'

const SignUpForm = ({
  onSubmit,
  isFromOrigination,
  setShowModal,
  submitLoading,
  oAuthToken
}) => {
  const { err } = useSelector(
    (state) => state.userSlice
  )
  const { handleSubmit } = useFormContext()
  return (
    <>
      <form className='grid' onSubmit={handleSubmit(onSubmit)} noValidate>
        {!oAuthToken &&
        <>
          <div className='column sixteen eight-mobile'>
            <TextInput
              name='firstName'
              label='First Name'
              ariaLabel='firstName'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen eight-mobile'>
            <TextInput
              name='lastName'
              label='Last Name'
              ariaLabel='lastName'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen'>
            <EmailInput
              name='email'
              label='Email'
              ariaLabel='email'
              disabled={submitLoading}
              autoComplete='do-not-autofill'
            />
          </div>
          <div className='column sixteen'>
            <PasswordInput
              name='password'
              label='Password'
              ariaLabel='password-input'
              disabled={submitLoading}
              autoComplete='new-password'
            />
          </div>
        </>
        }
        {oAuthToken &&
          <SignUpOAuthInfo err={err} />
        }
        <div className='column sixteen'>
          <Checkbox
            label='I would like to receive updates on new investment opportunities, price reductions, and market insights'
            name='marketingOptIn'
            ariaLabel='marketingOptIn'
            checkboxClass='marketing-checkbox'
          />
        </div>
        {isFromOrigination && (
          <AccreditationOptions setShowModal={setShowModal} />
        )}
        <div className='column sixteen'>
          <SignupTerms isFromOrigination={isFromOrigination} setShowModal={setShowModal} />
        </div>
        {err && <div className='column sixteen  text-error'>
          <span>An account already exists using this email address. Please <a className='b_16_regular' href='/signin'>sign in</a>.</span>
        </div>}
        <div className='column sixteen'>
          <div className='btn-group full-width'>
            <Button
              type='submit'
              loading={submitLoading}
              disabled={submitLoading}
              customClass='full-width'
            >
              {isFromOrigination ? 'Create Account' : err === 500 && oAuthToken ? 'Use Different Email' : 'Start Investing'}
            </Button>
          </div>
        </div>
        {!oAuthToken && <div className='column sixteen'>
          <SignInLink />
        </div>}
      </form>
    </>
  )
}

export default SignUpForm
