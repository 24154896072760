import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/signUpSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageLoading } from 'components'
import ReferralInfo from './partials/ReferralInfo'
import ReferralError from './partials/ReferralError'
import SignUpForm from './partials/SignUpForm'
import AccreditationModal from './partials/AccreditationModal'
import { seoTitleTemplate, Mixpanel, getDomain } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { getSignupReferral, signUp, clearErrorState } from 'slices/userSlice'
import { handleReCaptchaVerify } from 'utils/googleRecaptcha'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import SignInOAuthBtns from './partials/SignInOAuthBtns'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import SignUpHeader from './partials/SignUpHeader'

const SignUp = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = location.search
  const r = qs.parse(query, { ignoreQueryPrefix: true })?.r || null
  const ria = qs.parse(query, { ignoreQueryPrefix: true })?.ria || null
  const [referralError, setReferralError] = useState(false)
  const [referralErrorMessage, setReferralErrorMessage] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [referralType, setReferralType] = useState(r ? 'linqto-referral' : ria ? 'advisor-referral' : '')
  const [submitLoading, setSubmitLoading] = useState(false)
  const signupToKycFlag = useFeatureIsOn('signup_to_kyc')

  const pathname = location.pathname
  const [oAuthToken, setOAuthToken] = useState((pathname === '/signup/create-account' ? JSON.parse(sessionStorage?.getItem('oAuthToken')) : null) || null)

  const fromOrigination = qs.parse(query, { ignoreQueryPrefix: true })?.fromOrigination || false
  const fromOriginationCompany = qs.parse(query, { ignoreQueryPrefix: true })?.company || null
  const referralCode = r || ria || null
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), context: { fromOrigination, oAuthToken } })
  const { executeRecaptcha } = useGoogleReCaptcha()
  const oAuthSignupFlag = useFeatureIsOn('oauth_signup')
  const {
    isSignedIn,
    isUserReturned,
    referrerName,
    riaFirmName,
    signupBonus,
    referrerLogoUrl,
    hasReferrer,
    accreditationTerms,
    pageLoading,
    err
  } = useSelector((state) => state.userSlice)

  useEffect(() => {
    if (oAuthToken) {
      Mixpanel.track('View OAuth Page')
    }
  }, [oAuthToken])

  useEffect(() => {
    if (ria) {
      localStorage.setItem(
        'ria-route',
        `/registered-investment-advisor?ria=${ria}`
      )
    }
    dispatch(getSignupReferral({ referralCode, referralType })).then(
      ({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.registerUTM()
          const ReferralByAnotherUser = payload?.hasReferrer && !payload?.isRiaReferral
          const ReferredByAnAdvisor = payload?.hasReferrer && payload?.isRiaReferral
          const ReferralCredit = payload?.signupBonus || 0
          Mixpanel.register({ 'Referral by another user': ReferralByAnotherUser, 'Referred by an advisor': ReferredByAnAdvisor, 'Referral credit': ReferralCredit, Platform: 'Web' })
          Mixpanel.track('View Sign Up Page')
          if (ReferralByAnotherUser) {
            setReferralType('linqto-referral')
          } else if (ReferredByAnAdvisor) {
            setReferralType('advisor-referral')
          }
          if (!payload?.hasReferrer) {
            if (payload?.notifyMessage) {
              setReferralError(true)
              setReferralErrorMessage({
                message: payload?.notifyMessage,
                title: 'Notice',
                actionLabel: 'OK',
                action: 'redirect'
              })
            } else if (referralCode) {
              setReferralError(true)
              setReferralErrorMessage({
                message:
                  "The referral link has expired or the referrer doesn't exist. Please sign up and provide the referrer's name in the form so we can manually match it for you.",
                title: 'Something Went Wrong',
                actionLabel: 'Continue to sign up',
                action: 'close'
              })
            }
          }
        }
      }
    )
  }, [])

  useEffect(() => {
    if (isUserReturned) {
      if (hasReferrer && isSignedIn) {
        history.push(`/registered-investment-advisor?ria=${ria}`)
      }
    }
  }, [isUserReturned, hasReferrer])

  useEffect(() => {
    if (showModal) {
      Mixpanel.track('View Accreditation Terms')
    }
  }, [showModal])

  // cleanup to reset err when component unmounts (link from signup to signin page)
  useEffect(() => () => dispatch(clearErrorState()), [])

  useEffect(() => {
    // solving if user go back on the browser after going to the create account page
    window.onpopstate = e => {
      if (pathname === '/signup/create-account') {
        sessionStorage.removeItem('oAuthToken')
        setOAuthToken(null)
      }
    }
  })

  useEffect(() => {
    // solving if user landed to create-account page without being clicking on aouth buttons
    // user will be directed to sign up
    if (pathname === '/signup/create-account' && !oAuthToken) {
      history.push('/signup')
    }
    if (pathname === '/signup') {
      setOAuthToken(null)
    }
  }, [pathname])

  const onSubmit = async (formValues) => {
    // dispatch action to clean up error & form state (from uAuth flow), then redirect user to signup page
    if (err && err === 500 && oAuthToken) {
      dispatch(clearErrorState())
      history.goBack()
      methods.reset()
      return
    }
    setSubmitLoading(true)
    const redirectUrl = fromOrigination
      ? fromOriginationCompany
        ? `/sell/${fromOriginationCompany}`
        : '/sell/'
      : ria
        ? `/registered-investment-advisor?ria=${ria}`
        : ''
    const token = await handleReCaptchaVerify(executeRecaptcha, 'signUp')
    const mixpanelDistinctId = Mixpanel.getDistinctId()
    let bodyTosend = {
      accreditedCountry: '',
      selfAccreditation: 'UNKNOWN',
      fromOrigination: fromOrigination,
      redirectUrl,
      token,
      externalTrackingId: mixpanelDistinctId
    }
    if (oAuthToken) {
      Mixpanel.track('Click Sign Up on OAuth Page')
      bodyTosend.oAuthType = oAuthToken.type
      bodyTosend.marketingOptIn = formValues?.marketingOptIn

      if (oAuthToken.type === 'Google') {
        bodyTosend.googleAccessToken = oAuthToken.value
      }

      if (oAuthToken.type === 'Apple') {
        bodyTosend.appleAccessToken = oAuthToken.value
        bodyTosend.firstName = oAuthToken.firstName
        bodyTosend.lastName = oAuthToken.lastName
      }

      if (fromOrigination) {
        bodyTosend.accreditOption = formValues.accreditOption
      }
    } else {
      bodyTosend = {
        ...formValues,
        ...bodyTosend
      }
    }
    dispatch(signUp(bodyTosend)).then(({ meta, ...data }) => {
      setSubmitLoading(false)
      if (meta.requestStatus === 'fulfilled') {
        if (oAuthToken) {
          Mixpanel.track('Confirm Registration-Success')
          if (redirectUrl) {
            history.push(redirectUrl)
          } else {
            if (signupToKycFlag) {
              history.push('/profile')
            } else {
              history.push('/products')
            }
          }
        } else {
          history.push({
            pathname: '/activate',
            state: { email: bodyTosend.email }
          })
        }
      } else {
        const isServerError = data?.payload?.data?.error || ''
        if (isServerError === 'REGISTRATION_EMAIL_ALREADY_ACTIVATED') {
          methods.setError('email')
        }
      }
    })
  }

  const closeModal = () => {
    setReferralError(false)
    if (referralErrorMessage?.action === 'redirect') {
      const message = referralErrorMessage.message
      if (message.indexOf('Forge Trust') >= 0) {
        history.push('/rewards')
      } else {
        window.location.href = getDomain('/', true)
      }
    }
  }

  const onOAuthSuccess = async (accessToken, type, firstName = '', lastName = '') => {
    dispatch(clearErrorState())
    const token = { type, value: accessToken, firstName, lastName }
    setOAuthToken(token)
    sessionStorage.setItem('oAuthToken', JSON.stringify({ ...token }))
    let url = ''
    if (r) url = `?r=${r}`
    if (ria) url = `?ria=${ria}`
    if (fromOrigination) url = `?fromOrigination=${fromOrigination}`
    if (fromOriginationCompany) url += `&company=${fromOriginationCompany}`
    history.push(`/signup/create-account${url}`)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Sign Up')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      {showModal && (
        <AccreditationModal
          hideModal={() => setShowModal(false)}
          accreditationTerms={accreditationTerms}
        />
      )}
      <SeoMeta title={seoTitleTemplate('Sign Up')}>
        <link rel='canonical' href='https://app.linqto.com/signup' />
      </SeoMeta>
      <div className='page-container'>
        <div className='inner-container wide-inner-container'>
          <div className='signup-container'>
            <div className='grid'>
              <div className='column eight sixteen-mobile'>
                <div className='signup-header-container'>
                  <SignUpHeader
                    pathname = { pathname}
                  />
                  {hasReferrer && (
                    <ReferralInfo
                      referrerName={referrerName}
                      referralAmount={signupBonus}
                      referrerLogo={referrerLogoUrl || ''}
                      riaFirmName={riaFirmName}
                      type={referralType}
                    />
                  )}
                  {referralError && (
                    <ReferralError
                      referralErrorMessage={referralErrorMessage}
                      closeModal={closeModal}
                    />
                  )}
                </div>
              </div>
              <div className='column eight sixteen-mobile'>
                <div className='signup-form'>
                  <SignInOAuthBtns
                    oAuthToken={oAuthToken}
                    oAuthShowFlag={oAuthSignupFlag}
                    onOAuthSuccess={onOAuthSuccess}
                  />
                  <FormProvider {...methods}>
                    <SignUpForm
                      onSubmit={onSubmit}
                      isFromOrigination={fromOrigination}
                      setShowModal={setShowModal}
                      referralCode={referralCode}
                      submitLoading={submitLoading}
                      oAuthToken={oAuthToken}
                    />
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp
