import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const AddFundsError = ({ selectedAccount }) => {
  const { order: { total } } = useSelector(
    (state) => state.placeOrderSlice
  )

  const history = useHistory()

  const goToAddFunds = () => {
    history.push(`/cash-account/add-funds${selectedAccount?.entityId ? `?entityId=${selectedAccount?.entityId}` : ''}`)
  }
  const cashBalance = selectedAccount?.amountAvailable
  if (total <= cashBalance) return null
  return (
    <div className='sell-offer-row'>
      <span className='red'>Please <a className='inline-text-link' onClick={goToAddFunds}>add funds</a> to complete this order</span>
    </div>
  )
}

export default AddFundsError
