import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import axios from 'axios'
import api from '../apis/private.js'
const initialState = {
  pageLoading: false,
  advisorName: '',
  allowDocumentDelete: true,
  documents: [],
  financialAdvisorRequestId: '',
  firstName: '',
  isAccredited: false,
  lastName: '',
  showVerificationForm: false,
  country: '',
  financialAdvisorAttestation: {
    description: '',
    options: [
      {
        accreditationType: '',
        optionId: '',
        optionText: ''
      }
    ]
  }
}

export const getFinancialAdvisor = createAsyncThunk('getFinancialAdvisor', (query, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const config = {
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/page/financialAdvisor`,
    headers: query
  }
  return axios(config)
    .then((res) => {
      return fulfillWithValue(res.data)
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const saveFinancialAdvisorReqest = createAsyncThunk('saveFinancialAdvisorRequest', (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api.post('/page/financialAdvisor', { ...formValues })
    .then(() => {
      return fulfillWithValue()
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const financialAdvisorSlice = createSlice({
  name: 'financialAdvisor',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFinancialAdvisor.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getFinancialAdvisor.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getFinancialAdvisor.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(saveFinancialAdvisorReqest.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(saveFinancialAdvisorReqest.fulfilled, (state, { payload }) => {
        return {
          pageLoading: false,
          ...payload
        }
      })
      .addCase(saveFinancialAdvisorReqest.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default financialAdvisorSlice.reducer
