import Button from 'components/Global/Button'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const ProfileSubmitBtn = ({
  redirect,
  isAccreditedRedirect,
  setDirectToVerifyStatus,
  onSubmit
}) => {
  const {
    handleSubmit,
    formState: { isDirty }
  } = useFormContext()
  const { profileLoading, isAccredited } = useSelector(state => state.profile)
  const disabled = profileLoading || !isDirty

  if (redirect && !isAccredited) {
    return (
      <div className='btn-group start side-by-side'>
        {!isAccreditedRedirect && (
          <Button disabled={disabled} mode='primary-alt'
            onClick={ () => {
              Mixpanel.track('Click Basic Information Save Button', { 'Save Type': 'Save and Return' })
              handleSubmit(onSubmit)
            }}>
            Save and Return
          </Button>
        )}
        <Button
          disabled={profileLoading}
          onClick={() => {
            Mixpanel.track('Click Basic Information Save Button', { 'Save Type': 'Save and Verify' })
            setDirectToVerifyStatus(true)
          }}
        >
          Save and Verify
        </Button>
      </div>
    )
  } else if (redirect && !isAccreditedRedirect && isAccredited) {
    return (
      <Button
        disabled={disabled}
        mode='primary-alt'
        onClick={() => {
          Mixpanel.track('Click Basic Information Save Button', { 'Save Type': 'Save and Return' })
          handleSubmit(onSubmit)
        }}
      >
        Save and Return
      </Button>
    )
  } else if (!redirect && !isAccredited) {
    return (
      <Button
        disabled={disabled}
        onClick={(e) => {
          Mixpanel.track('Click Basic Information Save Button', { 'Save Type': 'Save and Continue to Accreditation' })
          setDirectToVerifyStatus(true)
        }}
      >
        Save and Continue to Accreditation
      </Button>
    )
  } else {
    return (
      <Button
        disabled={disabled}
        onClick={ () => {
          Mixpanel.track('Click Basic Information Save Button', { 'Save Type': 'Update' })
          handleSubmit(onSubmit)
        }}
      >
        Update
      </Button>
    )
  }
}

export default ProfileSubmitBtn
