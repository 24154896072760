import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const SellOfferHeader = ({ title, showLearnMoreLink }) => {
  const { width } = useWindowSize()
  const { companyName, companyIconUrl } = useSelector(
    (state) => state.sellOffer
  )

  const howItWorksUR = window.location.pathname.includes('/sell-offer') ? '/sell-offer/how-it-works' : window.location.pathname.includes('/offer') ? '/sell-offer/how-it-works?anchor=faq' : null

  const handleClick = () => {
    if (window.location.pathname.includes('/sell-offer')) {
      Mixpanel.track('Click See How It Works on Create Sell Offer Page')
    } else if (window.location.pathname.includes('/offer')) {
      Mixpanel.track('Click See How It Works on Sell Offer Details Page')
    }
  }

  return (
    <div className='sell-offer-header'>
      {width >= 767 && showLearnMoreLink ? <div>
        <h1>{title}</h1>
        <div className='sell-offer-header__copy'>Want to learn more?{' '}
          <a href={howItWorksUR} onClick={handleClick} className='sell-offer-header__copy link primary' target='_blank' rel='noreferrer'>See How It Works</a>
        </div>
      </div> : <h1>{title}</h1>}
      <div className='sell-offer-company-name'>
        <img src={companyIconUrl} />
        <h3>{companyName}</h3>
      </div>
      {width <= 767 && showLearnMoreLink ? <div className='sell-offer-header__copy'>Want to learn more?{' '}
        <a className='sell-offer-header__copy link' href={howItWorksUR} onClick={handleClick} target='_blank' rel='noreferrer'>See How It Works</a></div> : null}
    </div>
  )
}

export default SellOfferHeader
