import React from 'react'
import { formatDecimal } from 'utils'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const AccountItem = ({ icon, accountName, balance, goTo }) => {
  const history = useHistory()
  return (
    <div className={`current-investment-item ${accountName === 'Linqto Bucks' ? 'current-investment-item_single' : ''}`} onClick={() => history.push(goTo)}>
      <div className='b_18_semibold current-investment-item_left'>
        <img src={images[icon]} />
        <span>{accountName}</span>
      </div>
      <div className='current-investment-item_right'>
        <div className='current-investment-item_right shares-total-price-container'>
          <span className='b_18_semibold current-investment-item_right total-price'>{formatDecimal(balance)}</span>
        </div>
        <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} />
      </div>
    </div>
  )
}

export default AccountItem
