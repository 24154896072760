import { useSelector } from 'react-redux'
import { formatDecimal, Mixpanel } from 'utils'
import LinqtoBucksToggle from './LinqtoBucksToggle'
import PromoCodeInput from './PromoCodeInput'

const Payment = ({ setSelectedAccount, selectedAccount, useLinqtoBucks, setUeLinqtoBucks, refreshOrder, promoCode, setPromoCode, promoCodeError, setPromoCodeError }) => {
  const { accounts, order: { amount, linqtoFee, linqtoBucksUsed, promotionalCredit, total, discountAmount, discountPercent, commitLoading } } = useSelector(
    (state) => state.placeOrderSlice
  )

  const handlePurchaseAsChange = (e) => {
    setSelectedAccount(accounts[e.target.value])
    Mixpanel.track('Change Purchase As Account on Buy Order Review Page')
  }

  return (
    <div className='sell-offer-section wih-margin'>
      <div className='heading_7 sell-offer-section-title'>
        <span>2</span> Payment
      </div>
      <div className='sell-offer-order'>
        {accounts?.length > 1 &&
        <div className='sell-offer-row'>
          <span className='select-input'>
            <label>Purchase as</label>
            <select aria-label='purchase-as' onChange={handlePurchaseAsChange} disabled={commitLoading}>
              {accounts?.length > 0 && accounts.map((e, i) => <option value={i} key={i}>{e.accountName}</option>)}
            </select>
          </span>
        </div>
        }
        <div className='sell-offer-row'>
          <span>Cash Balance</span>
          <span>{formatDecimal(selectedAccount?.amountAvailable)}</span>
        </div>
        <LinqtoBucksToggle
          useLinqtoBucks = {useLinqtoBucks}
          setUeLinqtoBucks={setUeLinqtoBucks}
          refreshOrder={refreshOrder}
          setPromoCode= {setPromoCode}
          setPromoCodeError = {setPromoCodeError} />
        <PromoCodeInput
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          setUeLinqtoBucks={setUeLinqtoBucks}
          refreshOrder={refreshOrder}
          promoCodeError = {promoCodeError}
          setPromoCodeError = {setPromoCodeError}/>
        <div className='sell-offer-row'>
          <span>Investment Amount</span>
          <span>{formatDecimal(amount)}</span>
        </div>
        {discountAmount > 0 && <div className='sell-offer-row'>
          <span>Promo Discount</span>
          {discountPercent > 0 && <div className='promo'>-{discountPercent * 100}% ({formatDecimal(discountAmount)})</div>}
          {(!discountPercent && discountAmount > 0) && <div className='promo'>-{formatDecimal(discountAmount)}</div>}
        </div>
        }
        <div className='sell-offer-row'>
          <span>Linqto Fee</span>
          <span>{formatDecimal(linqtoFee)}</span>
        </div>
        {linqtoBucksUsed > 0 && <div className='sell-offer-row'>
          <span>Linqto Bucks</span>
          <span> -{formatDecimal(linqtoBucksUsed)}</span>
        </div>
        }
        {promotionalCredit > 0 && <div className='sell-offer-row'>
          <span>Promotional Credit</span>
          <span> -{formatDecimal(promotionalCredit)}</span>
        </div>
        }
        <div className='sell-offer-row b_18_semibold'>
          <span>Total Amount Due</span>
          <span>{formatDecimal(total)}</span>
        </div>
      </div>
    </div>
  )
}

export default Payment
