import types from 'types/errorTypes'
import moment from 'moment'

const ERROR_LINKS = {
  verificationCode: 'https://support.uphold.com/hc/en-us/articles/360040851151',
  insufficientFunds: 'https://support.uphold.com/hc/en-us/articles/360048300352-Why-am-I-getting-an-insufficient-funds-message-if-I-have-funds-#:~:text=If%20you%20believe%20you%20received,at%20cardsupport%40uphold.com',
  insufficientUnlockedFunds: 'https://support.uphold.com/hc/en-us/articles/360048300352-Why-am-I-getting-an-insufficient-funds-message-if-I-have-funds-#:~:text=If%20you%20believe%20you%20received,at%20cardsupport%40uphold.com',
  identityVerificationRequired: 'https://support.uphold.com/hc/en-us/articles/202766795-Here-s-how-to-verify-your-identity-it-s-easy',
  passwordResetRestriction: 'https://support.uphold.com/hc/en-us/articles/203399367-Security-at-Uphold',
  generalErrors: 'https://support.uphold.com/hc/en-us',
  transactionFail: 'https://support.uphold.com/hc/en-us/articles/360045908692-Why-did-my-transaction-fail-',
  ssnRequired: 'https://support.uphold.com/hc/en-us/articles/360039160072-Why-do-I-need-to-add-my-Social-Security-Number-to-my-Uphold-account-#:~:text=Information-,Why%20do%20I%20need%20to%20add%20my,Number%20to%20my%20Uphold%20account%3F&text=We%20are%20required%20to%20hold,for%20your%20annual%20tax%20filing.',
  upholdWebsite: 'https://uphold.com'
}

const parseErrorMessages = ({ error, availableAt }) => {
  let title = ''
  let message = ''
  switch (error) {
  case types.UPHOLD_OTP_REQUIRED:
    title = 'Code Required'
    message = `Please enter your verification code. <a href=${ERROR_LINKS.verificationCode} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_OTP_INCORRECT:
    title = 'Incorrect Code'
    message = `Your verification code is incorrect. Please try again. <a href=${ERROR_LINKS.verificationCode} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_UNKNOWN_ERROR:
    title = 'Uphold Error'
    message = `Uphold returned an error. Please contact Uphold support. <a href=${ERROR_LINKS.generalErrors} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_TOKEN_INVALID:
    title = 'Uphold Token Invalid'
    message = `Uphold returned an error stating that your access token is invalid. Please try to disconnect your wallet and connect again. <a href=${ERROR_LINKS.generalErrors} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_INSUFFICIENT_FUNDS:
    title = 'Insufficient Wallet Funds'
    message = `The selected wallet account does not contain sufficient funds to cover the cost of this transaction (including any fees or commissions).\n\nPlease change your Payment Method or select a smaller amount. <a href=${ERROR_LINKS.insufficientFunds} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_INSUFFICIENT_UNLOCKED_FUNDS:
    title = 'Insufficient Unlocked Funds'
    message = `The selected wallet account does not contain sufficient unlocked funds to cover the cost of this transaction (including any fees or commissions).Funds will be unlocked on ${moment(availableAt).format('MM/DD/YYYY')}. <a href=${ERROR_LINKS.insufficientUnlockedFunds} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_IDENTITY_VERIFICATION_REQUIRED:
    title = 'Uphold Verification Required'
    message = `Uphold Wallet requires you verify your identity to complete the balance transfer. <a href=${ERROR_LINKS.identityVerificationRequired} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_EMAIL_VERIFICATION_REQUIRED:
    title = 'Uphold Email Verification Required'
    message = 'Uphold requires you verify your email before you can continue.'
    break
  case types.UPHOLD_PASSWORD_RESET_RESTRICTION:
    title = 'Uphold Password Restriction'
    message = `Uphold is not allowing you to transfer funds because you recently changed your password.\n\nThis restriction ends on ${moment(availableAt).local()}. <a href=${ERROR_LINKS.passwordResetRestriction} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_DAILY_DEPOSIT_LIMIT_EXCEEDED:
    title = 'Daily Limit Exceeded'
    message = `You have exceeded your daily limit. Please try again tomorrow. <a href=${ERROR_LINKS.generalErrors} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_USER_SSN_MISSING:
    title = 'Missing SSN'
    message = `Uphold requires your SSN on your uphold profile before you can make a transfer with your account. <a href=${ERROR_LINKS.ssnRequired} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.UPHOLD_USER_STATUS_NOT_VALID:
  case types.UPHOLD_NOT_FOUND:
    title = 'Please Contact Uphold'
    message = 'There was trouble finalizing the transaction with your Uphold account. Please contact Uphold.'
    break
  case types.UPHOLD_CONNECTION_TIMEOUT:
    title = 'Connection Time Out'
    message = 'The connection to Uphold timed out. Please try again later.'
    break
  case types.PLACE_ORDER_USER_PROFILE_NOT_COMPLETE:
    title = 'Incomplete Investor Profile'
    message = 'You must complete your investor profile to place your order.'
    break
  case types.PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_SHARES:
    title = 'Insufficient Available Shares'
    message = 'We do not have enough shares for this purchase.'
    break
  case types.PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_OWNERS:
    title = 'Insufficient Available Owners'
    message = 'We do not have enough owners for this purchase.'
    break
  case types.PLACE_ORDER_PRODUCT_USER_NOT_PREFERRED:
    title = 'Something is wrong'
    message = 'This product is not allowed for a non-preferred user.'
    break
  case types.PLACE_ORDER_PRODUCT_USER_NOT_QUALIFIED_PURCHASER:
    title = 'Something is wrong'
    message = 'This product is not allowed for a non qualified purchaser user.'
    break
  case types.PLACE_ORDER_EXCEEDED_NON_ACCREDITED_WIRE_ORDERS_CAP:
    title = 'Exceeded Wire Orders Cap'
    message = 'You have reached the wire order limit as a not accredited user. Please become accredited and try again.'
    break
  case types.PLACE_ORDER_USER_WIRE_NOT_ALLOWED:
    title = 'Wire Purchase not Allowed'
    message = `Your account is not allowed to purchase with wire transfer. Linqto accepted different form of payment such as Uphold. You can set up an Uphold wallet and try again.<a href=${ERROR_LINKS.upholdWebsite} target="_blank" rel="noreferrer">Learn More</a>`
    break
  case types.PLACE_ORDER_SANCTIONED_COUNTRY:
    title = 'Order Cannot be Placed'
    message = 'We’re sorry, but your account is restricted from placing this order. Please contact our support team to learn more.'
    break
  // NOTE: need to check microblink on their error code so we show the right message
  case 'SDK_LOAD_FAILED':
    title = 'SDK failed to load.'
    message = 'It looks like your browser may not be supported or you’re using an outdated version. Please install the latest version of the Chrome, Firefox, Edge, Opera or Safari. If you are using your phone, make sure you are on the latest version of Android or iOS.'
    break
  case 'CAMERA_NOT_ALLOWED':
    title = 'Please allow camera access.'
    message = 'You can not scan until you have allowed access. Please update the camera permissions in your settings and try again.'
    break
  case 'MISSING_LICENSE_KEY':
    title = 'Internal Server Error.'
    message = 'There is no license key.'
    break
  case 'PLACE_ORDER_PARAMETERS_CHANGED':
    title = 'Please review order change'
    message = 'The company you are placing an order for is either fully subscribed or the share price has changed. Click “OK” to review the updates.'
    break
  case 'UPHOLD_MUST_PERFORM_LIVENESS_CHECK':
  case 'UPHOLD_MUST_SUBMIT_IDENTITY':
    title = 'Oops. Something Went Wrong'
    message = 'Uphold needs to verify your identity. Please log in to Uphold and verify your identity in order to add funds.'
    break
  case 'UPHOLD_RESTRICTED_BY_AUTHENTICATION_RESET':
    title = 'Oops. Something Went Wrong'
    message = `Your Uphold account is currently unavailable due to a recent change in your authentication method. ${availableAt ? `Your Uphold account will be enabled again on: ${moment(availableAt).format('MM/DD/YYYY HH:MM')} PT` : ''}`
    break
  case 'UPHOLD_USER_STATUS_NOT_VALID':
    title = 'Internal Server Error.'
    message = 'There was trouble finalizing the transaction with your Uphold account. Please contact Uphold.'
    break
  default: {
    if (error?.startsWith('UPHOLD_')) {
      title = 'Uphold Error'
      message = `Uphold returned an error. Please contact Uphold support. <a href=${ERROR_LINKS.generalErrors} target="_blank" rel="noreferrer">Learn More</a>`
    } else {
      title = 'Please Contact Us'
      message = 'An error has occurred.\n\nPlease try again later or contact our support team at invest@linqto.com.'
    }
  }
  }
  const placeOrderError = {
    type: types[error] || error,
    title,
    message
  }
  return { placeOrderError }
}

export default parseErrorMessages
