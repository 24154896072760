import { formatDecimal } from 'utils'
import { images } from 'assets'

const AddFundsModalItem = ({ account, onSelect, disabled = false, customClass = '', readOnly = false }) => {
  return (
    <div
      className={`order-modal-account-item add-funding-modal-item ${customClass} ${disabled ? 'disabled' : ''} ${readOnly ? 'read-only' : ''}`}
      onClick={() => !disabled && onSelect && onSelect(account)}
    >
      <div>
        <img alt={account.label} src={account.iconUrl} className='account-icon' />
      </div>
      <div className='b_18_semibold add-funding-modal-item-name'>{account.label}</div>
      <div className='add-funding-modal-item-right'>
        <div className='b_18_semibold add-funding-modal-item-name-sm'>{account.label}</div>
        {account.balance !== null && formatDecimal(account.balance)}
        {!account?.balance && account?.usdBalance !== null && formatDecimal(account.usdBalance)}
        {account?.description && <span className='order-modal-account-desc'>{account.description}</span>}
        {!readOnly && <img src={images['right-arrow']} alt='arrow' className='account-arrow' />}
      </div>
    </div>
  )
}

export default AddFundsModalItem
