import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/private.js'
import { Mixpanel } from 'utils'
import parseInvestDetails from 'actions/parsing/parseInvestDetails.js'

const initialState = {
  companies: [],
  retailCompanies: [],
  nonRetailCompanies: [],
  isLoggedIn: false,
  needToCompleteInvestorProfile: false,
  verticals: [],
  details: {},
  scheduleMeetingWithSalesUrl: '',
  showScheduleMeeting: false,
  loading: false,
  pageLoading: false,
  cashPaymentEnabled: false,
  wirePaymentEnabled: false,
  hasWalletDeposit: false,
  canSellShares: false,
  ownedShares: 0,
  announcements: [],
  hasSellableShares: false,
  isRetailPurchaser: false,
  userProfileIsComplete: false,
  accreditedStatus: 'NOT_VERIFIED',
  linqtoImpliedValuation: ''
}

export const getInvestPage = createAsyncThunk('getInvestPage', ({ vertical = '', query = '', sort = '', firstRender }, { dispatch, getState }) => {
  const url = `/page/invest?filterVertical=${encodeURIComponent(vertical)}&searchTerm=${query}&orderBy=${sort}`
  return api
    .get(url)
    .then((res) => {
      const dismissScheduleMeeting = Boolean(sessionStorage.getItem('dismissScheduleMeeting') || false)
      // Check initial state and current invest state, if they are equal, fire Mixpanel event (event should be fired only once)
      if (firstRender) {
        Mixpanel.registerUTM()
        Mixpanel.register({ Platform: 'Web', 'KYC Complete': res.data.isLoggedIn ? !res.data.needToCompleteInvestorProfile : false, 'Signed In': res.data.isLoggedIn })
        Mixpanel.track('View Linqto Product Invest Page', {
          'Show Schedule Meeting with Expert': (!!res?.data?.scheduleMeetingWithSalesUrl && !dismissScheduleMeeting),
          'Onboarding Complete': !!res?.data?.needToCompleteInvestorProfile,
          'Has Made Wallet Deposit': !!res?.data?.hasWalletDeposit,
          'Cash Account Enabled': !!res?.data?.cashPaymentEnabled,
          'Wire Payments Enabled': !!res?.data?.wirePaymentEnabled
        })
      }
      const verticals = res.data.verticals ? res.data.verticals : getState().invest.verticals
      const scheduleMeetingWithSalesUrl = res.data.scheduleMeetingWithSalesUrl
      return { ...res.data, verticals, scheduleMeetingWithSalesUrl, showScheduleMeeting: !!res.data.scheduleMeetingWithSalesUrl, retailCompanies: res.data.companies.filter(p => p.retailPurchaseEnabled), nonRetailCompanies: res.data.companies.filter(p => !p.retailPurchaseEnabled) }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const getInvestDetail = createAsyncThunk('getInvestDetail', ({ companyId }, { dispatch }) => {
  return api
    .get(`/page/invest/${companyId}`)
    .then((res) => {
      const dismissScheduleMeeting = Boolean(sessionStorage.getItem('dismissScheduleMeeting') || false)
      const company = res?.data?.company || {}
      const linqtoBucks = res?.data?.linqtoBucks
      Mixpanel.registerUTM()
      Mixpanel.register({
        'Available to Invest': !company?.isSoldOut,
        'Is Linqto Bucks available': !!linqtoBucks,
        'Linqto Bucks amount': linqtoBucks,
        Platform: 'Web',
        'Signed In': res?.data?.isLoggedIn,
        'Has Entity': res?.data?.entities?.length > 0
      })
      Mixpanel.track('View Product Page', {
        'Share value': company.products && company.products.length ? company.products[0]?.sharePrice : 'N/A',
        Vertical: company?.vertical,
        'Minimum investment amount': company?.sliderMinimum,
        'Company Name': company?.name,
        'Show Schedule Meeting with Expert': (!!res?.data?.scheduleMeetingWithSalesUrl && !dismissScheduleMeeting),
        'Promotion Discount amount': `${res?.data?.discountPercent * 100}%`,
        'Promotion ID': res?.data?.discountNote
      })
      return { details: parseInvestDetails(res.data), scheduleMeetingWithSalesUrl: res.data.scheduleMeetingWithSalesUrl, showScheduleMeeting: !!res.data.scheduleMeetingWithSalesUrl, canSellShares: res.data.canSellShares, ownedShares: res.data.ownedShares, isLoggedIn: res.data.isLoggedIn }
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const trackScheduleMeeting = createAsyncThunk('trackScheduleMeeting', ({ urlName, type }, { dispatch }) => {
  return api.post(`/event/page/invest/${urlName ? `${urlName}/` : ''}scheduleMeetingWithSalesPopup/${type}`)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const toggleInterest = createAsyncThunk('toggleInterest', ({ companyUrlName, isInterested }, { dispatch }) => {
  return api.post(`/page/invest/${companyUrlName}/setUserInterest`, { interested: isInterested })
    .then(res => res.data)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const investSlice = createSlice({
  name: 'invest',
  initialState,
  reducers: {
    toggleScheduleMeeting: (state) => {
      state.showScheduleMeeting = false
    },
    setLinqtoImpliedValuation: (state, { payload }) => {
      state.linqtoImpliedValuation = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvestPage.pending, (state) => {
        state.loading = true
      })
      .addCase(getInvestPage.fulfilled, (state, { payload }) => {
        return { ...state, loading: false, ...payload }
      })
      .addCase(getInvestPage.rejected, (state) => {
        state.loading = false
      })
      .addCase(getInvestDetail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getInvestDetail.fulfilled, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getInvestDetail.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(toggleInterest.fulfilled, (state) => {
        return { ...state, details: { ...state.details, isInterested: !state.details.isInterested } }
      })
  }
})

export const { toggleScheduleMeeting, setLinqtoImpliedValuation } = investSlice.actions

export default investSlice.reducer
