import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Wrapper, Content, PageLoading, ConditionalRender } from 'components'
import InvestDetailHeader from './partials/InvestDetailHeader'
import InvestDetailTabs from './partials/InvestDetailTabs'
import PlaceOrder from './partials/PlaceOrder'
import InvestDetailAbout from './partials/InvestDetailAbout'
import InvestDetailSummary from './partials/InvestDetailSummary'
import InvestDetailValuation from './partials/InvestDetailValuation'
import UnicornMosaicModal from '../UnicornDetails/partials/UnicornMosaicModal'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import ScheduleMeetingsButton from '../Global/ScheduleMeetingsButton'
import { getSliderValue, Mixpanel, MOBILE_WIDTH_SIZE, formatNumberWithLetter } from 'utils'
import { getInvestDetail, toggleScheduleMeeting, trackScheduleMeeting, setLinqtoImpliedValuation } from 'slices/investSlice'
import { useLocation } from 'react-router-dom'
import InvestDetailSellButton from './partials/InvestDetailSellButton'
import HasActiveOfferErrorModal from './partials/HasActiveOfferErrorModal'
import useWindowSize from 'hooks/useWindowSize'

const InvestDetail = ({ match }) => {
  const location = useLocation()
  const [showMosaicModal, setMosaicShowModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [sharePrice, setSharePrice] = useState(0)
  const [investAmount, setInvestAmount] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [shares, setShares] = useState(0)
  const [totalTillNextTier, setTotalTillNextTear] = useState(0)
  const [totalSavings, setTotalSavings] = useState(0)
  const [isPreferred, setIsPreferred] = useState(false)
  const [tiers, setTiers] = useState([])
  let pageTitle = ''
  const [stickyHeader, setStickyHeader] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(state => state.userSlice)
  const { details, showScheduleMeeting, scheduleMeetingWithSalesUrl, pageLoading } = useSelector(state => state.investSlice)
  const [discountedAmount, setDiscountedAmount] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)
  const { width } = useWindowSize()
  const { isSoldOut, impliedValuation, impliedValuationSharePrice } = details
  useEffect(() => {
    // Sets the pathname to local storage to handle redirect
    Cookies.set('redirectPath', location.pathname)
    const companyId = match.params.id
    fetchProductDetails(companyId)
  }, [])

  // NOTE: detect share price change and update linqto implied valuation
  useEffect(() => {
    let valuationPrice
    if (impliedValuationSharePrice && !isSoldOut && impliedValuation) {
      valuationPrice = formatNumberWithLetter(((sharePrice / impliedValuationSharePrice) * impliedValuation), true, true, 1, true)
    } else {
      valuationPrice = impliedValuation ? formatNumberWithLetter(impliedValuation, true, true, 1, true) : 'TBD'
    }
    dispatch(setLinqtoImpliedValuation(valuationPrice))
  }, [sharePrice])

  // Set Page Title on Mount
  useEffect(() => {
    if (details) {
      pageTitle = (details.pageTitle && details.pageTitle !== '') ? details.pageTitle : `${details.name} | Invest in ${details.name} with Linqto | Linqto Investing`
    }
  }, [details])

  useEffect(() => {
    const onScroll = () => {
      const tabHeight = document?.querySelector('.invest-tabs-container')?.getBoundingClientRect()
      if (tabHeight?.y <= 104) {
        setStickyHeader(true)
      } else {
        setStickyHeader(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [details])

  useEffect(() => {
    if (sessionStorage.getItem('dismissScheduleMeeting')) {
      dispatch(toggleScheduleMeeting(false))
    }
  }, [showScheduleMeeting])

  const fetchProductDetails = async (companyId) => {
    const response = await dispatch(getInvestDetail({ companyId }))
    if (response && response.payload?.details?.promoFlag === 'COMING_SOON' && response.payload?.details?.sharePrice !== '') {
      setSharePrice(response.payload.details.sharePrice)
    }
    if (response && response.company) {
      if (response.company.offers) {
        const sliderVal = getSliderValue(response.company, 0)
        setShares(sliderVal.shares)
        setInvestAmount(sliderVal.amount)
        setSharePrice(sliderVal.sharePrice)
        setTotalTillNextTear(sliderVal.totalTillNextTier)
        setTotalSavings(sliderVal.totalSavings)
        setIsPreferred(sliderVal.isPreferred)
        setTiers(sliderVal.tiers)
      }
    }
  }

  const dismissScheduleMeeting = () => {
    Mixpanel.track('Dismiss Meet with Expert')
    dispatch(trackScheduleMeeting({ urlName: match.params.id, type: 'dismiss' }))
    dispatch(toggleScheduleMeeting(false))
    sessionStorage.setItem('dismissScheduleMeeting', true)
  }

  const scheduleMeeting = () => {
    Mixpanel.track('Click Meet With Expert')
    dispatch(trackScheduleMeeting({ urlName: match.params.id, type: 'click' }))
    window.open(scheduleMeetingWithSalesUrl)
  }

  if (pageLoading) {
    return <PageLoading />
  }
  return (
    <>
      <SeoMeta title={pageTitle} description={details.pageMetaDescription} keywords={details.pageMetaKeywords} />
      <Wrapper className='invest-detail-container'>
        <div className='page-container invest-header-container'>
          <div className='inner-container'>
            <Content className='order-group invest-details-info'>
              <InvestDetailHeader />
              <PlaceOrder
                company={details}
                user={user}
                setAmount={setInvestAmount}
                userName={details.user && `${details.user.firstName} ${details.user.lastName}`}
                urlName={match.params.id}
                investAmount={investAmount}
                companyId={details.companyId}
                stickyHeader = {stickyHeader}
                setShares={setShares}
                shares={shares}
                setSharePrice={setSharePrice}
                sharePrice={sharePrice}
                totalTillNextTier={totalTillNextTier}
                setTotalTillNextTear={setTotalTillNextTear}
                totalSavings={totalSavings}
                setTotalSavings={setTotalSavings}
                isPreferred={isPreferred}
                setIsPreferred={setIsPreferred}
                tiers={tiers}
                setTiers={setTiers}
                setShowErrorModal={setShowErrorModal}
                discountPercent={discountPercent}
                setDiscountPercent={setDiscountPercent}
                discountedAmount={discountedAmount}
                setDiscountedAmount={setDiscountedAmount}
              />
            </Content>
            <InvestDetailSellButton />
            {width <= MOBILE_WIDTH_SIZE && <div className='invest-details-info__bottom-info'>
              <div><span className='b_16_semibold gray3'>Founded:</span> <span className='b_16_regular gray3'>{details?.founded}</span></div>
              <div><span className='b_16_semibold gray3'>Headquarters:</span> <span className='b_16_regular gray3'>{details?.headquarters?.city}, {details?.headquarters?.state}</span></div>
              <a target='_blank' rel='noopener noreferrer' href={`https://${details?.website}`} className='b_16_semibold gray3'>{details?.website}</a>
            </div>}
          </div>
        </div>
        <div className={`page-container invest-tabs-container ${details?.isSoldOut ? 'fully-subscribed-tabs-container' : ''} ${stickyHeader ? 'sticky-padding' : ''}`}>
          <div className='inner-container'>
            <InvestDetailTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} stickyHeader={stickyHeader} setStickyHeader={setStickyHeader}/>
            <InvestDetailAbout />
            <InvestDetailSummary setMosaicShowModal={setMosaicShowModal}/>
            <InvestDetailValuation sharePrice={sharePrice}/>
          </div>
        </div>
        <ConditionalRender isVisible={showMosaicModal}>
          <UnicornMosaicModal setShowModal={setMosaicShowModal} />
        </ConditionalRender>
        {showScheduleMeeting &&
        <ScheduleMeetingsButton scheduleMeeting={scheduleMeeting} dismissScheduleMeeting={dismissScheduleMeeting} text='Meet with Expert'/>}
        {showErrorModal && <HasActiveOfferErrorModal hideModal={() => setShowErrorModal(false)}/>}
      </Wrapper>
    </>
  )
}

export default InvestDetail
