import Modal from './Modal'
import Button from './Button'

const SuccessModal = ({ message, hideModal }) => {
  return (
    <>
      <Modal
        mode='primary'
        size='md'
        crossToClose
        modalHeader=''
        innerStyle='success-modal'
        hideModal={hideModal}
      >
        <div className='b_18_regular'>{message}</div>
        <div className='btn-group centered'>
          <Button onClick={hideModal}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default SuccessModal
