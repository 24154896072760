import { useEffect } from 'react'
import { Modal } from 'components'
import { images } from 'assets'
import Button from '../../Global/Button'
import TextInput from 'components/Global/Inputs/TextInput'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { schema } from 'schemas/requestFinancialadvisorSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { requestFinancialAdvisor } from 'slices/investorStatusSlice'

const RequestModal = ({ hideModal }) => {
  const dispatch = useDispatch()
  const { requestStatus } = useSelector((state) => state.investorStatusSlice)
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })

  useEffect(() => {
    document.addEventListener('keydown', _handleKeyDown)
    return () => document.removeEventListener('keydown', _handleKeyDown)
  }, [])

  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful }
  } = methods

  const _handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)
    }
  }

  const onSubmit = async (formValues) => {
    await dispatch(requestFinancialAdvisor({ ...formValues })).then(
      ({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          setTimeout(() => hideModal(), 1000)
        }
      }
    )
  }

  return (
    <Modal
      mode='primary'
      size='sm'
      innerStyle='request-modal'
      hideModal={hideModal}
      modalHeader='Request Financial Professional'
      crossToClose
    >
      <div className='b_18_regular'>
        {requestStatus === 'success' ? (
          <div className='success-msg'>
            <img
              alt='email-success'
              src={images.success}
              className='success-icon'
              style={{ height: '30px' }}
            ></img>
            <p className='msg'>Email successfully sent.</p>
          </div>
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                name='name'
                label='Name'
                disabled={requestStatus === 'loading'}
                ariaLabel='name-input'
              />
              <TextInput
                name='email'
                label='Email'
                disabled={requestStatus === 'loading'}
                ariaLabel='email-input'
              />
              <Button
                type='submit'
                customClass='request-btn'
                loading={requestStatus === 'loading'}
                disabled={
                  requestStatus === 'loading' || !isDirty || isSubmitSuccessful
                }
              >
                Request
              </Button>
            </form>
          </FormProvider>
        )}
      </div>
    </Modal>
  )
}
export default RequestModal
