import React from 'react'
import { formatDecimal } from 'utils'
import { images } from 'assets'
import Pill from 'components/Global/Pill'
import { useHistory } from 'react-router-dom'

const InvestmentItem = ({ type, company: { iconUrl, companyName, purchased, shares, tag, status, urlName } }) => {
  const history = useHistory()
  if (type === 'history') {
    return (
      <div className='current-investment-item' onClick={() => history.push(`/portfolio/${urlName}`)}>
        <div className='current-investment-item_left'>
          <img src={iconUrl} />
          <span className='b_18_regular'>{companyName}</span>
        </div>
        <div className='current-investment-item_right'>
          <span className='current-investment-item_right status'>{status === 'OPEN' ? 'Sold' : status?.toLowerCase()}</span>
          <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} />
        </div>
      </div>
    )
  }

  return (
    <div className='current-investment-item' onClick={() => history.push(`/portfolio/${urlName}`)}>
      {tag === 'ORDER_PENDING' && <Pill className='b_14_regular flag pending' status='Pending' />}
      {tag === 'ACTIVE_OFFER' && <Pill className='b_14_regular flag active' status='Active' />}
      <div className='current-investment-item_left'>
        <img src={iconUrl} />
        <span className='b_18_regular'>{companyName}</span>
      </div>
      <div className='current-investment-item_right'>
        <div className='current-investment-item_right shares-total-price-container'>
          <span className='current-investment-item_right shares'>{formatDecimal(shares, false, 0)} shares</span>
          <span className='b_18_semibold current-investment-item_right total-price'>{formatDecimal(purchased)}</span>
        </div>
        <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} />
      </div>
    </div>
  )
}

export default InvestmentItem
