import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/addFundsSummarySchema'
import { yupResolver } from '@hookform/resolvers/yup'
import qs from 'qs'
import { seoTitleTemplate, Mixpanel, Braze } from 'utils'
import { PageLoading } from 'components'
import { getAddFundsSummaryPage, getAddFundsACHSummaryPage, commitAddFunds } from 'slices/walletSlice'
import SummaryDetails from './partials/SummaryDetails'
import SummaryForm from './partials/SummaryForm'
import SeoMeta from 'components/Global/SeoMeta'
import Breadcrumb from 'components/Global/Breadcrumb'
import TransferErrorModal from './partials/TransferErrorModal'
import SummaryDetailsPlaid from './partials/SummaryDetailsPlaid'
import parseErrorMessages from 'actions/parsing/parseErrorMessages'

const FBOAddFundsSummary = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [upholdAPIError, setUpholdAPIError] = useState('')

  const query = location.search
  const { amount, cardCurrency, cardLabel, type, entityId } = qs.parse(query, {
    ignoreQueryPrefix: true
  })

  const items = [
    { name: 'My Portfolio', route: '/portfolio' },
    { name: 'Cash Account', route: `/cash-account${entityId ? `?entityId=${entityId}` : ''}` }
  ]

  const { pageLoading, otpRequired, transactionId, msToLive } = useSelector(
    (state) => state.walletSlice
  )
  const [showTansferErrorModal, setShowTansferErrorModal] = useState(false)
  const [showOtpRequired, setShowOtpRequired] = useState(false)

  const cardId = location.pathname.split('/cash-account/add-funds-summary/')[1]

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    context: {
      otpRequired: type === 'Uphold' && (otpRequired || showOtpRequired)
    }
  })

  const refreshGetAddFundsSummary = () => {
    if (type === 'Bank Account') {
      dispatch(
        getAddFundsACHSummaryPage({ amount, cashExternalAccountId: cardId })
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('View Add Funds Summary Page')
        } else {
          Mixpanel.track('Funding Error', { 'Error Type': payload })
          Braze.track('Funding Error', { 'Error Type': payload })
        }
      })
    } else if (type === 'Uphold') {
      dispatch(
        getAddFundsSummaryPage({ amount, cardCurrency, cardId, cardLabel })
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('View Add Funds Summary Page')
          if (payload.insufficientFunds) {
            Mixpanel.track('Funding Error', { 'Error Type': 'UPHOLD_INSUFFICIENT_FUNDS' })
            Braze.track('Funding Error', { 'Error Type': 'UPHOLD_INSUFFICIENT_FUNDS' })
            history.push({
              pathname: '/cash-account/add-funds',
              state: { amount, cardId, error: 'UPHOLD_INSUFFICIENT_FUNDS' }
            })
          }
        } else {
          if (payload?.mixpanel) {
            setUpholdAPIError(payload?.error)
          } else {
            Mixpanel.track('Funding Error', { 'Error Type': payload })
            Braze.track('Funding Error', { 'Error Type': payload })
          }
          handleCommitError(payload)
        }
      })
    }
  }

  useEffect(() => {
    refreshGetAddFundsSummary()
  }, [])

  useEffect(() => {
    let timerId
    if (msToLive) {
      timerId = setTimeout(() => {
        refreshGetAddFundsSummary()
      }, msToLive)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [transactionId])

  const handleCommitError = (AddFundsError) => {
    switch (AddFundsError) {
    case 'UPHOLD_OTP_REQUIRED':
      setShowOtpRequired(true)
      methods.setError('upholdOTP', {
        type: 'UPHOLD_OTP_REQUIRED',
        message:
            'Please enter the six-eight digit one-time passcode from your Authenticator App for your Uphold account and try again.'
      })
      break
    case 'UPHOLD_OTP_INCORRECT':
      setShowOtpRequired(true)
      methods.setError('upholdOTP', {
        type: 'UPHOLD_OTP_REQUIRED',
        message:
            'Incorrect code. Please enter the six-eight digit one-time passcode from your Authenticator App for your Uphold account and try again.'
      })
      break
    case 'UPHOLD_INSUFFICIENT_FUNDS':
      history.push({
        pathname: '/cash-account/add-funds',
        state: { amount, cardId, error: 'UPHOLD_INSUFFICIENT_FUNDS' }
      })
      break
    default:
      setShowTansferErrorModal(true)
    }
  }

  const cancelAddFunds = () => {
    Mixpanel.track('Click Back on Add Funds Summary Page')
    history.push(`/cash-account/add-funds${entityId ? `?entityId=${entityId}` : ''}`)
  }

  const onSubmit = async (formValues) => {
    Mixpanel.track('Click Confirm on Add Funds Summary Page')
    const commitRequest = {}
    if (type) {
      if (type === 'Uphold') {
        commitRequest.transactionId = transactionId
        if (formValues?.upholdOTP) {
          commitRequest.otpCode = formValues.upholdOTP
        }
      } else if (type === 'Bank Account') {
        commitRequest.amount = amount
        commitRequest.cashExternalAccountId = cardId
      }
      dispatch(commitAddFunds({ commitRequest, type })).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('Funding Successful', { Currency: cardCurrency, Amount: amount, 'Funding Method': type })
          localStorage.setItem('add-funds', `${type} - ${payload.balanceType}`)
          history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
        } else {
          Mixpanel.track('Funding Error', { 'Error Type': payload })
          Braze.track('Funding Error', { 'Error Type': payload })
          handleCommitError(payload)
          setUpholdAPIError(payload)
        }
      })
    }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Add Funds Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Add Funds Summary')} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          <Breadcrumb items={items} />
          <h1>Add Funds Summary</h1>
          <div className='add-funds-container'>
            {type === 'Uphold' && <SummaryDetails />}
            {type === 'Bank Account' && <SummaryDetailsPlaid />}
            <FormProvider {...methods}>
              <SummaryForm
                onSubmit={onSubmit}
                cancelAddFunds={cancelAddFunds}
                showOtpRequired={showOtpRequired}
                type={type}
              />
            </FormProvider>
          </div>
        </div>
        {showTansferErrorModal && (
          <TransferErrorModal
            hideModal={() => setShowTansferErrorModal(false)}
            message={parseErrorMessages({ error: upholdAPIError })?.placeOrderError?.message || ''}
            handleClick={() => {
              setShowTansferErrorModal(false)
              history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
            }}
          />
        )}
      </div>
    </>
  )
}
export default FBOAddFundsSummary
