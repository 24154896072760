import { useSelector } from 'react-redux'
import { images } from 'assets'
import Button from 'components/Global/Button'
import { formatDecimal } from 'utils'
import ClientStatus from './ClientStatus'

const ClientList = ({ viewAs }) => {
  const { allowedImpersonations } = useSelector((state) => state.ria)

  return (
    <div className='ria-client-list'>
      <div className='ria-client-list-row ria-client-list-header'>
        <div className='col-name'>Client</div>
        <div className='col-status'></div>
        <div className='col-investment'>Investment</div>
        <div className='col-actions'></div>
      </div>

      {allowedImpersonations.length === 0 && (
        <div className='box-item'>
          You don&apos;t have any linked clients yet.
        </div>
      )}
      {allowedImpersonations.map((user, i) => (
        <div className='ria-client-list-row' key={i}>
          <div className='col-name'>
            {user.firstName} {user.lastName}{' '}
            {user.isCurrentIdentity && (
              <div className='verified'>
                <img
                  width={25}
                  alt={user.firstName}
                  src={images.checkmark}
                />
              </div>
            )}
          </div>
          <div className='col-status'>
            <ClientStatus
              status={user.accreditationStatus}
              userId={user.userId}
              hasActiveOffer={user.hasActiveOffer}
              hasPendingOrder={user.hasPendingOrder}
            />
          </div>
          <div className='col-investment'>
            {formatDecimal(user.totalInvestment)}
          </div>
          <div className='col-actions'>
            <Button
              size='sm'
              onClick={() => viewAs(user.userId)}
            >
              View As
              <img
                alt='View as'
                src={images.arrow_white_right}
                className='view-icon'
              />
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ClientList
