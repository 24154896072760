import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatDecimal } from 'utils'
import SellOrderItem from '../../SellOrder/SellOrderItem'
import moment from 'moment'

const CompletedSales = () => {
  const { remainingShares, remainingProceedes, completedOrders, deactivatedAt, offerStatus } = useSelector(state => state.sellOffer)
  const history = useHistory()

  const pushToSellOrder = (id) => {
    history.push(`/sell-order/${id}`)
  }

  let box = null

  if (remainingProceedes > 0) {
    box = (
      <div className='sell-offer-summary-container completed-sales'>
        <div className='sell-offer-summary-container__row'>
          <span className='sell-offer-summary-container__row__copy'>Shares remaining</span>
          <span className='sell-offer-summary-container__row__copy'>{formatDecimal(remainingShares, false, false)}</span>
        </div>
        <div className='sell-offer-summary-container__row'>
          <span className='sell-offer-summary-container__row__copy'>Remaining proceeds</span>
          <span className='sell-offer-summary-container__row__copy'>{formatDecimal(remainingProceedes)}</span>
        </div>
        {offerStatus === 'Closed' && deactivatedAt && <div className='sell-offer-summary-container__row'>
          <span className='sell-offer-summary-container__row__copy'>Cancelled Date</span>
          <span className='sell-offer-summary-container__row__copy'>{moment(deactivatedAt).format('MM/DD/YYYY')}</span>
        </div>}
      </div>
    )
  }

  return (
    <>
      <h1 className='b_18_semibold sell-offer-summary-container__completed-sales-header'>Completed Sales</h1>
      {completedOrders && completedOrders.length ? completedOrders.map(order => (
        <SellOrderItem
          key={order.sellOrderId}
          order={order}
          handleClick={() => pushToSellOrder(order?.sellOrderId)}
        />
      )) : null}
      {box}
    </>
  )
}

export default CompletedSales
