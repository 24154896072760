import { useSelector } from 'react-redux'
import ProfileNextBtn from './ProfileNextButton'
import ProfileSubmitBtn from './ProfileSubmitBtn'

const ProfileFormSubmit = ({
  redirect,
  onSubmit,
  setDirectToVerifyStatus,
  isAccreditedRedirect
}) => {
  const { isAccredited } = useSelector(state => state.profile)
  return (
    <div className='box-content'>
      {isAccredited
        ? <ProfileSubmitBtn
          redirect={redirect}
          isAccreditedRedirect={isAccreditedRedirect}
          setDirectToVerifyStatus={setDirectToVerifyStatus}
          onSubmit={onSubmit}
        />
        : <ProfileNextBtn
          onSubmit={onSubmit}
        />
      }
    </div>
  )
}

export default ProfileFormSubmit
