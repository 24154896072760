import SellOfferPrice from './SellOfferPrice'
import SellOfferShares from './SellOfferShares'
import SellOfferCTA from './SellOfferCTA'
import SellOfferTotals from './SellOfferTotals'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ErrorModal from 'components/Global/ErrorModal'

const SellOfferDetail = ({
  subTotal,
  transactionFees,
  totalProceeds,
  costBasisOfShares,
  averageCost,
  calculateTotals,
  projectedGain,
  calculateMinimumShareAmount,
  min,
  sliderIndex,
  setSliderIndex,
  disableSharesToSell,
  setDisableSharesToSell,
  disableSharesToSellInput
}) => {
  const { companyUrlName, shareOwningAccounts } = useSelector((state) => state.sellOffer)
  const history = useHistory()
  const { handleSubmit, watch } = useFormContext()

  const onSubmit = (formValues) => {
    history.push(
      `/sell-order-confirmation/?companyUrlName=${companyUrlName}&amount=${formValues.marketLastSoldPrice}&shares=${formValues.sharesForAccount}${formValues.shareOwningAccount.entityId ? `&entityId=${formValues.shareOwningAccount.entityId}` : ''}`
    )
  }

  const shareOwningAccount = watch('shareOwningAccount')

  const disabled = (shareOwningAccounts && shareOwningAccounts.length > 1 && !shareOwningAccount) || !!shareOwningAccount?.activeOfferId

  return (
    <>
      {shareOwningAccount?.activeOfferId > 0 && shareOwningAccounts?.length === 1 ? <ErrorModal
        centered
        crossToClose
        error={{
          type: 'error',
          title: 'Active Offer Already in Progress',
          message: `It looks like you already have an <a class="link primary" href=${`/offer/${shareOwningAccount?.activeOfferId}`}>active offer</a> for this investment. You may only have one active sell offer per investment at a time.`
        }} hideModal={() => history.goBack()} /> : null}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <SellOfferPrice calculateTotals={calculateTotals} subTotal={subTotal} setDisableSharesToSell={setDisableSharesToSell} calculateMinimumShareAmount={calculateMinimumShareAmount} setSliderIndex={setSliderIndex} disableSharesToSellInput={disableSharesToSellInput} />
        <SellOfferShares setSliderIndex={setSliderIndex} sliderIndex={sliderIndex} calculateTotals={calculateTotals} disabled={disabled} disableSharesToSell={disableSharesToSell} subTotal={subTotal} minimumSellOfferAmount={min} setDisableSharesToSell={setDisableSharesToSell} disableSharesToSellInput={disableSharesToSellInput} calculateMinimumShareAmount={calculateMinimumShareAmount} />
        <SellOfferTotals
          subTotal={subTotal}
          transactionFees={transactionFees}
          totalProceeds={totalProceeds}
          costBasisOfShares={costBasisOfShares}
          averageCost={averageCost}
          projectedGain={projectedGain}
        />
        <SellOfferCTA onSubmit={onSubmit} disabled={disabled} />
      </form>
    </>
  )
}

export default SellOfferDetail
