import Modal from '../../Global/Modal'
import Button from '../../Global/Button'
import ReactHtmlParser from 'react-html-parser'

const TransferErrorModal = ({ hideModal, handleClick, message }) => {
  return (
    <>
      <Modal
        mode='primary'
        size='md'
        crossToClose
        modalHeader='Oops. Something Went Wrong'
        hideModal={hideModal}
        innerStyle='success-modal'
      >
        <div className='b_18_regular text-center'>
          {ReactHtmlParser(message)}
        </div>
        <div className='btn-group centered'>
          <Button onClick={handleClick}>
            {message ? 'OK' : 'Retry'}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default TransferErrorModal
