import Breadcrumb from 'components/Global/Breadcrumb'
import SeoMeta from 'components/Global/SeoMeta'
import SellOfferHeader from 'components/SellOffer/partials/SellOfferHeader'
import SellOrderCostBasisItem from 'components/SellOrder/SellOrderCostBasisItem'
import { PageLoading } from 'components'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Link } from 'react-router-dom'
import { getSellOrderDetails } from 'slices/sellOfferSlice'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SellOrderGains from './SellOrderGains'
import SellOrderItem from './SellOrderItem'
import SellOrderSubHeader from './SellOrderSubHeader'
import SellOrderSummaryContainer from './SellOrderSummaryContainer'

const SellOrderDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { companyUrlName, companyName, pageLoading, sellOrder } = useSelector(state => state.sellOffer)
  const { id } = useParams()
  const items = [{ name: 'My Portfolio', route: '/portfolio' }, { name: companyName, route: `/portfolio/${companyUrlName}` }]

  useEffect(() => {
    dispatch(getSellOrderDetails(id)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Sell Order Details Page (ATS)', { 'Company Name': payload?.company?.name })
      }
    })
  }, [id])

  const linkToOrder = (id) => history.push(`/order/${id}`)

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Sell Order Details')} />
        <PageLoading />
      </>
    )
  }

  const { breakdown, offer } = sellOrder || {}
  const { buyOrders } = breakdown || []

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Offer Confirmed')} />
      <div className='page-container sell-offer-container'>
        <div className='inner-container'>
          <Breadcrumb items={items} />
          <SellOfferHeader title='Sell Order' />
          <div className='sell-offer-page-container'>
            <SellOrderSummaryContainer />
            <div className='sell-offer-summary-container__bottom-container top'>
              <SellOrderSubHeader title='Cost Basis and Gains' />
              {buyOrders && buyOrders.length ? buyOrders.map(transaction => (
                <SellOrderCostBasisItem key={transaction.orderId} transaction={transaction} handleClick={() => linkToOrder(transaction.orderId)} />
              )) : null}
              <SellOrderGains breakdown={breakdown} />
            </div>
            <div className='sell-offer-summary-container__bottom-container'>
              <SellOrderSubHeader title='Sell Offer Details' />
              <span className='sell-offer-summary-container__row__cost-basis share-copy'>
                Your sell offer may be executed over multiple sell orders if an investor does not buy the full amount. View your complete sell offer details below. All sales are final.
              </span>
              {offer ? <SellOrderItem order={offer} handleClick={() => history.push(`/offer/${offer?.sellOfferId}`)} /> : null}
            </div>
            <div className='b_18_regular space-above-sm'>
            Have a question about your order?{' '}
              <Link onClick={() => Mixpanel.track('Click Contact Us', { Location: 'Sell Order Details' })}
                to={`/contact?topic=Order Support&message=Re: Sell Order ID ${id}`}
                className='link primary contact-us'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellOrderDetail
