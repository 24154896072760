import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { status, selfAccreditationOptions } from '../data'
import { PageLoading, ConditionalRender } from 'components'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import InvestorStatusHeader from './partials/InvestorStatusHeader'
import AccreditedUserSection from './partials/AccreditedUserSection'
import NotAccreditedUserCopy from './partials/NotAccreditedUserCopy'
import SelfAccreditationSection from './partials/SelfAccreditationSection'
import FinancialProfessionalSection from './partials/FinancialProfessionalSection'
import AccreditationTerms from './partials/AccreditationTerms'
import EUResidentsSection from './partials/EUResidentsSection'
import UKResidentsSection from './partials/UKResidentsSection'
import NoRegCountriesSection from './partials/NoRegCountriesSection'
import USCRDNumberSection from './partials/USCRDNumberSection'
import USNetWorthSection from './partials/USNetWorthSection'
import USIndividualIncomeSection from './partials/USIndividualIncomeSection'
import USJointIncomeSection from './partials/USJointIncomeSection'
import OtherRegCountriesSection from './partials/OtherRegCountriesSection'
import CRDSubmitSuccessModal from './partials/CRDSubmitSuccessModal'
import ContactSupport from 'components/Global/ContactSupport'
import { schema } from 'schemas/crdNumberSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getInvestorStatus,
  saveSelfAccreditation,
  saveSecurityLicense
} from 'slices/investorStatusSlice'
import ButtonGroup from './ButtonGroup'
import NotAccreditedSubmitSuccessModal from './partials/NotAccreditedSubmitSuccessModal'

const InvestorStatus = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    selfAccreditation,
    accreditedCountry,
    accreditedStatus,
    pageLoading,
    hasRequestedFinancialAdvisor,
    isSignedIn,
    hasKyc,
    isUserProfileComplete,
    loading,
    accreditedCountrySource,
    accreditationRegion,
    initialValues
  } = useSelector((state) => state.investorStatusSlice)
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })
  const [showModal, setShowModal] = useState(false)
  const [showAccreditationTypesModal, setShowAccreditationTypesModal] =
    useState(false)
  const [selfAccreditationState, setSelfAccreditationState] = useState('UNKNOWN')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [didSubmitAccStatus, setDidSubmitAccStatus] = useState(true)
  const [submitAccSuccessModal, setSubmitAccStatusModal] = useState(false)

  useEffect(() => {
    getInvestorStatusPage()
  }, [])

  const getInvestorStatusPage = () => {
    dispatch(getInvestorStatus()).then((res) => {
      if (!res?.payload?.hasKyc && res?.payload?.isSignedIn) {
        history.push('/profile')
      } else {
        Mixpanel.track('Onboarding View Investor Status', { 'Accredited Country': res?.payload?.accreditedCountry })
      }
      /* If loaded in react native webview, post message
         so webview can remove required elements from DOM */
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('pageLoaded')
      }
    })
  }

  useEffect(() => {
    methods.reset(initialValues)
  }, [initialValues])

  useEffect(() => {
    setSelfAccreditationState(selfAccreditation)
  }, [selfAccreditation])

  useEffect(() => {
    /* For the react native webview, since the header has already
      rendered, this needs to be removed here and not in React Naive code */
    if (window.ReactNativeWebView) {
      document.getElementById('header-div').style.display = 'none'
    }
  }, [])

  const handleRequest = (e) => {
    e.preventDefault()
    setShowModal(true)
    if (hasRequestedFinancialAdvisor) {
      Mixpanel.track('Onboarding Click Resend Financial Professional')
    } else {
      Mixpanel.track('Onboarding Click Email Financial Professional')
    }
  }

  const onSubmit = async (formValues) => {
    const crdNumber = formValues.crdNumber
    if (crdNumber || crdNumber.length > 0) {
      dispatch(saveSecurityLicense({ crdNumber })).then(({ meta }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('Onboarding Click Submit Verification', {
            'Accreditation Method': 'Manual',
            'Accredited Country': accreditedCountry,
            'Accreditation Status': selfAccreditationOptions[selfAccreditationState]
          })
          getInvestorStatusPage()
          setSubmitSuccess(true)
        }
      })
    }
  }

  const handleSaveSelfAccreditation = (val) => {
    setSelfAccreditationState(val)
    setDidSubmitAccStatus(false)
    dispatch(
      saveSelfAccreditation({
        selfAccreditation: val
      })
    )
  }

  const isSelfAccredited = () => {
    return (
      selfAccreditation !== 'FOREIGN_INVESTOR' &&
      accreditedCountry === 'United States'
    )
  }

  const handleNonAccreditedSubmit = async () => {
    await dispatch(getInvestorStatus())
    setSubmitAccStatusModal(true)
    setDidSubmitAccStatus(true)
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto',
      'Accredited Country': accreditedCountry,
      'Accreditation Status': selfAccreditationOptions[selfAccreditationState]
    })
  }

  const handleSubmitAccSuccessModal = () => {
    setSubmitAccStatusModal(false)
    history.push('/cash-account')
  }

  const isAccreditedUser = () => accreditedStatus === 'ACCREDITED'

  let accRegionComponent = null

  if (accreditationRegion === 'EU_SELF') {
    accRegionComponent = <EUResidentsSection />
  } else if (accreditationRegion === 'UK_SELF') {
    accRegionComponent = <UKResidentsSection />
  } else if (accreditationRegion === 'OTHER_NOT_REGULATED') {
    accRegionComponent = <NoRegCountriesSection />
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
        <PageLoading />
      </>
    )
  }

  const showButtonGroup = accreditationRegion !== 'EU_SELF' && selfAccreditationState === 'NOT_ACCREDITED' && accreditationRegion !== 'UK_SELF'

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
      <div className='page-container investor-status-container'>
        <div className='inner-container'>
          <ConditionalRender isVisible={isSignedIn}>
            <InvestorStatusHeader
              isUserProfileComplete={isUserProfileComplete}
              hasKyc={hasKyc}
              isAccredited={isAccreditedUser}
              accreditedStatus={accreditedStatus}
            />
            <ConditionalRender isVisible={isAccreditedUser()}>
              <AccreditedUserSection />
            </ConditionalRender>
            <ConditionalRender isVisible={!isAccreditedUser()}>
              <NotAccreditedUserCopy
                setShowAccreditationTypesModal={setShowAccreditationTypesModal}
                showAccreditationTypesModal={showAccreditationTypesModal}
                status={status}
                accreditationRegion={accreditationRegion}
              />
              <ConditionalRender isVisible={accreditationRegion === 'NONE'}>
                <OtherRegCountriesSection />
              </ConditionalRender>
              <ConditionalRender isVisible={isSelfAccredited()}>
                <div className='self-acc-container'>
                  <SelfAccreditationSection
                    selfAccreditationState={selfAccreditationState}
                    handleSaveSelfAccreditation={handleSaveSelfAccreditation}
                    loading={loading}
                    accreditedStatus={accreditedStatus}
                    selfAccreditationOptions={selfAccreditationOptions}
                  />
                  <ConditionalRender
                    isVisible={selfAccreditationState === 'SECURITIES_LICENSE'}
                    className='b_18_regular info-container'
                  >
                    <FormProvider {...methods}>
                      <USCRDNumberSection
                        accreditedStatus={accreditedStatus}
                        loading={loading}
                        onSubmit={onSubmit}
                      />
                    </FormProvider>
                  </ConditionalRender>
                </div>
                <ConditionalRender isVisible={selfAccreditationState === 'NET_WORTH'}>
                  <USNetWorthSection />
                </ConditionalRender>
                <ConditionalRender isVisible={selfAccreditationState === 'ANNUAL_INCOME'}>
                  <USIndividualIncomeSection />
                </ConditionalRender>
                <ConditionalRender isVisible={selfAccreditationState === 'JOINT_INCOME'}>
                  <USJointIncomeSection />
                </ConditionalRender>
              </ConditionalRender>
              {accRegionComponent}
              <ConditionalRender isVisible={selfAccreditationState === 'NOT_ACCREDITED' && accreditedCountry === 'United States'}>
                <div className='b_18_regular accreditation-terms margin'>
                  In order to comply with regulations, some or all investment opportunities may not be available to non-accredited investors.  If you meet the qualifications in the future, you can update your accreditation status in your investor profile.
                </div>
              </ConditionalRender>
              {showButtonGroup && <div className='b_18_regular accreditation-terms'>
                <ButtonGroup disabled={didSubmitAccStatus} loading={false} onClick={handleNonAccreditedSubmit} />
              </div>}
              <ConditionalRender
                isVisible={(isSelfAccredited() || accreditationRegion === 'NONE') && (selfAccreditationState !== 'NOT_ACCREDITED')}
              >
                <FinancialProfessionalSection
                  handleRequest={handleRequest}
                  hasRequestedFinancialAdvisor={hasRequestedFinancialAdvisor}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  accreditationRegion={accreditationRegion}
                />
              </ConditionalRender>
              <AccreditationTerms accreditedCountry={accreditedCountry} accreditedCountrySource={accreditedCountrySource} />
            </ConditionalRender>
            <ContactSupport alignment='left' onClick={() => Mixpanel.track('Onboarding Click Contact Support')} />
          </ConditionalRender>
        </div>
      </div>
      {submitAccSuccessModal && <NotAccreditedSubmitSuccessModal hideModal={handleSubmitAccSuccessModal} />}
      {submitSuccess && <CRDSubmitSuccessModal hideModal={() => setSubmitSuccess(false)} />}
    </>
  )
}

export default InvestorStatus
