import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/profileSchema'
import { schema as financeSchema } from 'schemas/profileFinancialSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { ConditionalRender, Identity, PageLoading } from 'components'
import { seoTitleTemplate, Mixpanel, toCamelCase } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import SaveProfileModal from './partials/SaveProfileModal'
import NotificationBanner from 'components/Global/NotificationBanner'
import ProfileHeader from './partials/ProfileHeader'
import ProfileForm from './partials/ProfileForm'
import ProfileEntity from './partials/ProfileEntity'
import BrokerList from './partials/BrokerList'
import { useQuery } from 'hooks/useQuery'

import {
  getUserProfile,
  editProfile,
  resetProfile
} from 'slices/profileSlice'

import {
  getCountries,
  getRegions
} from 'slices/commonSlice'
import { allocationOptions, annualIncomeOptions, netWorthOptions } from 'components/data'
import profilePayload from './partials/profilePayload'
import FinancialForm from './partials/FinancialForm'

const tooltip = {
  taxId:
    'Required for all US citizens and residents to meet regulatory and tax reporting obligations. Only leave this blank if you have neither an SSN nor an ITIN. ',
  foreignTaxId:
    'Your Foreign Tax ID# and/or national ID# are unique identifying numbers issued by your government. If you have both, please provide your Tax ID#.  If your country does not issue tax ID numbers, please provide your national ID number. Providing your ID# helps us to confirm your identity and remain compliant with US regulations.',
  pCountry:
    'We’ll use your residence for accreditation verification if you are not a US Citizen.',
  citizenshipCountry: 'We’ll need to know this for accreditation verification.',
  entities:
    'Adding an investment entity gives you more options of how to fund your Linqto orders. Some examples of entities you can use to invest include: Trust (Family, Revocable, etc), Corporation (S-, C-, B-), Partnership,  Limited Liability Company, Solo 401k, Self-Directed IRA, Non-Profit Corporation, and Non-Profit Foundation.',
  brokerInfo:
    'We’ll need this to distribute your shares in the event of an exit. Note, we can only distribute shares titled in the name of an entity to a brokerage account that is also titled in the name of the same entity. '
}

const Profile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const [validateTrustedContact, setValidateTrustedContact] = useState(false)
  const [validateEmployment, setValidateEmployment] = useState(false)
  const [validateFinancialProfile, setValidateFinancialProfile] = useState(false)
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    context: {
      validateEmployment,
      validateFinancialProfile,
      validateTrustedContact
    }
  })
  const financialMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(financeSchema),
    context: {
      validateTrustedContact
    }
  })
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue
  } = methods

  const {
    pageLoading,
    entity,
    hasKyc,
    isAccredited,
    isUserProfileComplete,
    initialValues,
    userProfile
  } = useSelector((state) => state.profile)
  const { isImpersonating, userId } = useSelector(state => state.userSlice)

  const redirect = query.get('redirect') || false
  const finanaicalProfile = query.get('finanaicalProfile') || false
  const showFinancialPage = !!query.get('finanaicalProfile') || false
  const [bigError, setBigError] = useState(false)
  const [requiredError, setRequiredError] = useState(false)
  const [countries, setCountries] = useState([])
  const [primaryRegions, setPrimaryRegions] = useState([])
  const [mailingRegions, setMailingRegions] = useState([])
  const [banner, setBanner] = useState(false)
  const [directToVerifyStatus, setDirectToVerifyStatus] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const isAccreditedRedirect = redirect && redirect === '/investor-status'
  useEffect(() => {
    return () => {
      dispatch(resetProfile())
    }
  }, [])

  useEffect(() => {
    if (isAccreditedRedirect) history.push('/profile?finanaicalProfile=true')
  }, [isAccreditedRedirect])

  // if "save and verify" button is clicked, submit form and redirect to investor status page
  useEffect(() => {
    if (directToVerifyStatus) {
      handleSubmit(onSubmit)
    }
  }, [directToVerifyStatus])

  useEffect(() => {
    reset(initialValues)
    if (!initialValues.citizenshipCountry) {
      setValue('citizenshipCountry', initialValues.pCountry, { shouldDirty: true })
    }
    if (initialValues?.usResidendcyStatus) {
      setValue('usResidendcyStatus', initialValues?.usResidendcyStatus)
    }
    setValue('annualIncome', annualIncomeOptions[initialValues?.annualIncome])
    setValue('networthExcludingResidence', netWorthOptions[initialValues?.networthExcludingResidence])
    setValue('capitalAllocation', allocationOptions[initialValues?.capitalAllocation])
  }, [initialValues])

  useEffect(async () => {
    await dispatch(getUserProfile()).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        const { isUserProfileComplete, isAccredited } = payload || {}
        if (isUserProfileComplete && !isAccredited && !isAccreditedRedirect) {
          history.push('/investor-status')
        }
        if (payload.hasKyc) {
          Mixpanel.track('Onboarding View Basic Information')
        }
      }
    })
  }, [])

  useEffect(() => {
    if (hasKyc && sessionStorage.getItem('identityVerified')) {
      setBanner(true)
      sessionStorage.removeItem('identityVerified')
    }
  }, [hasKyc])

  useEffect(() => {
    if (!_.isEmpty(userProfile)) {
      const primary = userProfile?.addresses[0]
      const mailing = userProfile.addresses[1]
      dispatch(getCountries()).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          setCountries(payload)
          const countries = payload
          if (primary?.country) {
            // validate country before setting region
            const validCountry = countries.some(c => c === primary.country)
            if (validCountry) {
              dispatch(getRegions(primary.country)).then(({ meta, payload }) => {
                if (meta.requestStatus === 'fulfilled') {
                  setPrimaryRegions(payload)
                  // If state is not in region list, reset field
                  if (!payload.some(state => state === primary.state)) {
                    setValue('pState', '')
                  }
                }
              })
            }
          }
          if (mailing?.country) {
            // validate country before setting region
            const validCountry = countries.some(c => c === mailing.country)
            if (validCountry) {
              dispatch(getRegions(mailing.country)).then(({ meta, payload }) => {
                if (meta.requestStatus === 'fulfilled') {
                  setMailingRegions(payload)
                  // If state is not in region list, reset field
                  if (!payload.some(state => state === mailing.state)) {
                    setValue('mState', '')
                  }
                }
              })
            }
          }
        }
      })
    }
  }, [userProfile])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setRequiredError(true)
      setBigError(false)
    } else {
      setRequiredError(false)
    }
  }, [Object.keys(errors).length])

  const onSubmit = async (formValues) => {
    const { userProfile, entity, trustedContact } = profilePayload(formValues)
    return dispatch(editProfile({ userProfile, entity, trustedContact })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (redirect) {
          if (redirect === 'place-order') {
            history.goBack()
          }
          history.push(redirect)
        } else if (directToVerifyStatus && !isImpersonating) {
          history.push('/investor-status')
        } else if (directToVerifyStatus && isImpersonating) {
          history.push(`/ria/verify-client-accreditation/${userId}`)
        } else {
          setShowSaveModal(true)
        }
      } else {
        setBigError(true)
      }
    })
  }

  const onNextSubmit = async (formValues) => {
    Mixpanel.track('Click Basic Information Save Button', { 'Save Type': 'Next' })
    const { userProfile, entity } = profilePayload(formValues)
    dispatch(editProfile({ userProfile, entity })).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        history.push('/profile?finanaicalProfile=true')
      } else {
        const errMsg = payload?.data?.error.split('_').join(' ')
        Mixpanel.track('General Error', { 'Error Type': toCamelCase(errMsg) })
        setBigError(true)
      }
    })
  }

  const onFinancialFormSubmit = (formValues) => {
    // Send the residency status key instead of the value to the backend
    const residency = initialValues?.usResidendcyStatus?.replace(/-|\s/g, '_').toUpperCase() || 'NOT_REQUIRED'
    const { userProfile, entity, trustedContact } = profilePayload({ ...initialValues, ...formValues, usResidendcyStatus: residency })
    dispatch(editProfile({ userProfile, entity, trustedContact })).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Click Next on Financial Profile Page')
        history.push('/investor-status')
      } else {
        setBigError(true)
      }
    })
  }

  if (!redirect && isUserProfileComplete && !isAccredited && isImpersonating) {
    history.push(`/ria/verify-client-accreditation/${userId}`)
  }

  if (!hasKyc) {
    return <Identity />
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Profile')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Profile')} />
      {showSaveModal && <SaveProfileModal hideModal={setShowSaveModal} />}
      {banner && (
        <NotificationBanner
          text={'Identity Verified'}
          delay={500}
          stay={2500}
        />
      )}
      <div className='profile-container page-container'>
        <div className='inner-container'>
          <ProfileHeader
            email={userProfile.email}
            legalName={userProfile.legalName}
            isAccredited={isAccredited}
            isUserProfileComplete={isUserProfileComplete}
            hasKyc={hasKyc}
            showFinancialPage={showFinancialPage}
            activeStep={finanaicalProfile ? 'finraProfile' : 'basicInfo'}
          />
          {!showFinancialPage && <div className='heading_7'>Personal Information</div>}
          <ConditionalRender isVisible={!showFinancialPage}>
            <FormProvider {...methods}>
              <ProfileForm
                onSubmit={(data) => !isAccredited ? onNextSubmit(data) : onSubmit(data)}
                redirect={redirect}
                tooltip={tooltip}
                countries={countries}
                primaryRegions={primaryRegions}
                mailingRegions={mailingRegions}
                setMailingRegions={setMailingRegions}
                setPrimaryRegions={setPrimaryRegions}
                setDirectToVerifyStatus={setDirectToVerifyStatus}
                isAccredited={isAccredited}
                entity={entity}
                isAccreditedRedirect={isAccreditedRedirect}
                bigError={bigError}
                requiredError={requiredError}
                setValidateFinancialProfile={setValidateFinancialProfile}
                setValidateEmployment={setValidateEmployment}
                setValidateTrustedContact={setValidateTrustedContact}
              />
              <div className='box-group'>
                <BrokerList tooltip={tooltip.brokerInfo} />
                <ProfileEntity tooltip={tooltip.entities} />
              </div>
            </FormProvider>
          </ConditionalRender>
          <ConditionalRender isVisible={!!showFinancialPage}>
            <FormProvider {...financialMethods}>
              <FinancialForm
                onSubmit={onFinancialFormSubmit}
                countries={countries}
                setValidateTrustedContact={setValidateTrustedContact}
              />
            </FormProvider>
          </ConditionalRender>
        </div>
      </div>
    </>
  )
}

export default Profile
