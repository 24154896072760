import { useEffect, useState } from 'react'
import { images } from 'assets'
import { formatDecimal, Mixpanel, getDomain } from 'utils'
import { useSelector } from 'react-redux'
import TextInput from 'components/Global/Inputs/TextInput'
import Checkbox from 'components/Global/Inputs/Checkbox'
import { FormProvider, useForm } from 'react-hook-form'

const RewardShare = () => {
  const [copied, setCopied] = useState(0)
  const [checked, setChecked] = useState(false)
  const { referralUrl, signUpBonus, firstOrderBonus } = useSelector(state => state.rewards)
  const methods = useForm()

  const shareTitle = 'Join Linqto with my personal link 💵'
  const shareTxt =
    signUpBonus > 0
      ? `Join Linqto with my personal link and get ${formatDecimal(signUpBonus, true, 0)} Linqto Bucks at sign up. ${encodeURIComponent(referralUrl)}`
      : `Join Linqto with my personal link. ${encodeURIComponent(referralUrl)}`

  useEffect(() => {
    if (checked) {
      methods.setValue('linkInput', referralUrl)
    } else {
      methods.setValue('linkInput', 'Check the box below to get your link!')
    }
  }, [checked])

  const linkCopy = (referralUrl) => {
    if (checked) {
      navigator.clipboard.writeText(referralUrl)
      setCopied(1)
    }
  }

  const facebookShare = (referralUrl) => {
    trackShareReferralLink('Facebook')
    if (checked) {
      const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        referralUrl
      )}`
      const win = window.open(url, '_blank')
      win.focus()
    }
  }

  const twitterShare = (shareTxt) => {
    trackShareReferralLink('Twitter')
    if (checked) {
      const url = `https://twitter.com/intent/tweet?text=${shareTxt}`
      const win = window.open(url, '_blank')
      win.focus()
    }
  }

  const LinkedinShare = (referralUrl) => {
    trackShareReferralLink('LinkedIn')
    if (checked) {
      const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        referralUrl
      )}`
      const win = window.open(url, '_blank')
      win.focus()
    }
  }

  const emailShare = (shareTitle, shareTxt) => {
    trackShareReferralLink('Email')
    if (checked) {
      const url = `mailto:?subject=${shareTitle}&body=${shareTxt}`
      window.open(url, '_blank')
    }
  }

  const trackShareReferralLink = (Type) => Mixpanel.track('Share Referral Link', { Type })

  const handleCheck = () => {
    setChecked(true)
    Mixpanel.track('Copy Referral Link')
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
  }
  return (
    <>
      <div className='rewards-share-container'>
        <div className='rewards-share-inner'>
          <img
            src={images['bucks-icon']}
            alt='Join Linqto Referral Program'
            className='rewards-share-img'
          />
          <div className='rewards-share'>
            <FormProvider{...methods} >
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='title-group'>
                  <div className='title heading_7'>Share your personal referral link with your friends.</div>
                  <div className='note'>
                    {`They sign up and get ${formatDecimal(signUpBonus, true, 0)}. They complete a purchase, and you get ${formatDecimal(firstOrderBonus, true, 0)}. Simple.`}
                  </div>
                </div>
                <div className={`input-share-container ${!checked ? 'disabled' : ''}`}>
                  <div className='input-share'>
                    <div>
                      <TextInput
                        name='linkInput'
                        ariaLabel='Email'
                        readOnly
                      />
                      <span
                        data-copied={copied}
                        onAnimationEnd={() => setCopied(0)}
                        className='ghost-input'
                        onClick={() => linkCopy(referralUrl)}
                      >
                        {referralUrl}
                      </span>
                      {copied === 1 ? (
                        <>
                          <button
                            className='copy-btn desktop-hidden'
                            onClick={() => linkCopy(referralUrl)}
                          >
                            <img src={images.rewards_icon_copy} alt='Copy Link' />
                          </button>
                          <div className='b_14_regular share-text'>Link copied</div>
                        </>
                      ) : (
                        <button
                          className='copy-btn'
                          onClick={() => linkCopy(referralUrl)}
                        >
                          <img src={images.rewards_icon_copy} alt='Copy Link' />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='actions-share'>
                    <button
                      className='share-btn'
                      onClick={() => emailShare(shareTitle, shareTxt)}
                    >
                      <img src={images.rewards_icon_email} alt='Share link by email' />
                    </button>

                    <button
                      className='share-btn'
                      onClick={() => facebookShare(referralUrl)}
                    >
                      <img
                        src={images.rewards_icon_facebook}
                        alt='Share link with Facebook'
                      />
                    </button>
                    <button
                      className='share-btn'
                      onClick={() => twitterShare(shareTxt)}
                    >
                      <img
                        src={images.rewards_icon_twitter}
                        alt='Share link with Twitter'
                      />
                    </button>
                    <button
                      className='share-btn'
                      onClick={() => LinkedinShare(referralUrl)}
                    >
                      <img
                        src={images.rewards_icon_linkedin}
                        alt='Share link with Linkedin'
                      />
                    </button>
                  </div>
                </div>
                <Checkbox
                  label={<>I have read and agree to be bound by the <a className='inline-text-link' href={getDomain('/reward-terms-of-use', true)} rel='noopener noreferrer' target='_blank'>Linqto Bucks Terms and Conditions</a>.</>}
                  name='useLinqtoBucks'
                  disabled={checked}
                  onChange={handleCheck}
                />
              </form>
            </FormProvider>
          </div>
        </div>
        <div className='rewards-info'>
          Can’t share the link? Ask your friends to include your name in the &quot;Referral&quot; box during sign up.
        </div>
      </div>
      <div className='rewards-steps'>
        <div className='rewards-steps-title heading_7'> Next Steps?</div>
        Keep sharing those referrals because there is no limit to your Linqto Bucks Rewards. On your next investment, check the Linqto Bucks box to apply your rewards.
      </div>
    </>
  )
}

export default RewardShare
