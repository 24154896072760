import { useState, useEffect, useRef } from 'react'
import { images } from 'assets'

const NotificationBanner = ({
  text,
  delay,
  stay,
  fixed = false,
  crossToClose = false,
  onClick = null,
  actionLabel = '',
  className = ''
}) => {
  const mountedRef = useRef(true)
  const [bannerStlye, setBannerStlye] = useState('notification-banner-down')
  useEffect(() => {
    setTimeout(() => {
      if (mountedRef.current) {
        setBannerStlye('notification-banner-down')
      }
      setTimeout(() => {
        if (mountedRef.current && !fixed) {
          setBannerStlye('notification-banner-up')
        }
      }, stay + delay)
    }, delay)
    return () => {
      mountedRef.current = false
    }
  }, [])

  const hideBanner = () => {
    if (mountedRef.current) {
      setBannerStlye('notification-banner-up')
    }
  }

  return (
    <div
      className={`notification-banner ${className} ${bannerStlye} ${
        fixed ? `notification-banner-fixed ${className}` : ''
      }`}
      ref={mountedRef}
    >
      <div className='inner-container'>
        {text}{' '}
        {onClick && (
          <span onClick={onClick} className='b_18_regular notification-banner-link'>
            {actionLabel}
          </span>
        )}
        {crossToClose && (
          <div className='team-apollo-banner_close-icon' onClick={hideBanner}>
            <img alt='close-dark' src={images['close-icon']} />
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationBanner
