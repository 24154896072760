import { Modal, Content, Wrapper, Row } from 'components'
import Button from '../Global/Button'
import ReactHtmlParser from 'react-html-parser'

const ErrorModal = ({ twoButtons, error, hideModal, message = '', crossToClose = false, centered = false }) => {
  if (!error.type) return null
  return (
    <Modal mode='error' size='md' hideModal={hideModal} modalHeader={error.title} crossToClose={crossToClose}>
      <Wrapper className='error-modal'>
        <Content className='error'>
          <div className={`b_18_regular message ${centered ? 'text-center' : ''}`}>{ReactHtmlParser(error.message)}</div>
        </Content>
        {twoButtons
          ? <Row className='btn-group two'>
            <Button mode='tertiary' onClick={() => hideModal(false)}>No</Button>
            <Button onClick={() => hideModal(true)}>Yes</Button>
          </Row>
          : <Row className='btn-group centered'><Button onClick={hideModal}>{message !== '' ? message : 'OK'}</Button></Row>}
      </Wrapper>
    </Modal>
  )
}

export default ErrorModal
