import useWindowSize from 'hooks/useWindowSize'
import React from 'react'
import { images } from 'assets'
import { formatDate, formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import Pill from 'components/Global/Pill'
import { useHistory } from 'react-router-dom'

const HistoryItem = ({ showCompany, order: { createdAt, companyName, sharePrice, shares, tag, price, orderId, sellOrderId }, type = 'BUY' }) => {
  const history = useHistory()
  const { width } = useWindowSize()

  let rowComponent = null
  const id = orderId || sellOrderId

  const goToDetails = (type, id) => {
    if (type === 'BUY') {
      history.push(`/order/${id}`)
    } else if (type === 'PROMO') {
      history.push(`/order/${id}`)
    } else if (type === 'SELL') {
      history.push(`/sell-order/${id}`)
    } else {
      history.push(`/offer/${id}`)
    }
  }

  if (width > MOBILE_WIDTH_SIZE) {
    rowComponent = (
      <div className={`history-grid-container${showCompany ? '__row' : '__row no_company'}`} onClick={() => goToDetails(type, id)}>
        {tag === 'CANCELLED' && <Pill className='b_14_regular flag cancelled' status='CANCELLED' />}
        {tag === 'ORDER_IN_PROGRESS' && <Pill className='b_14_regular flag pending' status='PENDING' />}
        <span className='b_18_regular'>{formatDate(createdAt)}</span>
        {showCompany && <span className='b_18_semibold'>{companyName}</span>}
        <span className='b_18_regular align-center'>{type === 'OFFER' ? 'SELL' : type}</span>
        <span className='b_18_regular align-right'>{formatDecimal(shares, false, 0)}</span>
        <span className='b_18_regular align-right'>{formatDecimal(sharePrice)}</span>
        <span className='b_18_semibold align-right'>{formatDecimal(price)}</span>
        <div className='flex-end'>
          <img src={images['right-arrow']} />
        </div>
      </div>)
  } else {
    rowComponent = (
      <div className='history-grid-container__row' onClick={() => goToDetails(type, id)}>
        {tag === 'CANCELLED' && <Pill className='b_14_regular flag cancelled' status='CANCELLED' />}
        {tag === 'ORDER_IN_PROGRESS' && <Pill className='b_14_regular flag pending' status='PENDING' />}
        <div className='history-grid-container__row__flex'>
          <div className='history-grid-container__row__flex__date-info'>
            <span className='b_18_regular'>{formatDate(createdAt)}</span>
            <span>{type === 'OFFER' ? 'SELL' : type}</span>
          </div>
        </div>
        <div className='history-grid-container__row__flex'>
          <div className='history-grid-container__row__flex__company-info'>
            {showCompany && <span className='b_18_semibold'>{companyName}</span>}
            <span className='b_18_regular'>{formatDecimal(price)}</span>
          </div>
          <img src={images['right-arrow']} />
        </div>
      </div>
    )
  }

  return rowComponent
}

export default HistoryItem
