import { formatDecimal } from 'utils'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const AddFundsLink = ({ selectedAccount }) => {
  const { order: { total } } = useSelector(
    (state) => state.placeOrderSlice
  )

  const history = useHistory()
  const cashBalance = selectedAccount?.amountAvailable

  const goToAddFunds = () => {
    history.push(`/cash-account/add-funds${selectedAccount?.entityId ? `?entityId=${selectedAccount?.entityId}` : ''}`)
  }

  if (total <= cashBalance) return null
  return (
    <div className='sell-offer-section wih-margin'>
      <div className='sell-offer-order sell-offer-order-end'>
        <div className='sell-offer-row'>
          <span className='red width-70'>{formatDecimal(total - cashBalance)} more required for purchase</span>
          <span className='link primary' onClick={goToAddFunds}>Add Funds</span>
        </div>
      </div>
    </div>
  )
}

export default AddFundsLink
