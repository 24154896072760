import Button from '../../../Global/Button'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import { Mixpanel, getDevice, MOBILE_WIDTH_SIZE, getDomain } from 'utils'
import useWindowSize from 'hooks/useWindowSize'

const IdentityCTA = ({ showQrCode, setModal, modal, openPlaid, idvFeatureValue }) => {
  const history = useHistory()
  const { width } = useWindowSize()

  const isMobile = width <= MOBILE_WIDTH_SIZE

  const openMicroBlink = () => {
    isMobile ? history.push('/identity/auto-verification/1') : showQrCode()
  }

  const handleVerifyClick = () => {
    Mixpanel.register({ Device: getDevice() })
    Mixpanel.track('Click Begin ID Verification')
    if (idvFeatureValue === 'plaid') {
      openPlaid()
    } else if (idvFeatureValue === 'persona') {
      openMicroBlink()
    } else {
      openMicroBlink()
    }
  }

  return (
    <div className='page-container'>
      <div className='inner-container identity-verification'>
        <h1>Complete Your Investor Profile</h1>
        <div className='identity-verification-container'>
          <div className='body'>
            <div className='body-container'>
              <div className='copy-container'>
                <span className='b_18_semibold header'>Identity Verification</span>
                <span className='copy'>
                  We’ll use your phone’s camera to verify your identity with
                  photo ID (driver’s license or passport) and a self-photo.{' '}
                </span>
                {!isMobile && (
                  <span className='copy'>
                    When you click “Begin ID Verification” you’ll be provided a
                    QR code to scan with your phone.
                  </span>
                )}
              </div>
              <div className='identity-link-container'>
                <span
                  onClick={() => setModal(!modal)}
                  className='link primary identity-link'
                  aria-label='identity-link'
                >
                  Why am I being asked to do this?
                </span>
                <a className='link primary identity-link' href={getDomain('/privacy', true)} target='_blank' rel='noreferrer'>
                  <span>Review Privacy Policy</span>
                </a>
              </div>
            </div>
            <div className='verification-btn-container'>
              <Button
                aria-label='verification-btn'
                onClick={() => handleVerifyClick()}
              >
                <div className='verification-btn'>
                  <img
                    alt='404-not-found'
                    src={images.camera}
                    height={18}
                    width={22}
                  />
                  <span>Begin ID Verification</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IdentityCTA
