import { images } from 'assets'
import ConditionalRender from 'components/Global/ConditionalRender'
import { useState, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import PhoneNoInput from 'components/Global/Inputs/PhoneNoInput'
import TrustedContactAddressInput from './TrustedContactAddressInput'
import TrustedContactRemoveLink from './TrustedContactRemoveLink'
import { isWhiteSpace } from 'utils'
import Select from 'components/Global/Inputs/Select'
import { trustedContactRelationships } from 'components/data'
import { deleteTrustedContact } from 'slices/profileSlice'

const TrustedContact = ({ countries, setValidateTrustedContact }) => {
  const {
    profileLoading,
    deleteLoading
  } = useSelector((state) => state.profile)

  const dispatch = useDispatch()

  const TrustedContactFields = [
    'trustedContactFirstName',
    'trustedContactLastName',
    'trustedContactPhone',
    'trustedContactRelationship',
    'trustedContactStreet1',
    'trustedContactStreet2',
    'trustedContactCity',
    'trustedContactZip',
    'trustedContactCountry',
    'trustedContactState']

  const { control, register, watch, setValue, clearErrors } = useFormContext()
  const [expand, setExpand] = useState(false)
  const validate = TrustedContactFields?.some(field => !!watch(field) && (watch(field) !== '' || isWhiteSpace(field)))
  useEffect(() => {
    setValidateTrustedContact(validate)
  }, [validate])

  const handleExpand = () => {
    setExpand(!expand)
  }

  const handelRemove = () => {
    dispatch(deleteTrustedContact()).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload === 'success') {
          TrustedContactFields?.forEach((field) => {
            setValue(field, '')
            clearErrors(field)
          })
          setExpand(false)
        }
      }
    })
  }

  return (
    <div className='trusted-contact-container'>
      <div className='heading_7 expandable-title' onClick={handleExpand}>Trusted Contact (Optional)  {!expand ? <img alt='Trusted Contact' src={images['chevron-right-blue']} /> : <img alt='Trusted Contact' src={images['chevron-down-blue']} />}</div>
      <ConditionalRender isVisible={expand}>
        <div className='b_18_regular'>
        By adding a trusted contact, you permit Linqto to disclose information about your account to address possible financial exploitation, confirm the specifics of your contact information, health status, or the identity of any legal guardian, executor, trustee or power of attorney.
        </div>
        <div className='grid'>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='trustedContactFirstName'
              label='First Name'
              ariaLabel='trustedContactFirstName'
              disabled={profileLoading || deleteLoading}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='trustedContactLastName'
              label='Last Name'
              ariaLabel='trustedContactLastName'
              disabled={profileLoading || deleteLoading}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <PhoneNoInput
              label='Phone'
              name='trustedContactPhone'
              control={control}
              disabled={profileLoading || deleteLoading}
              register={register}
              ariaLabel='trustedContactPhone'
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='trustedContactRelationship'
              setValue={setValue}
              render={({ field }) => (
                <Select
                  field={field}
                  label='Relationship'
                  name='trustedContactRelationship'
                  ariaLabel='trustedContactRelationship'
                  options={trustedContactRelationships}
                  ref={null}
                  defaultOption={{ value: '', label: 'Select Relationship' }}
                  disabled={profileLoading || deleteLoading}
                  setValue={setValue}
                />
              )} />
          </div>
          <TrustedContactAddressInput
            disabled={profileLoading || deleteLoading}
            countries={countries}
          />
        </div>
        <TrustedContactRemoveLink handelRemove={handelRemove} deleteLoading={deleteLoading} />
      </ConditionalRender>
    </div>
  )
}

export default TrustedContact
