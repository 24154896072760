import Button from 'components/Global/Button'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getSellOrder } from 'slices/sellOfferSlice'
import { seoTitleTemplate, Mixpanel } from 'utils'
import { schema } from 'schemas/sellOrderSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { sellOrderErrors } from 'components/data'
import SellOrderHeader from './partials/SellOrderHeader'
import SellOrderContainer from './partials/SellOrderContainer'
import SellOrderTotals from './partials/SellOrderTotals'
import InCompleteProfileModal from 'components/FBOAccount/partials/InCompleteProfileModal'
import ErrorModal from 'components/Global/ErrorModal'

const SellOrder = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pageLoading, shareOwningAccounts, loading, subTotal, minimumTotalSellPrice } = useSelector(state => state.sellOffer)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedAvailableShares, setSelectedAvailableShares] = useState(0)
  const [showIncompleteProfileModal, setShowIncompleteProfileModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [summaryError, setSummaryError] = useState({})

  const id = match.params.id || ''

  const methods = useForm({ mode: 'all', resolver: yupResolver(schema), context: { hasEntities: shareOwningAccounts.length > 1, selectedAccount, minSell: { subTotal, minimumTotalSellPrice, selectedAvailableShares } } })
  const { handleSubmit, setError } = methods

  useEffect(() => {
    dispatch(getSellOrder(id)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Create Sell Order Page', { 'Company Name': payload.companyName })
      } else if (meta.requestStatus === 'rejected') {
        if (payload?.error === 'PROFILE_INCOMPLETE') {
          Mixpanel.track('View Incomplete Profile Modal')
          setShowIncompleteProfileModal(true)
        }
      }
    })
    methods.reset({ sharesForAccount: 0 })
  }, [])

  const onSubmit = async (formValues) => {
    if (!formValues.sharesForAccount) {
      setError('sharesForAccount', {
        type: 'required',
        message:
            'Enter valid amount'
      })
    } else {
      const entityId = selectedAccount.entityId || null
      history.push(`/sell-order/review/?companyUrlName=${id}&shareCount=${formValues.sharesForAccount}${entityId ? `&entityId=${entityId}` : ''}`)
    }
  }

  const handleBack = event => {
    event.preventDefault()
    history.goBack()
  }

  const closeIncompleteModal = () => {
    setShowIncompleteProfileModal(false)
    Mixpanel.track('Click Go to Investor Profile')
    history.push('/profile')
  }

  const renderErrorModal = () => {
    Mixpanel.track('Sell Order Error', { 'Error Type': sellOrderErrors.SHARE_PRICE_NOT_AVAILABLE.mixPanel })
    setSummaryError(sellOrderErrors.SHARE_PRICE_NOT_AVAILABLE)
    setShowErrorModal(true)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Create Sell Order')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Create Sell Order')} />
      <FormProvider {...methods}>
        <div className='page-container sell-offer-container sell-order-container'>
          <form className='inner-container' onSubmit={handleSubmit(onSubmit)}>
            <SellOrderHeader title='Create Sell Order' showHelp={true} />
            <div className='sell-offer-page-container'>
              <SellOrderContainer
                setSelectedAccount={setSelectedAccount}
                selectedAccount={selectedAccount}
                selectedAvailableShares={selectedAvailableShares}
                setSelectedAvailableShares={setSelectedAvailableShares}
                renderErrorModal={renderErrorModal}
              />
              <SellOrderTotals />
              <div className='btn-group right'>
                <Button disabled={loading} mode='tertiary' style={{ marginRight: '10px' }} onClick={handleBack}>Cancel</Button>
                <Button disabled={loading}>Sell</Button>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
      {showIncompleteProfileModal && <InCompleteProfileModal hideModal={closeIncompleteModal} type='SELL_ORDER' crossToClose={false} />}
      {showErrorModal && <ErrorModal error={summaryError} crossToClose={true} hideModal={() => setSummaryError(false)}/>}
    </>
  )
}

export default SellOrder
