import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import { images } from 'assets'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'
import { useState } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

const PromoCodeInput = ({ setPromoCode, promoCode, refreshOrder, promoCodeError, setPromoCodeError }) => {
  const addPromoFeature = useFeatureIsOn('add_promo')
  const [showPromoCodeInput, setShowPromoCodeInput] = useState(!addPromoFeature)

  const {
    order: { linqtoBucksUsed, isPromoCodeValid },
    confirmOrderLoading
  } = useSelector((state) => state.placeOrderSlice)

  const promoCodeDisabled = linqtoBucksUsed > 0

  const applyPromoCode = () => {
    if (promoCode?.length > 0) {
      if (!promoCodeDisabled) {
        refreshOrder(false, false, promoCode)
      }
    } else {
      setPromoCodeError(true)
    }
  }

  const removePromoCode = () => {
    Mixpanel.track('Remove Promo Code', { 'Promo Code': promoCode })
    refreshOrder(false, false, '')
  }

  const showPrommCodeInput = () => {
    setShowPromoCodeInput(true)
  }

  const initialInput = () => {
    return (
      <div className={`input-group promo-input-wrapper ${promoCodeDisabled || confirmOrderLoading ? 'disabled' : ''} ${promoCodeError ? 'error' : ''}`}>
        <label>Promo Code</label>
        <input
          disabled={promoCodeDisabled || confirmOrderLoading}
          type='text'
          aria-label='promoCodeInput'
          className='promo-input'
          value={promoCode}
          onChange={(e) => {
            setPromoCode(e.target.value)
            setPromoCodeError(false)
          }}
        />
        <span className='apply-btn b_18_semibold' onClick={applyPromoCode}>Apply</span>
        {(promoCodeError) && <ErrorMessage message='Promo code not found' />}
        {promoCodeDisabled && <span>To enter a promo code, please turn off Linqto Bucks.</span>}
      </div>
    )
  }

  const successInput = () => {
    return (
      <div className='input-group promo-input-wrapper promo-input-success'>
        <label>Promo Code</label>
        <input
          disabled={true}
          type='text'
          aria-label=''
          className={`promo-input ${confirmOrderLoading ? 'promo-input-disabled' : ''}`}
        />
        <span className='promo-name-wrapper b_16_semibold'>{promoCode} <img onClick={removePromoCode} src={images['promo-close']} /></span>
        <span className='promo-applied-wrapper b_18_semibold'>Applied <img src={images['promo-check']} /></span>
      </div>
    )
  }

  return (
    <div className='linqto-bucks-row'>
      {addPromoFeature && !showPromoCodeInput && <span className='b_18_semibold blue-link' onClick={showPrommCodeInput}>Add Promo Code</span>}
      {(!addPromoFeature || showPromoCodeInput) &&
        <>
          {!isPromoCodeValid && initialInput()}
          {isPromoCodeValid && successInput()}
        </>
      }
    </div>
  )
}

export default PromoCodeInput
