import Button from 'components/Global/Button'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { useQuery } from 'hooks/useQuery'
import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { confirmSellOrder, getSellOrderSummary } from 'slices/sellOfferSlice'
import { seoTitleTemplate, Mixpanel, getDomain } from 'utils'
import SellOrderHeader from '../partials/SellOrderHeader'
import SellOrderContainer from '../partials/SellOrderContainer'
import ErrorModal from 'components/Global/ErrorModal'
import { sellOrderErrors } from 'components/data'

const SellOrderSummary = () => {
  const query = useQuery()
  const methods = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const { handleSubmit } = methods
  const { sharePrice, subTotal, loading } = useSelector(state => state.sellOffer)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [summaryError, setSummaryError] = useState({})
  const [pageLoading, setPageLoading] = useState({})

  const companyUrlName = query.get('companyUrlName')
  const shareCount = query.get('shareCount')
  const entityId = query.get('entityId')

  const renderErrorModal = (error) => {
    if (error) {
      Mixpanel.track('Sell Order Error', { 'Error Type': error.mixPanel })
      setSummaryError(error)
      setShowErrorModal(true)
    }
  }

  useEffect(() => {
    getSellOrderSummaryPage()
  }, [])

  const getSellOrderSummaryPage = () => {
    setPageLoading(true)
    dispatch(getSellOrderSummary({ companyUrlName, shareCount, entityId })).then(({ meta, payload }) => {
      setPageLoading(false)
      if (meta.requestStatus === 'fulfilled') {
        if (payload && !payload.sharePrice) {
          renderErrorModal(sellOrderErrors.SHARE_PRICE_NOT_AVAILABLE)
        } else {
          Mixpanel.track('View Sell Order Review Page', { 'Company Name': payload?.companyName, Shares: shareCount, 'Share Price': sharePrice })
        }
      } else if (meta.requestStatus === 'rejected') {
        if (sellOrderErrors[payload]) {
          renderErrorModal(sellOrderErrors[payload])
        }
      }
    })
  }

  const onSubmit = async () => {
    const { meta, payload } = await dispatch(confirmSellOrder({ companyUrlName, numberOfShares: shareCount, sharePrice, totalPrice: subTotal, entityId }))
    if (meta.requestStatus === 'fulfilled') {
      history.push(`/sell-order/${payload?.sellOrderId}`)
    } else if (meta.requestStatus === 'rejected') {
      if (sellOrderErrors[payload]) {
        renderErrorModal(sellOrderErrors[payload])
      } else {
        Mixpanel.track('Sell Order Error', { 'Error Type': 'Unknown' })
      }
    }
  }

  const handleBack = event => {
    event.preventDefault()
    history.goBack()
  }

  const hideErrorModal = () => {
    setShowErrorModal(false)
    if (summaryError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED') {
      getSellOrderSummaryPage()
    }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Sell Order Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Sell Order Summary')} />
      <FormProvider {...methods}>
        <div className='page-container sell-offer-container'>
          <form className='inner-container' onSubmit={handleSubmit(onSubmit)}>
            <SellOrderHeader title='Sell Order Summary'/>
            <div className='sell-offer-page-container'>
              <SellOrderContainer titleCopy='Please review your sell offer.' type='summary' />
              <span className='customer-support-copy'>
                All sales are final.
              </span>
              <div className='btn-group right'>
                <Button mode='tertiary' style={{ marginRight: '10px' }} onClick={handleBack}>Go Back</Button>
                <Button disabled={loading} loading={loading}>Submit</Button>
              </div>
              <div className='b_18_regular space-above-sm'>
              Have a question about your order? {' '}
                Visit our <a
                  className='link primary'
                  href={getDomain('/sell-order/how-it-works/', true)}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => Mixpanel.track('Click See How It Works on Sell Order Review Page') }
                >Selling FAQS</a> or{' '}
                <Link onClick={() => Mixpanel.track('Click Contact Us', { Location: 'Sell Order Summary' })}
                  to={'/contact?topic=Order Support&message=Re: Sell Order'}
                  className='link primary contact-us'>
                  Contact Us
                </Link>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
      {showErrorModal && <ErrorModal error={summaryError} crossToClose={true} hideModal={hideErrorModal} message={summaryError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED' ? 'Review' : 'OK'} />}
    </>
  )
}

export default SellOrderSummary
