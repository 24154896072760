import { Content } from 'components'
import { images } from 'assets'
import { Mixpanel } from 'utils'

const ProductDocuments = ({ documents, type = 'order-details' }) => {
  const handleViewDocument = (name) => {
    if (type === 'order-details') {
      Mixpanel.track('View Document on Order Details Page', { 'Document Type': name })
    } else if (type === 'order-confirmation') {
      Mixpanel.track('View Document on Buy Order Review Page', { 'Document Type': name })
    }
  }

  return (
    <Content className='agreements'>
      {documents.map((d) => {
        return (
          <a
            onClick={() => handleViewDocument(d.name)}
            className='agreement box'
            key={d.name}
            href={d.url}
            target='_blank'
            rel='noopener noreferrer'
            aria-label={d.name}
          >
            <span role='agreement' className='b_18_semibold name' >{d.name}</span>
            <img alt='next-click' src={images['next-click']} />
          </a>
        )
      })}
    </Content>
  )
}

export default ProductDocuments
