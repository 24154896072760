import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const listAction = (action) => {
  return (
    <span className='b_14_regular item-action'>
      <img alt={action} src={images[`icon-list-${action}`]} />
      {action}
    </span>
  )
}

const BrokerListItem = ({ name, brokerage, exists, entityId }) => {
  const history = useHistory()
  const url = entityId
    ? `/broker-information/${entityId}`
    : '/broker-information'
  return (
    <a onClick={() => history.push(url)} className='broker-list-item'>
      <span className='b_18_regular item-type'>{name}</span>
      <span className='b_18_regular item-name'>{brokerage}</span>
      {listAction(exists ? 'edit' : 'add')}
    </a>
  )
}

export default BrokerListItem
