import Modal from '../../Global/Modal'
import Button from '../../Global/Button'

const AddBankErrorModal = ({ hideModal }) => {
  return (
    <>
      <Modal
        mode='primary'
        size='md'
        crossToClose
        modalHeader='Oops. Something went wrong.'
        hideModal={hideModal}
      >
        <div className='b_18_regular left-aligned'>
          Please review the information you provided and ensure that it matches your
          account details exactly.<br /><br />
          If you continue to experience an error, please <a href='/contact' className='link primary'>contact us</a>.
        </div>
        <div className='btn-group centered'>
          <Button
            onClick={hideModal}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default AddBankErrorModal
