import { Mixpanel } from 'utils'

const InvestmentTabContainer = ({ onChange, tab }) => {
  const handleChange = (type) => {
    onChange(type)
    Mixpanel.track('Click Portfolio Tab', { 'Portfolio Tab': type === 'history' ? 'History' : 'Current Investments' })
  }

  return (
    <div className='invest-tabs-container'>
      <div className='page-tabs section-seperator '>
        <div className={`tab ${tab === 'current' ? 'b_18_semibold active' : 'b_18_regular'}`} onClick={() => handleChange('current')}>Current Investments</div>
        <div className={`tab ${tab === 'history' ? 'b_18_semibold active' : 'b_18_regular'}`} onClick={() => handleChange('history')}>History</div>
      </div>
    </div>
  )
}

export default InvestmentTabContainer
