import Button from 'components/Global/Button'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'

const UserAccrediationCTA = ({ redirect }) => {
  const { submitLoading } = useSelector((state) => state.ria)
  const { watch } = useFormContext()

  const reason = watch('reason')

  return (
    <div className='btn-group start'>
      <Button
        disabled={reason === null || submitLoading}
        type='submit'
        loading={submitLoading}
      >
        Confirm Verification
      </Button>
      <Button
        mode='tertiary'
        onClick={() => redirect('Cancel')}
        type='button'
        disabled={submitLoading}
      >
        Cancel
      </Button>
    </div>
  )
}

export default UserAccrediationCTA
