import { getDomain } from 'utils'
import { Link } from 'react-router-dom'
import { images } from 'assets'

const FooterLogo = ({ isOnAppDomain }) => {
  return (
    <div className='footer-logo'>
      {isOnAppDomain ? <a
        href={getDomain('/', true)}
      >
        <img className='logo' alt='Your Access to Private Investment' src={images['footer-logo']} />
      </a> : <Link to='/'>
        <img
          className='logo'
          alt='Your Access to Private Investment'
          src={images['footer-logo']}
        ></img>
      </Link>}
      <h3 className='footer-heading'>Your Access to Private Investment</h3>
    </div>
  )
}

export default FooterLogo
