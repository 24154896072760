import Toggle from 'components/Global/Toggle'
import { images } from 'assets'
import { useSelector } from 'react-redux'
import { formatDecimal, getDomain, Mixpanel } from 'utils'

const LinqtoBucksToggle = ({ useLinqtoBucks, setUeLinqtoBucks, refreshOrder, setPromoCode, setPromoCodeError }) => {
  const { maxOrderLinqtoBucksPercent, linqtoBucks, order: { amount, discountAmount } } = useSelector(
    (state) => state.placeOrderSlice
  )
  const toggleLinqtoBucks = () => {
    const selection = !useLinqtoBucks ? 'On' : 'Off'
    Mixpanel.track('Change Linqto Bucks Selection on Buy Order Review Page', { 'Linqto Bucks Selection': selection })
    setUeLinqtoBucks(!useLinqtoBucks)
    setPromoCode('')
    setPromoCodeError(false)
    refreshOrder(false, !useLinqtoBucks, '')
  }

  if (maxOrderLinqtoBucksPercent === 0 || linqtoBucks === 0) return null
  return (
    <div className='linqto-bucks-row'>
      <div className='sell-offer-row'>
        <div className='linqto-bucks-wrapper'>
          <img src={images['bucks-icon']} alt='Linqto Bucks' className='linqto-img' />
          <div className='linqto-copy'>
            <span>Use Linqto Bucks</span>
            <span className='linqto-amount'>{formatDecimal(linqtoBucks)}</span>
          </div>
        </div>
        <Toggle handleChange={toggleLinqtoBucks} checked={ useLinqtoBucks } ariaLabel='lqbToggleSwitch'/>
      </div>
      {useLinqtoBucks && (<div className='b_18_regular linqto-bucks-terms'>
        {linqtoBucks < (maxOrderLinqtoBucksPercent * amount) && 'Linqto Bucks cannot be combined with other promotions. ' }
        {linqtoBucks >= (maxOrderLinqtoBucksPercent * amount) && `Linqto Bucks can be used for up to ${maxOrderLinqtoBucksPercent * 100}% of the order and cannot be combined with other promotions. `}
        By using Linqto Bucks, I have read and agree to be bound by the <a className='inline-text-link' href={getDomain('/reward-terms-of-use', true)} target='_blank' rel='noreferrer'>Linqto Bucks Terms and Conditions.</a>
      </div>)
      }
      {discountAmount > 0 && <div className='linqto-bucks-terms'>Linqto Bucks cannot be combined with other promotions.</div>}
    </div>
  )
}

export default LinqtoBucksToggle
