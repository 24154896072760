import AddressInput from './AddressInput'
import SameAddress from './SameAddress'
import { Controller, useFormContext } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import PhoneNoInput from 'components/Global/Inputs/PhoneNoInput'
import Select from 'components/Global/Inputs/Select'
import IraEntity from './IraEntity'
import FinancialFields from './FinancialFields'
import ProfileFormSubmit from './ProfileFormSubmit'
import FormError from 'components/Global/FormError'
import OrderRedirect from './OrderRedirect'
import { ConditionalRender } from 'components'
import SSNMaskedInput from 'components/Global/Inputs/SSNMaskedInput'
import { useSelector } from 'react-redux'
import TrustedContact from './TrustedContact'
import { allocationOptions, annualIncomeOptions, netWorthOptions } from 'components/data'

const ProfileForm = ({
  tooltip,
  countries,
  primaryRegions,
  setPrimaryRegions,
  mailingRegions,
  setMailingRegions,
  onSubmit,
  entity,
  redirect,
  isAccreditedRedirect,
  setDirectToVerifyStatus,
  bigError,
  setValidateTrustedContact,
  setValidateFinancialProfile,
  setValidateEmployment,
  requiredError
}) => {
  const { handleSubmit, control, getValues, register, watch, clearErrors } = useFormContext()
  const primaryCountry = watch('pCountry')
  const citizenCountry = watch('citizenshipCountry')
  const showResidendcyStatus = primaryCountry === 'United States' && citizenCountry !== 'United States'
  const { isAccredited, initialValues, profileLoading, userProfile } = useSelector((state) => state.profile)
  const isDisabled = (field) => {
    if (!isAccredited) return false
    if (isAccredited) {
      switch (field) {
      case 'taxId':
        if (initialValues?.pCountry === 'United States' || initialValues?.citizenshipCountry === 'United States') {
          return initialValues?.taxId !== ''
        } else { return true }
      case 'foreignTaxId':
        if (initialValues?.pCountry !== 'United States' && initialValues?.citizenshipCountry !== 'United States') {
          return initialValues?.foreignTaxId !== ''
        } else {
          return true
        }
      default: return false
      }
    }
  }

  const onCitizenshipChange = () => clearErrors('usResidendcyStatus', 'NOT_REQUIRED')

  // Cleanup taxId edge case of if user inputs ssn and deletes it for foreign tax #
  const cleanupTaxId = (data) => {
    if (data?.taxId === '___-__-____') {
      data.taxId = data.taxId.replace(/[_-]/g, '')
      return data
    }
  }

  const onSubmitForm = (data) => {
    /* Update usResidencyStatus for backend use
       Default residency to NOT_REQUIRED if not shown in form
    */
    const residency = showResidendcyStatus ? data?.usResidendcyStatus?.replace(/-|\s/g, '_').toUpperCase() : 'NOT_REQUIRED'
    /* Get selected keys to send to backend for annual income, networth and allocation
       Get keys for backend if user is a returning accredited user
       otherwise set to default value of UNKNOWN
    */
    const annualIncome = Object.keys(annualIncomeOptions).find(key => annualIncomeOptions[key] === data.annualIncome)
    const netWorth = Object.keys(netWorthOptions).find(key => netWorthOptions[key] === data.networthExcludingResidence)
    const allocation = Object.keys(allocationOptions).find(key => allocationOptions[key] === data.capitalAllocation)

    // Map selected sectors from multiselect for backend payload
    const sectors = data.sectors.map(s => ({ name: s.label, sectorId: s.value }))

    cleanupTaxId(data)

    onSubmit({
      ...data,
      usResidendcyStatus: residency,
      annualIncome,
      networthExcludingResidence: netWorth,
      capitalAllocation: allocation,
      sectors
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className='box-content'>
        <div className='grid'>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='firstName'
              label='First Name'
              ariaLabel='firstName'
              disabled={profileLoading}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='lastName'
              label='Last Name'
              ariaLabel='lastName'
              disabled={profileLoading}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <PhoneNoInput
              label='Phone'
              name='phone'
              control={control}
              disabled={profileLoading}
              register={register}
              ariaLabel='phoneNo'
            />
          </div>
          <div className='column four sixteen-mobile'>
            <SSNMaskedInput
              name='taxId'
              label='Social Security # or ITIN #'
              placeholder='000-00-0000'
              content={tooltip.taxId}
              ariaLabel='taxId'
              disabled={profileLoading || isDisabled('taxId')}
              defaultValue = {userProfile?.taxId}
            />
          </div>
          <div className='column four sixteen-mobile'>
            <TextInput
              name='foreignTaxId'
              label='Foreign Tax or national ID#'
              disabled={profileLoading || isDisabled('foreignTaxId')}
              content={tooltip.foreignTaxId}
              ariaLabel='foreignTaxId'
            />
          </div>
          <AddressInput
            section={'p'}
            category={'Primary'}
            disabled={profileLoading}
            countries={countries}
            primaryRegions={primaryRegions}
            setPrimaryRegions={setPrimaryRegions}
            tooltip={tooltip}
            control={control}
          />
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='citizenshipCountry'
              render={({ field }) => (
                <Select
                  field={field}
                  label='Country of Citizenship'
                  name='citizenshipCountry'
                  options={countries}
                  ref={null}
                  handleChange={onCitizenshipChange}
                  disabled={profileLoading || isAccredited}
                  content={tooltip.citizenshipCountry}
                  defaultOption={{ value: '', label: 'Select Country' }}
                  ariaLabel='citizenshipCountry'
                />
              )}
            />
          </div>
          {showResidendcyStatus && <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='usResidendcyStatus'
              render={({ field }) => (
                <Select
                  field={field}
                  label='US Residency Status'
                  name='usResidendcyStatus'
                  options={['Resident Alien', 'Non-Resident Alien']}
                  ref={null}
                  disabled={profileLoading || (isAccredited && (initialValues.usResidendcyStatus !== 'NOT_REQUIRED'))}
                  defaultOption={{ value: 'NOT_REQUIRED', label: 'Status' }}
                  ariaLabel='usResidendcyStatus'
                />
              )}
            />
          </div>}
          <SameAddress profileLoading={profileLoading} />
          <AddressInput
            section={'m'}
            category={'Mailing'}
            sameAddress={!getValues('sameAddress')}
            disabled={profileLoading}
            countries={countries}
            mailingRegions={mailingRegions}
            setMailingRegions={setMailingRegions}
            tooltip={tooltip}
            control={control}
          />
        </div>
      </div>
      {/* Show Financial Fields in Profile Form if user is a returning accredited user */}
      {isAccredited && <FinancialFields setValidateFinancialProfile={setValidateFinancialProfile} setValidateEmployment={setValidateEmployment} />}
      {entity?.entityId > 0 && (
        <IraEntity
          entity={entity}
          redirect={redirect}
          loading={profileLoading}
        />
      )}
      {isAccredited &&
        <TrustedContact
          countries={countries}
          setValidateTrustedContact={setValidateTrustedContact} />}
      <div className='box-content'>
        {requiredError && (
          <FormError
            errorMessageHeader='Required Fields Missing'
            errorMessageCopy='Please make sure to fill in all required fields'
          />
        )}
        {bigError && (
          <FormError
            errorMessageHeader='Something Went Wrong'
            errorMessageCopy='Unable to save profile.'
          />
        )}
        <ConditionalRender isVisible={redirect && !isAccreditedRedirect}>
          <OrderRedirect />
        </ConditionalRender>
      </div>
      <ProfileFormSubmit
        redirect={redirect}
        isAccreditedRedirect={isAccreditedRedirect}
        setDirectToVerifyStatus={setDirectToVerifyStatus}
        onSubmit={onSubmit}
      />
    </form>
  )
}

export default ProfileForm
