import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const Breadcrumb = ({ items }) => {
  const history = useHistory()
  return (
    <div className='breadcrumb-container'>
      {items.map((item, i) => {
        return (
          <div key={`bc-${i}`}>
            <a
              onClick={() => history.push(item.route)}
              className='breadcrumb-link'
            >
              {item.name}
            </a>
            <img src={images['breadcrumb-arrow']} alt={item.name} />
          </div>
        )
      })}
    </div>
  )
}

export default Breadcrumb
