import { ErrorModal, PageLoading } from 'components'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import Breadcrumb from 'components/Global/Breadcrumb'
import { FormProvider, useForm } from 'react-hook-form'
import FBOAddFundsForm from './partials/FBOAddFundsForm'
import { getAddFundsPage, getPlaidLinkToken, getPlaidAccounts } from 'slices/walletSlice'
import { schema } from 'schemas/addFundsSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import WireTransferContent from './partials/WireTransferContent'
import { useLocation, useHistory } from 'react-router-dom'
import TopBanner from 'components/Global/TopBanner'
import { usePlaidLink } from 'react-plaid-link'
import BankAccountModal from 'components/FBOAccount/partials/BankAccountModal'
import ReactHtmlParser from 'react-html-parser'
import qs from 'qs'
import parseErrorMessages from 'actions/parsing/parseErrorMessages'

const FBOAddFunds = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [selectedAccount, setSelectedAccount] = useState(null)
  const [showTopBanner, setShowTopBanner] = useState(false)
  const [topBannerCopy, setTopBannerCopy] = useState({ title: '', body: '' })
  const [insufficientError, setInsufficientError] = useState(location?.state?.error === 'UPHOLD_INSUFFICIENT_FUNDS' || null)
  const [showWireInstruction, setShowWireInstruction] = useState(false)
  const [showPlaidErrorModal, setShowPlaidErrorModal] = useState(false)
  const [showPlaidUnderReviewModal, setShowPlaidUnderReviewModal] = useState(false)
  const [upholdAPIError, setUpholdAPIError] = useState('')

  const query = location.search
  const entityId =
    qs.parse(query, { ignoreQueryPrefix: true })?.entityId || null

  const items = [
    { name: 'My Portfolio', route: '/portfolio' },
    { name: 'Cash Account', route: `/cash-account${entityId ? `?entityId=${entityId}` : ''}` }
  ]

  const { pageLoading, bank, plaidLinkToken, loading } = useSelector((state) => state.walletSlice)

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    dispatch(getAddFundsPage(entityId)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload.plaidLinkingAllowed) dispatch(getPlaidLinkToken(entityId))
        const isBankLinked = payload?.cashExternalAccounts?.filter((a) => a.amlStatus === 'APPROVED').length > 0 ? true
          : payload.cashExternalAccounts?.filter((a) => a.amlStatus === 'NEEDS_REVIEW').length > 0 ? 'Under Review' : false

        Mixpanel.track('View Add Funds Page', {
          'Uphold Linked': payload.hasUphold ? payload.upholdError ? 'Email Verification' : true : false,
          'Bank Account Linked': isBankLinked
        })
        if (
          payload &&
          payload.hasUphold &&
          sessionStorage.getItem('connectUpholdAddFunds')
        ) {
          if (payload.upholdError) {
            setTopBannerCopy({ title: 'Almost there!', body: `Please check your email. ${payload.upholdError}` })
          } else {
            setTopBannerCopy({ title: 'Uphold Successfully Linked', body: 'Your Uphold Account can now be used as a funding method' })
            Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Uphold' })
          }
          setShowTopBanner(true)
          sessionStorage.removeItem('connectUpholdAddFunds')
        }
        if (insufficientError) {
          const upholdCards = payload.upholdCards
          setSelectedAccount({ ...upholdCards.filter((a) => a.id === location?.state?.cardId)[0], fundingMethod: 'Uphold' })
          methods.reset({ amount: `$${location?.state?.amount}` })
          methods.setError('amount', {
            type: 'UPHOLD_INSUFFICIENT_FUNDS',
            message: 'Insufficient funds. Change the amount to transfer or select a different funding source.'
          })
        }
      } else if (meta.requestStatus === 'rejected') {
        if (payload) {
          setUpholdAPIError(payload)
        }
      }
    })
  }, [])

  const onSubmit = async (formValues) => {
    if (selectedAccount) {
      const formAmount = formValues.amount.charAt(0) === '$' ? formValues.amount.substring(1) : formValues.amount
      Mixpanel.track('Click Next on Add Funds Page', { 'Funding Method': selectedAccount.fundingMethod, Currency: selectedAccount.currency, Amount: formAmount })
      history.push({
        pathname: `/cash-account/add-funds-summary/${selectedAccount.id}`,
        search: `?amount=${formAmount}&cardCurrency=${selectedAccount.currency}&cardLabel=${selectedAccount.label}&type=${selectedAccount.fundingMethod}${entityId ? `&entityId=${entityId}` : ''}`
      })
    }
  }

  const cancelAddFunds = () => {
    Mixpanel.track('Click Cancel on Add Funds Page')
    history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
  }

  const handleUpholdAPIError = () => {
    setUpholdAPIError('')
    history.goBack()
  }

  const onSuccess = useCallback(async (publicToken, metadata) => {
    window.scrollTo(0, 0)
    dispatch(getPlaidAccounts({ publicToken: metadata.public_token, plaidAccountId: metadata.account_id, entityId })).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload === 500) {
          setShowPlaidErrorModal(true)
        } else {
          if (payload?.amlStatus === 'NEEDS_REVIEW') {
            setShowPlaidUnderReviewModal(true)
          } else {
            Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Bank Account' })
            setTopBannerCopy({ title: 'Account(s) Successfully Connected', body: 'New account is now available as a funding method.' })
            setShowTopBanner(true)
          }
          dispatch(getAddFundsPage(entityId))
        }
      }
    })
  }, [])

  const onExit = useCallback(() => {
    setShowPlaidErrorModal(true)
    Mixpanel.track('Cancel Plaid Linking')
  }, [])

  const config = {
    token: plaidLinkToken,
    onSuccess,
    onExit
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    Mixpanel.track('Click Link Account on Select Funding Method', { 'Account Type': 'Bank Account' })
    open()
  }

  if (pageLoading || loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Add Funds')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      {upholdAPIError && <ErrorModal
        error={parseErrorMessages({ error: upholdAPIError })?.placeOrderError}
        hideModal={handleUpholdAPIError}
        message='OK'
      />}
      <SeoMeta title={seoTitleTemplate('Add Funds')} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          {showTopBanner && (
            <TopBanner
              title={topBannerCopy.title}
              body={topBannerCopy.body}
              hideBanner={() => setShowTopBanner(false)}
            />
          )}
          <Breadcrumb items={items} />
          <h1>Add Funds</h1>
          <div className='add-funds-container'>
            <FormProvider {...methods}>
              <FBOAddFundsForm
                onSubmit={onSubmit}
                cancelAddFunds={cancelAddFunds}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                insufficientError={insufficientError}
                setInsufficientError={setInsufficientError}
                setShowWireInstruction={setShowWireInstruction}
                connectPlaidAccount = {connectPlaid}
                entityId={entityId}
              />
            </FormProvider>
            {showWireInstruction && (
              <WireTransferContent bank={bank}/>
            )}

            {showPlaidErrorModal && <BankAccountModal
              title='Trouble Connecting to Bank'
              copy={ReactHtmlParser(`We are having trouble connecting to your bank and are unable to add your bank account at this time.<br /><br />
              Please come back and  try again later.`)}
              buttonCopy='Okay'
              handleClick={() => setShowPlaidErrorModal(false)}
              hideModal={() => setShowPlaidErrorModal(false)}
            />}
            {showPlaidUnderReviewModal && <BankAccountModal
              title='Review Required'
              copy={ReactHtmlParser(`It looks like the name on this account is not an exact match to the name on your Linqto account. Additional review is required before you can use this account to add funds.
              <br /><br />
              We will contact you once it has been reviewed (typically 1-2 days) and let you know if there is any more information required.`)}
              buttonCopy='Finish'
              handleClick={() => setShowPlaidUnderReviewModal(false)}
              hideModal={() => setShowPlaidUnderReviewModal(false)}
            />}
          </div>
        </div>
      </div>
    </>
  )
}
export default FBOAddFunds
