import { GrowthBook } from '@growthbook/growthbook-react'
import { Mixpanel } from 'utils'

const growthbookClientKey = {
  'localhost:3000': 'sdk-QJ3brdON7qPchHK',
  'dev.linqto.com': 'sdk-QJ3brdON7qPchHK',
  'dev.app.linqto.com': 'sdk-QJ3brdON7qPchHK',
  'app.dev.linqto.com': 'sdk-QJ3brdON7qPchHK',
  'alpha.linqto.com': 'sdk-wWI7Do5pAkUgwC3j',
  'alpha.app.linqto.com': 'sdk-wWI7Do5pAkUgwC3j',
  'app.alpha.linqto.com': 'sdk-wWI7Do5pAkUgwC3j',
  'beta.linqto.com': 'sdk-HQAzZLkHmJCryk9a',
  'app.beta.linqto.com': 'sdk-HQAzZLkHmJCryk9a',
  'beta.app.linqto.com': 'sdk-HQAzZLkHmJCryk9a',
  'www.linqto.com': 'sdk-ko0KaVCh6GNPLYUB',
  'app.linqto.com': 'sdk-ko0KaVCh6GNPLYUB'
}

// Create a GrowthBook instance
export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: growthbookClientKey[window.location.host],
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log('trackingCallback triggered')
    Mixpanel.track('$experiment_started', { 'Experiment name': experiment.key, 'Variant name': result.variationId, $source: 'growthbook' })
  }
})
