import Button from 'components/Global/Button'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { useQuery } from 'hooks/useQuery'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { getConfirmedSellOfferDetail } from 'slices/sellOfferSlice'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SellOfferHeader from './partials/SellOfferHeader'
import SellOfferSummaryContainer from './SellOfferSummary/SellOfferSummaryContainer'

const SellOfferConfirmed = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  const { companyUrlName, pageLoading } = useSelector(state => state.sellOffer)
  const id = query.get('offerId')

  useEffect(() => {
    dispatch(getConfirmedSellOfferDetail(id)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Create Sell Offer Confirmation Page (ATS)', { 'Company Name': payload.companyName })
      }
    })
  }, [id])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Offer Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Offer Confirmed')} />
      <div className='page-container sell-offer-container'>
        <div className='inner-container'>
          <SellOfferHeader title='Offer Confirmed' />
          <div className='sell-offer-page-container'>
            <SellOfferSummaryContainer
              id={id}
              titleCopy='We will notify you when someone purchases your shares.' />
            <span className='customer-support-copy'>
            Offers remain active until cancelled or sold. All sales are final.
            </span>
            <div className='btn-group right'>
              <Button onClick={() => history.push(`/portfolio/${companyUrlName}`)}>Close</Button>
            </div>
            <div className='b_18_regular space-above-sm'>
            Have a question about your offer?{' '}
              <Link onClick={() => Mixpanel.track('Click Contact Us', { Location: 'Sell Offer Confirmation Page' })}
                to={`/contact?topic=Order Support&message=Re: Sell Offer ID ${id}`}
                className='link primary contact-us'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellOfferConfirmed
