import Modal from '../../Global/Modal'
import Button from '../../Global/Button'
import { ConditionalRender } from 'components'
import { useSelector } from 'react-redux'

const USSuccessModal = ({ hideModal }) => {
  const { accreditationRegion } = useSelector(
    (state) => state.investorStatusSlice
  )

  return (
    <>
      <Modal
        mode='primary'
        size='md'
        clickToClose
        pressToClose
        crossToClose
        hideModal={hideModal}
        modalHeader='Successful Document Upload'
        innerStyle='document-success-modal'
      >
        <div className='b_18_semibold'>
          Your documents were successfully uploaded.
        </div>
        <div className='b_18_regular'>
          Our team will review your submission right away. You’ll receive an
          email when you are verified.
        </div>
        <div className='b_18_regular'>
          In the mean time, you can upload additional documents.
        </div>
        <ConditionalRender isVisible={accreditationRegion !== 'NONE'}>
          <div className='b_18_regular'>
            Be sure that your submitted documents reflect at least two (2) years
            if accredited by income, or $1 million total assets (not including
            home residence) if accredited by net worth.
          </div>
        </ConditionalRender>
        <div className='btn-group centered'>
          <Button onClick={hideModal}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default USSuccessModal
