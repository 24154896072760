import React from 'react'
import { useSelector } from 'react-redux'
import HistoryColumns from './HistoryColumns'
import HistoryItem from './HistoryItem'

const ActiveOffers = () => {
  const { activeOffers } = useSelector(state => state.portfolioSlice)
  if (!activeOffers || !activeOffers.length) {
    return null
  }

  return (
    <div className='history-grid-container'>
      <h3>Active Offer</h3>
      <HistoryColumns showCompany={false}/>
      {activeOffers && activeOffers.length ? activeOffers.map(order => <HistoryItem key={order.orderId} order={order} showCompany={false} type='OFFER' />) : null}
    </div>
  )
}

export default ActiveOffers
