import UpholdWalletAccounts from './UpholdWalletAccounts'
import UpholdWalletHeader from './UpholdWalletHeader'

const UpholdWalletConnected = ({ hasUphold, upholdCards, disconnectUpholdWallet }) => {
  return (
    <>
      <UpholdWalletHeader hasUphold={hasUphold} connectUpholdWallet={() => {}} disconnectUpholdWallet={disconnectUpholdWallet}/>
      <UpholdWalletAccounts upholdCards={upholdCards}/>
    </>
  )
}

export default UpholdWalletConnected
