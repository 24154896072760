const UnicornDetailsOfficers = ({ officers, style }) => {
  return (
    <>
      <div className='desktop-content' >
        <div className='grid unicorn-table'>
          <div className='row unicorn-table-header'>
            <div className='column four'>
                Name
            </div>
            <div className='column four'>Work History</div>
            <div className='column four'>Title</div>
            <div className='column four'>Status</div>
          </div>
          {officers && officers.map((p, i) =>
            <div key={`keyOfficer_${i}`} className='unicorn-table-body row'>
              <div className={`column four ${style}`}>{p.name}</div>
              <div className={`column four ${style}`}>{p.workHistory}</div>
              <div className={`column four ${style}`}>{p.title}</div>
              <div className={`column four ${style}`}>{p.status}</div>
            </div>
          )}
        </div>
      </div>
      <div className='unicorn-card-container'>
        {officers && officers.map((p, i) =>
          <div className='unicorn-card' key={`mkeyOfficer_${i}`} >
            <div className='grid'>
              <div className='row unicorn-card-row'>
                <div className={`column eight ${style}`}>{p.name}</div>
                <div className={`column eight ${style}`}>{p.title} <br/>{p.status && `(${p.status})`}</div>
              </div>
              <div className='sixteen row unicorn-card-row-line-sepretaor' />
              <div className='row unicorn-card-row'>
                <div className={`column sixteen ${style}`}>Work History:</div>
                <div className={`column sixteen ${style}`}>{p.workHistory}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UnicornDetailsOfficers
