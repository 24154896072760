import { images } from 'assets'
import { formatDecimal } from 'utils'
import ConditionalRender from 'components/Global/ConditionalRender'

const ReferralInfo = ({
  referrerName,
  referralAmount,
  referrerLogo,
  riaFirmName,
  type = 'linqto-referral'
}) => {
  const imgURL =
    referrerLogo !== ''
      ? referrerLogo
      : type === 'linqto-referral'
        ? images['linqto-bucks']
        : images['advisor-referral-logo']
  const boxClass =
    referrerLogo !== ''
      ? 'signup-reward-container partner-signup-reward-container'
      : 'signup-reward-container'

  return (
    <>
      <ConditionalRender isVisible={type === 'linqto-referral'}>
        <div className={boxClass}>
          <img src={imgURL} alt='Linqto Referral Program' />
          <div>
            Your <strong>{formatDecimal(referralAmount, true, 0)}</strong> Linqto Bucks credit is waiting for you!
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender isVisible={type === 'advisor-referral'}>
        <div className={boxClass}>
          <div>
            <strong>{referrerName}</strong> from <strong>{riaFirmName}</strong> invites you to be their client on Linqto. Sign
            in or create an account to accept.
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender isVisible={type === 'advisor-terms'}>
        <div className={boxClass}>
          <img
            src={imgURL}
            className='advisor-regerral-logo'
            alt='Advisor Referral'
          />
          <div>
            <strong>{referrerName}</strong> from <strong>{riaFirmName}</strong> invites you to be their client on Linqto.
          </div>
        </div>
      </ConditionalRender>
    </>
  )
}

export default ReferralInfo
