import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from 'slices/userSlice'
import NavHeader from './partials/NavHeader'
import MobileNav from './partials/MobileNav'
import WebNav from './partials/WebNav'
import { Mixpanel } from 'utils'

const Nav = ({ isUserReturned }) => {
  const dispatch = useDispatch()
  const { isSignedIn } = useSelector((state) => state.userSlice)
  const [showDropdown, setShowDropdown] = useState(false)
  const [toggleStatus, setToggleStatus] = useState(false)

  const logOut = () => {
    Mixpanel.track('Click Sign out')
    dispatch(signOut()).then(() => {
      // if domain is www.linqto.com, beta.linqto.com, alpha.linqto.com, dev.linqto.com
      if (!window.location.hostname.includes('app')) {
        window.location = '/'
        // if domain is app.linqto.com => We need to test on this domain
      } else if (window.location.hostname === 'app.linqto.com') {
        window.location = 'https://www.linqto.com/'
        // if domain is app.beta.linqto.com, app.alpha.linqto.com, app.dev.linqto.com => We need to test on this domain
      } else {
        const hostname = window.location.hostname.substring(4, window.location.hostname.length)
        window.location = `https://${hostname}`
      }
    })
  }

  const accountNavItems = [
    { route: '/portfolio', name: 'My Portfolio', tag: 'portfolio', show: false, domain: 'user', showOnMobile: true },
    { route: '/linqto-wallet', name: 'Wallet', tag: 'wallet', show: false, domain: 'user', showOnMobile: true },
    { route: '/documents', name: 'Documents', tag: 'documents', show: false, domain: 'user', showOnMobile: true },
    { route: '/profile', name: 'Investor Profile', tag: 'profile', show: false, domain: 'user', showOnMobile: true },
    { route: '/settings', name: 'Settings', tag: 'settings', show: false, domain: 'user', showOnMobile: true },
    { route: '/rewards', name: 'Linqto Bucks', tag: 'rewards', show: true, domain: 'user', showOnMobile: true },
    { route: '/', name: 'Sign out', tag: 'signout', show: false, domain: 'user', showOnMobile: false, action: logOut }
  ]

  const learnNavItems = [
    { route: '/about', name: 'About Linqto', tag: 'about', show: false, domain: 'global', showOnMobile: true, cms: true },
    { route: '/faq', name: 'FAQs', tag: 'faq', show: false, domain: 'global', showOnMobile: true, cms: true },
    { route: '/blog', name: 'Blog', tag: 'blog', show: false, domain: 'global', showOnMobile: true, cms: true },
    { route: '/market-insights/', name: 'Market Insights', tag: 'marketInsights', show: false, domain: 'global', showOnMobile: true, cms: true }
  ]
  // NOTE: array of nav items
  const mainNavItems = [
    { route: '/products', name: 'Invest', tag: 'invest', show: false, domain: 'global', showOnMobile: true, cms: !isSignedIn },
    { route: '/sell', name: 'Sell', tag: 'sell', show: false, domain: 'public', showOnMobile: true, cms: true },
    { route: '/partners', name: 'Partners', tag: 'partners', show: true, domain: 'public', showOnMobile: true, cms: true },
    { route: '/', name: 'Learn', tag: 'learn', show: false, domain: 'global', showOnMobile: false, submenu: learnNavItems },
    { route: '/signin', name: 'Sign In', tag: 'sign-in', show: false, domain: 'public', showOnMobile: true, cms: false },
    { route: '/signup', name: 'Sign Up', tag: 'signup', show: false, domain: 'public', showOnMobile: true, style: 'button', cms: false },
    { route: '/', name: 'Account', tag: 'account', show: false, domain: 'user', showOnMobile: false, submenu: accountNavItems }
  ]

  const filterNavByStatus = (nav) => {
    const newNav = isSignedIn
      ? nav.filter((i) => i.domain === 'user' || i.domain === 'global')
      : nav.filter((i) => i.domain === 'public' || i.domain === 'global')
    return newNav
  }

  return (
    <div id='header-div' className='nav-container'>
      <NavHeader
        setToggleStatus={setToggleStatus}
        toggleStatus={toggleStatus}
      />
      {isUserReturned &&
      <>
        <WebNav
          setToggleStatus={setToggleStatus}
          toggleStatus={toggleStatus}
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
          items={filterNavByStatus(mainNavItems)}
          filterNavByStatus={filterNavByStatus}
        />
        {toggleStatus && (
          <MobileNav
            setToggleStatus={setToggleStatus}
            toggleStatus={toggleStatus}
            items={filterNavByStatus(mainNavItems)}
            filterNavByStatus={filterNavByStatus}
          />
        )}
      </>}
    </div>
  )
}

export default Nav
