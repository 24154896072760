import { Modal, Content, Wrapper, Row } from 'components'
import Button from '../../Global/Button'

const NotAccreditedSubmitSuccessModal = ({ hideModal }) => {
  return (
    <Modal mode='error' size='md' crossToClose hideModal={hideModal} modalHeader='Your Profile Is Complete'>
      <Wrapper className='crd-modal-container'>
        <Content className='container'>
          <div className='b_18_regular'>
            You’re one step away from being able to invest! Click below to add funds to your wallet.
          </div>
        </Content>
        <Row className='btn-group centered'>
          <Button onClick={hideModal}>Add Funds</Button>
        </Row>
      </Wrapper>
    </Modal>
  )
}

export default NotAccreditedSubmitSuccessModal
