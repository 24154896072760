import React from 'react'
import { formatDecimal } from 'utils'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const CashBalanceItem = ({ totalCash, groupBy, entityId, hasEntities }) => {
  const history = useHistory()
  let urlName = ''
  if (groupBy === 'investment') {
    if (hasEntities) {
      urlName = '/linqto-wallet'
    } else {
      urlName = '/cash-account'
    }
  } else {
    urlName = `/cash-account${entityId ? `?entityId=${entityId}` : ''}`
  }
  return (
    <div className='current-investment-item' onClick={() => history.push(urlName)}>
      <div className='current-investment-item_left'>
        <img src={images['wallet-cash-account']} alt='Cash Balance' />
        <span className='b_18_regular'>Cash Balance</span>
      </div>
      <div className='current-investment-item_right'>
        <div className='current-investment-item_right shares-total-price-container'>
          <span className='current-investment-item_right shares'> </span>
          <span className='b_18_semibold current-investment-item_right total-price'>{formatDecimal(totalCash)}</span>
        </div>
        <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} />
      </div>
    </div>
  )
}

export default CashBalanceItem
