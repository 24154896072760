import Button from 'components/Global/Button'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import Checkbox from 'components/Global/Inputs/Checkbox'
import AddFundsError from './AddFundsError'

const OrderConfirmationForm = ({
  hanldeCommitOrder,
  selectedAccount
}) => {
  const {
    commitLoading,
    order: { total }
  } = useSelector((state) => state.placeOrderSlice)

  const cashBalance = selectedAccount?.amountAvailable

  const { handleSubmit, formState: { errors } } = useFormContext()
  return (
    <form onSubmit={handleSubmit(hanldeCommitOrder)}>
      <div className='sell-offer-section'>
        <div className='sell-offer-order sell-offer-order-end'>
          <div className='sell-offer-row'>
            <Checkbox
              label='I confirm I have read these documents and agree to be bound by their provisions.'
              name='confirmOrderCheckbox'
              ariaLabel='confirmOrderCheckbox'
              disabled={commitLoading}
              checkboxClass='confirm-checkbox'
            />
          </div>
          <AddFundsError selectedAccount={selectedAccount} />
          <div className='sell-offer-row flex-end'>
            <Button
              type='submit'
              loading={commitLoading}
              disabled={commitLoading || Object.keys(errors).length > 0 || total > cashBalance}
              customClass='place-order-button'
            >
            Place Order
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default OrderConfirmationForm
