import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { seoTitleTemplate, getCostBasis, getAverageSharePrice, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import SellOfferHeader from './partials/SellOfferHeader'
import SellOfferDetail from './partials/SellOfferDetail'
import { getSellOffer } from 'slices/sellOfferSlice'
import SellOfferErrorModal from './partials/SellOfferErrorModal'

const SellOffer = ({ match }) => {
  const dispatch = useDispatch()
  const methods = useForm({ mode: 'onTouched' })
  const { reset, setValue, watch } = methods
  const id = match.params.id || ''

  const {
    minimumTotalSellPrice,
    transactionFee,
    transactionFlatFee
  } = useSelector((state) => state.sellOffer)

  const [subTotal, setSubTotal] = useState(0)
  const [transactionFees, setTtransactionFees] = useState(0)
  const [totalProceeds, setTotalProceeds] = useState(0)
  const [costBasisOfShares, setCostBasisOfShares] = useState(0)
  const [averageCost, setAverageCost] = useState(0)
  const [projectedGain, setProjectedGain] = useState(0)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [disableSharesToSell, setDisableSharesToSell] = useState(false)
  const [min, setMin] = useState(0)
  const [sliderIndex, setSliderIndex] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(getSellOffer(id)).then(({ meta, payload }) => {
      setLoading(false)
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Create Sell Offer Page (ATS)', { 'Company Name': payload.companyName })
        const { shareOwningAccounts, marketLastSoldPrice, minimumTotalSellPrice } = payload || {}
        if (shareOwningAccounts && shareOwningAccounts.length) {
          calculateMinimumShareAmount(marketLastSoldPrice, shareOwningAccounts?.[0]?.sellableShareCount, minimumTotalSellPrice)
          setDisableSharesToSell(marketLastSoldPrice * shareOwningAccounts?.[0]?.sellableShareCount < minimumTotalSellPrice)
          if (shareOwningAccounts.length === 1) {
            const account = shareOwningAccounts?.[0] || {}
            setValue('shareOwningAccount', account)
            setValue('sellableShareCount', account.sellableShareCount)
          }
          reset(payload.initialValues)
        }
      } else if (meta.requestStatus === 'rejected') {
        setShowErrorModal(true)
      }
    })
  }, [])

  useEffect(() => {
    calculateTotals()
  }, [watch('shareOwningAccount')])

  const calculateTotals = () => {
    const shareOwningAccount = watch('shareOwningAccount')
    const sellableShareCount = watch('sharesForAccount')
    const { shareLots } = shareOwningAccount || {}
    const subTotalValue =
      watch('marketLastSoldPrice') * sellableShareCount
    const transactionFees = (subTotalValue * transactionFee).toFixed(2)
    const aveCostBasis = getCostBasis(shareLots || [], sellableShareCount)
    const aveCostShare = getAverageSharePrice(aveCostBasis, sellableShareCount)
    const totalProceeds = subTotalValue - transactionFees - transactionFlatFee
    setSubTotal(subTotalValue)
    setTtransactionFees(transactionFees)
    setTotalProceeds(totalProceeds)
    setAverageCost(aveCostShare)
    setCostBasisOfShares(aveCostBasis)
    setProjectedGain(totalProceeds - aveCostBasis)
  }

  const disableSharesToSellInput = (subTotal, minShareAmountEqualsTotalSellableShares) =>
    setDisableSharesToSell(!!(subTotal < minimumTotalSellPrice || minShareAmountEqualsTotalSellableShares))

  const calculateMinimumShareAmount = (price, sellableShareCount, minimumTotalSellPrice) => {
    setSliderIndex(sellableShareCount)
    let minShareAmount = Math.round(minimumTotalSellPrice / price)
    if (minShareAmount * price < minimumTotalSellPrice) {
      minShareAmount = (Math.round(minimumTotalSellPrice / price) + 1)
    }
    if (minShareAmount < sellableShareCount) {
      setMin(minShareAmount)
      return minShareAmount
    } else if (minShareAmount === sellableShareCount) {
      setMin(sellableShareCount - 1)
      return sellableShareCount
    } else {
      setMin(sellableShareCount)
      return sellableShareCount
    }
  }
  if (loading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Create Sell Offer')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Create Sell Offer')} />
      <div className='page-container sell-offer-container'>
        <div className='inner-container'>
          <SellOfferHeader title='Create Sell Offer' showLearnMoreLink />
          <FormProvider {...methods}>
            <SellOfferDetail
              id={id}
              subTotal={subTotal}
              transactionFees={transactionFees}
              totalProceeds={totalProceeds}
              costBasisOfShares={costBasisOfShares}
              averageCost={averageCost}
              calculateTotals={calculateTotals}
              projectedGain={projectedGain}
              disableSharesToSell={disableSharesToSell}
              setDisableSharesToSell={setDisableSharesToSell}
              min={min}
              calculateMinimumShareAmount={calculateMinimumShareAmount}
              setSliderIndex={setSliderIndex}
              sliderIndex={sliderIndex}
              disableSharesToSellInput={disableSharesToSellInput}
            />
          </FormProvider>
          {showErrorModal && (
            <SellOfferErrorModal
              setShowErrorModal={setShowErrorModal}
              companyURL={id}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default SellOffer
