import USDocumentUpload from './USDocumentUpload'
import { USDoceumentTypes } from '../../data'

const USNetWorthSection = () => {
  const intialDocsToUpload = [{ id: 1, name: 'Select Document Type' }]
  return (
    <USDocumentUpload
      dropDownList={USDoceumentTypes.NET_WORTH}
      intialDocsToUpload={intialDocsToUpload}
    />
  )
}

export default USNetWorthSection
