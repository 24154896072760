import Button from '../../Global/Button'
import { images } from 'assets'
import { useDispatch, useSelector } from 'react-redux'
import { submitEntityCreation } from 'slices/profileSlice'

const AddEntity = () => {
  const dispatch = useDispatch()

  const { submitEntityLoading } = useSelector((state) => state.profile)

  const submitEntity = (e) => {
    e.preventDefault()
    return dispatch(submitEntityCreation())
  }

  return (
    <>
      {submitEntityLoading === 'init' && (
        <div className='add-entity'>
          <div className='btn-group start'>
            <Button
              type='button'
              mode='primary-alt'
              onClick={(e) => submitEntity(e)}
              disabled={submitEntityLoading === 'loading'}
              loading={submitEntityLoading === 'loading'}
            >
              I Want to Add an Entity
            </Button>
          </div>
        </div>
      )}
      {submitEntityLoading === 'success' && (
        <div className='box message-box'>
          <img
            alt='Identity Step'
            src={images['accreditation-verified-status']}
            className='entity-icon'
          />
          <div className='b_18_regular'>
            Thanks for letting us know you want to add an entity. We&apos;ll
            reach out shortly to set up this investment option for you.
          </div>
        </div>
      )}
      {submitEntityLoading === 'error' && (
        <div className='box message-box'>
          <img
            alt='Identity Step'
            src={images['profile-error']}
            className='entity-icon entity-icon-error'
          />
          <div>
            An error has occured. Please email{' '}
            <a href='mailto:invest@linqto.com'>invest@linqto.com</a> to add an
            entity.{' '}
          </div>
        </div>
      )}
    </>
  )
}

export default AddEntity
