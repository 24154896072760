import { useRef, useState, useEffect } from 'react'
import { images } from 'assets'

const renderFileName = (name, file) => {
  return (name === '') ? file[0]?.name.slice(0, 100) : name
}
const deleteAction = (e, handleDelete) => {
  e.stopPropagation()
  handleDelete(e)
}
const renderDelete = (handleDelete) => {
  return (
    <div className='delete-icon' onClick={(e) => deleteAction(e, handleDelete)}>
      <svg
        width='16'
        height='18'
        viewBox='0 0 16 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <line
          x1='1'
          y1='-1'
          x2='20.5418'
          y2='-1'
          transform='matrix(0.677334 0.735675 -0.677334 0.735675 0 2.15234)'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <line
          x1='1'
          y1='-1'
          x2='20.5418'
          y2='-1'
          transform='matrix(0.677334 -0.735675 0.677334 0.735675 1.40918 17.8479)'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
    </div>
  )
}

const RenderLoadingUpload = ({ progress }) => {
  const dropZone = useRef()
  const [width, setWidth] = useState(0)
  useEffect(() => {
    setWidth(dropZone.current.offsetWidth)
  }, [dropZone])
  return (
    <>
      <div className='dropzone-new dropzone-loading' ref={dropZone}>
        <div className='left-group'>
          <span className='b_18_semibold doc-title'>Uploading... {progress}%</span>
        </div>
      </div>
      <div className='upload-progress-bar' style={{ width: (progress / 100) * width }}/>
    </>
  )
}

const RenderErrorUpload = ({ enableView, uploadErrorTitleMessage, uploadErrorMessage, handleDelete }) => {
  return (
    <>
      <div className='dropzone-new dropzone-error'>
        <div className='left-group'>
          <span className='b_18_semibold doc-title'>{uploadErrorTitleMessage}</span>
        </div>
        {!enableView && renderDelete(handleDelete)}
      </div>
      {<div className='b_16_semibold err-msg upload-err-ms error-type'>{uploadErrorMessage}</div>}
    </>
  )
}
const handleViewClick = (enableView, url, onViewDocument) => {
  if (enableView) {
    window.open(url)
  }
}
const RenderSuccessUpload = ({ enableView, name, fileUploaded, handleDelete, url, allowDelete, ariaLabel, onViewDocument = () => {}, disabled = false }) => {
  return (
    <>
      <div className={`dropzone-new ${enableView && 'click-to-view'} ${disabled && 'disabled'}`} aria-label={ariaLabel} onClick={() => {
        onViewDocument()
        handleViewClick(enableView, url)
      }}>
        <div className='left-group'>
          <img alt='pdf' className='upload-img' src={images.pdf} />
          <span className='b_18_semibold doc-title'>{renderFileName(name, fileUploaded)}</span>
        </div>
        {allowDelete && !disabled && renderDelete(handleDelete)}
        {!allowDelete && enableView && <img src={images['upload-right-arrow']} alt='View Document' className='upload-arrow' />}
      </div>
    </>
  )
}

const RenderIntitalUpload = ({ placeHolder, dragging, getRootProps, getInputProps, open, uploadError = false, disabled = false }) => {
  return (
    <>
      <div {...getRootProps({ className: `dropzone-new ${uploadError && 'dropzone-error'} ${dragging && 'dragging'} ${disabled && 'disabled'}` })}>
        <input {...getInputProps()} aria-label='upload-component' disabled={disabled}/>
        <div className='b_16_regular'>{placeHolder}</div>
        <button type='button' className='b_16_regular browse-button' onClick={open}>
          Browse
        </button>
      </div>
    </>
  )
}

export {
  renderFileName,
  RenderLoadingUpload,
  RenderErrorUpload,
  RenderSuccessUpload,
  RenderIntitalUpload
}
