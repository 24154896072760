import { Field } from 'redux-form'
import { images } from 'assets'
import renderer from './renderer'

const IraEntity = ({ entity, redirect, loading }) => {
  return (
    <div className='box'>
      <div className='b_18_semibold box-title top-border'>{entity.title}</div>
      <div className='box-content'>
        <div className='grid verified ira-exist'>
          {!entity.isComplete && (
            <>
              <div className='four column eight-tablet eight-mobile'>
                <span className='b_16_regular'>{entity.name}</span>
              </div>
              <div className='four column eight-tablet eight-mobile'>
                <Field
                  name='type'
                  component={renderer.renderIRAType}
                  label='IRA Type'
                  redirect={redirect}
                  options={entity.types}
                  disabled={loading}
                />
              </div>
            </>
          )}
          {entity.isComplete && (
            <div className='eight column'>
              <div className='verified ira-exist'>
                <span>{entity.name}</span>
                <img
                  width={25}
                  alt='Entity verfied'
                  src={images.checkmark}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default IraEntity
