import { Mixpanel, getDomain } from 'utils'
import { NavLink } from 'react-router-dom'
import { images } from 'assets'
// TODO: maybe can consolidate with NavItem component
const DropDownItem = ({ setShowDropdown, showDropdown, item, filterNavByStatus, isActive }) => {
  const clickDropdownItem = () => {
    Mixpanel.track('Click Main Nav', { 'Main Nav Item': item?.name })
    setShowDropdown(false)
  }
  return (
    <div
      key={item.tag}
      className={`item dropdown dropdown-6 animate-up ${isActive ? 'active' : ''}`}
      onMouseOver={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      aria-label={item.tag}
    >
      <span className='dropdown-title'>{item.name}</span>
      <img src={images['chevron-down']} height={20} width={20} />
      {showDropdown && (
        <div className='dropdown_menu dropdown_menu--animated dropdown_menu-6'>
          {filterNavByStatus(item.submenu).map((i) => {
            if (i.action) {
              return (
                <div
                  key={`nav-${i.tag}`}
                  className={`item dropdown ${i.tag === 'signout' ? 'cobalt' : ''}`}
                  onClick={i.action}
                >
                  {i.name}
                </div>
              )
            }
            // only refresh the whole page is domain is on app and navigate to wp
            if (i.cms && window.location.hostname.includes('app')) {
              return (<a
                key={`nav-${i.tag}`}
                href={getDomain(i.route, i?.cms)}
                onClick={clickDropdownItem}
                className='item dropdown'
              >
                <span>{i.name}</span>
              </a>
              )
            }
            return (
              <NavLink
                key={`nav-${i.tag}`}
                to={i.route}
                onClick={clickDropdownItem}
                className='item dropdown'
              >
                <span>{i.name}</span>
              </NavLink>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default DropDownItem
