import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/private.js'
import { formatDecimal } from 'utils'

const initialState = {
  pageLoading: false,
  companyIconUrl: '',
  companyId: '',
  companyName: '',
  companyUrlName: '',
  marketCurrentMaxPrice: 0,
  marketCurrentMinPrice: 0,
  marketLastSoldPrice: 0,
  minimumTotalSellPrice: 0,
  sellableShareCount: 0,
  shareLots: [],
  transactionFeeRate: 0,
  offerStatus: '',
  projectedGain: 0,
  avgOriginalHeldPricePerShare: 0,
  totalOriginalHeldSharePrice: 0,
  shareCount: 0,
  sharePrice: 0,
  subTotal: 0,
  totalProceeds: 0,
  transactionFee: 0,
  offerId: 0,
  sellOrder: null,
  deactivatedAt: null,
  shareOwningAccounts: [],
  transactionFlatFee: 0,
  linqtoFlatFee: 0,
  loading: false
}

export const getSellOffer = createAsyncThunk(
  'getSellOffer',
  (id, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/sell/${id}`)
      .then((res) => {
        const shareOwningAccounts = res?.data?.shareOwningAccounts || []
        const sharesForAccount = shareOwningAccounts && shareOwningAccounts.length === 1 ? shareOwningAccounts?.[0]?.sellableShareCount : null
        let obj = null
        if (shareOwningAccounts && shareOwningAccounts.length === 1) {
          obj = shareOwningAccounts?.[0] || null
        }
        return fulfillWithValue({
          ...res.data,
          initialValues: {
            marketLastSoldPrice: formatDecimal(res?.data?.marketLastSoldPrice, false, 2),
            sharesForAccount,
            shareOwningAccount: obj
          }
        })
      })
      .catch((err) => {
        return rejectWithValue(err.response)
      })
  }
)

export const getSellOrderDetails = createAsyncThunk(
  'getSellOrderDetails',
  (id, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/sellOrderDetail/${id}`)
      .then((res) => fulfillWithValue(res.data))
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const getSellOfferSummary = createAsyncThunk(
  'getSellOfferSummary',
  ({ companyUrlName, shareCount, sharePrice, entityId }, { dispatch, fulfillWithValue }) => {
    return api
      .get(`/page/sellSummary/${companyUrlName}?shareCount=${shareCount}&sharePrice=${sharePrice}${entityId ? `&entityId=${entityId}` : ''}`)
      .then((res) => fulfillWithValue({ ...res.data }))
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const confirmSellOffer = createAsyncThunk(
  'confirmSellOffer',
  ({ companyUrlName, shareCount, sharePrice, entityId }, { dispatch, fulfillWithValue }) => {
    return api
      .post(`/page/sellSummary/${companyUrlName}/commit?shareCount=${shareCount}&sharePrice=${sharePrice}${entityId ? `&entityId=${entityId}` : ''}`)
      .then((res) => fulfillWithValue({ ...res.data }))
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const getSellOfferDetail = createAsyncThunk(
  'getSellOfferDetail',
  (id, { dispatch, fulfillWithValue }) => {
    return api
      .get(`/page/offerDetail/${id}`)
      .then((res) => fulfillWithValue({ ...res.data }))
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const getConfirmedSellOfferDetail = createAsyncThunk(
  'getConfirmedSellOfferDetail',
  (id, { dispatch, fulfillWithValue }) => {
    return api
      .get(`/page/sellSummary/confirmed/${id}`)
      .then((res) => fulfillWithValue({ ...res.data }))
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

export const getSellOrder = createAsyncThunk(
  'getSellOrder',
  (id, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/pool/sell/${id}`)
      .then((res) => {
        return fulfillWithValue({ ...res.data })
      })
      .catch((err) => {
        if (err.response?.data?.error !== 'PROFILE_INCOMPLETE') {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response?.data)
      })
  }
)

export const getSellOrderSummary = createAsyncThunk(
  'getSellOrderSummary',
  ({ companyUrlName, shareCount, entityId }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/pool/sellSummary//${companyUrlName}?shareCount=${shareCount}${entityId ? `&entityId=${entityId}` : ''}`)
      .then((res) => fulfillWithValue({ ...res.data }))
      .catch((err) => {
        if (err.response) {
          if (err.response.data?.error === 'PLACE_ORDER_ATS_OR_POOL_NOT_ENABLED') {
            return rejectWithValue(err.response.data.error)
          } else {
            dispatch(handleServerError(err.response.data.error))
          }
        }
      })
  }
)

export const confirmSellOrder = createAsyncThunk(
  'confirmSellOrder',
  ({ companyUrlName, numberOfShares, sharePrice, totalPrice, entityId }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post(`/page/pool/sellSummary/${companyUrlName}/commit?numberOfShares=${numberOfShares}&sharePrice=${sharePrice}&totalPrice=${totalPrice}${entityId ? `&entityId=${entityId}` : ''}`)
      .then((res) => fulfillWithValue({ ...res.data }))
      .catch((err) => {
        if (err.response.data?.error === 'PLACE_ORDER_ATS_OR_POOL_NOT_ENABLED' || err.response.data?.error === 'PLACE_ORDER_INSUFFICIENT_FUNDS' || err.response.data?.error === 'PLACE_ORDER_PARAMETERS_CHANGED') {
          return rejectWithValue(err.response.data.error)
        } else {
          dispatch(handleServerError(err.response.data.error))
          return rejectWithValue(err.response.data.error)
        }
      })
  }
)

export const sellOfferSlice = createSlice({
  name: 'sellOfferSlice',
  initialState,
  reducers: {
    clearTotalsState: (state) => {
      state.sharePrice = 0
      state.subTotal = 0
      state.transactionFee = 0
      state.transactionFlatFee = 0
      state.totalProceeds = 0
      state.projectedGain = 0
      state.avgOriginalHeldPricePerShare = 0
      state.totalOriginalHeldSharePrice = 0
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSellOffer.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getSellOffer.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getSellOffer.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getSellOfferSummary.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getSellOfferSummary.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getSellOfferSummary.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(confirmSellOffer.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(confirmSellOffer.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(confirmSellOffer.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getSellOfferDetail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getSellOfferDetail.fulfilled, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getSellOfferDetail.rejected, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getConfirmedSellOfferDetail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getConfirmedSellOfferDetail.fulfilled, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getConfirmedSellOfferDetail.rejected, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getSellOrderDetails.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getSellOrderDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload,
          companyIconUrl: payload?.company?.iconUrl || '',
          companyName: payload?.company?.name || ''
        }
      })
      .addCase(getSellOrder.pending, (state) => {
        return {
          ...initialState,
          pageLoading: true
        }
      })
      .addCase(getSellOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getSellOrder.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getSellOrderSummary.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(getSellOrderSummary.rejected, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(confirmSellOrder.pending, (state) => {
        state.loading = true
      })
      .addCase(confirmSellOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(confirmSellOrder.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
  }
})

export const { clearTotalsState } = sellOfferSlice.actions

export default sellOfferSlice.reducer
