import { formatDecimal, formatShares } from 'utils'

const ProductOrderSummary = ({ company }) => {
  return (
    <div className='order-list-summary-container'>
      <div className='order-list-summary'>
        <div className='order-list-summary-row'>
          <div>Investment Amount</div>
          <div className='number'>{formatDecimal(company.purchased)}</div>
        </div>
        <div className='order-list-summary-row'>
          <div>Average Cost/Share</div>
          <div className='number'>{formatDecimal(company.averageSharePrice)}</div>
        </div>
        <div className='order-list-summary-row'>
          <div>Shares</div>
          <div className='number'>{formatShares(company.shares)
          }</div>
        </div>
      </div>
    </div>
  )
}

export default ProductOrderSummary
