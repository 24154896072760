import { SET_LOADING_SPINNER, HIDE_LOADING_SPINNER, RESET_LOADING, SYSTEM_ERROR } from 'types/actionTypes'

const INITIAL_STATE = {
  spinnerVisible: false,
  status: 'init',
  isLoggedIn: false,
  isAccredited: false,
  hasUpholdWallet: false,
  downloadUrl: '',
  systemErr: false,
  faqs: [],
  title: '',
  addresses: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SET_LOADING_SPINNER:
    return { ...state, spinnerVisible: action.payload }
  case HIDE_LOADING_SPINNER:
    return { ...state, spinnerVisible: action.payload }
  case RESET_LOADING:
    return { ...state, status: INITIAL_STATE.status }
  case SYSTEM_ERROR:
    return { ...state, ...action.payload }
  default:
    return state
  }
}
