import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'

const Select = ({
  name,
  label,
  field,
  content = '',
  disabled = false,
  ariaLabel = '',
  className = '',
  options = [],
  defaultOption = null,
  handleChange = () => {},
  extraLabel = '',
  textFormat = null
}) => {
  const {
    formState: { errors },
    setValue,
    clearErrors
  } = useFormContext()

  return (
    <div className={`input-group ${errors[name] && 'error'} ${className}`}>
      <TooltipLabel content={content} label={label} extraLabel={extraLabel} />
      <select
        {...field}
        disabled={disabled}
        name={name}
        aria-label={ariaLabel}
        onChange={(e) => {
          setValue(name, e.target.value, { shouldDirty: true })
          handleChange(e.target.value)
          clearErrors(name)
        }}
      >
        {defaultOption && (
          <option value={defaultOption.value}>{defaultOption.label}</option>
        )}
        {options &&
          options.length > 0 &&
          options.map((e, i) => (
            <option value={e} key={`${i}-${e}`}>
              {textFormat ? textFormat(e) : e}
            </option>
          ))}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default Select
