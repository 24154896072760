const InvestHeader = () => {
  return (
    <div className='invest-header'>
      <h1 className='title_1 invest-title'>Explore, Analyze, Invest</h1>
      <div className='b_22_regular invest-subtitle'>
      We&apos;ve curated top private tech firms for you to invest in through Linqto. Leveraging our proprietary analysis now enriched by CB Insights data, invest in high-valuation startups or unicorns while they&apos;re still private.
      </div>
    </div>
  )
}

export default InvestHeader
