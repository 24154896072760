import { ACCstatus } from '../../data'
import { images } from 'assets'
import { useSelector } from 'react-redux'

const verfiedIcon = images['accreditation-verified-status']
const notVerfiedIcon = images['accreditation-not-verified-status']

const AccreditationStatus = ({ accreditedStatus }) => {
  const { isRetailPurchaser } = useSelector((state) => state.investorStatusSlice)

  const determineAccreditationBadge = () => {
    if (isRetailPurchaser && accreditedStatus === 'NOT_VERIFIED') {
      return 'Not Accredited'
    }
    return ACCstatus[accreditedStatus]
  }

  const accreditationStatusIcon = () => {
    if (isRetailPurchaser || accreditedStatus === 'ACCREDITED') {
      return verfiedIcon
    }
    return notVerfiedIcon
  }

  return (
    <div className='accredaition-status'>
      <img
        src={accreditationStatusIcon()}
        alt={determineAccreditationBadge()}
      />
      <span className='b_16_regular'>{determineAccreditationBadge()}</span>
    </div>
  )
}

export default AccreditationStatus
