import { images } from 'assets'
import Button from '../../../Global/Button'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const SuccessContent = () => {
  const { mfaRequired } = useSelector((state) => state.userSlice)
  const history = useHistory()

  return (
    <div className='reset-container'>
      <img alt='email-success' src={images.success} className='reset-icon' />
      <p className='b_18_regular'>You have successfully reset your password.</p>
      {mfaRequired && (
        <div className='btn-group centered'>
          <Button
            onClick={() => history.push('/signin')}
          >
            Sign in
          </Button>
        </div>
      )}
    </div>
  )
}

export default SuccessContent
