import Modal from '../../Global/Modal'
import Button from '../../Global/Button'

const ContactTeamModal = ({ hideModal, handleClick }) => {
  return (
    <>
      <Modal
        mode='primary'
        size='md'
        crossToClose
        modalHeader='Contact our team to fund account'
        hideModal={hideModal}
        innerStyle='success-modal'
      >
        <div className='b_18_regular text-center'>
          To fund your cash balance of your retirement account, contact our team.
        </div>
        <div className='btn-group centered'>
          <Button onClick={handleClick}>
            Contact Us
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ContactTeamModal
