import { formatNumberWithLetter } from 'utils'
import moment from 'moment'
import UnicornDetailsOfficers from './UnicornDetailsOfficers'

const UnicornDetailsSummary = ({ company, isLoggedIn, setShowModal }) => {
  const blueStyle = !isLoggedIn ? 'blur-info' : ''
  return (
    <>
      <div className='unicorn-details-section section-seperator section-line-seperator'>
        <div className='grid'>
          <div className='row row-with-padding'>
            <div className='column three eight-mobile unicorn-details-column'>
              <label>Total Raised</label>
              <div className={blueStyle}>{company.totalRaised ? formatNumberWithLetter(company.totalRaised, true, true, 1, true) : '-'}</div>
            </div>
            <div className='column three eight-mobile unicorn-details-column'>
              <label>Last Raised</label>
              <div className={blueStyle}>{company.lastRaised && `${formatNumberWithLetter(company.lastRaised, true, true, 1, true)} | ` } { company.lastRaisedDate && moment(company.lastRaisedDate).fromNow()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='unicorn-details-section section-seperator section-line-seperator'>
        <div className='grid'>
          <div className='row row-with-padding'>
            <div className='column three sixteen-mobile unicorn-details-column'>
              <label>Headquarters Location</label>
              {company.address && company.address.street && <div className={blueStyle}>{company.address.street ? company.address.street : '-'}</div>}
              {company.address && company.address.city && <div className={blueStyle}>{company.address.city}, {company.address.state}</div>}
              {company.address && company.address.postalCode && <div className={blueStyle}>{company.address.postalCode}</div>}
              {company.address && company.address.country && <div className={blueStyle}>{company.address.country}</div>}
            </div>
            <div className='column three sixteen-mobile unicorn-details-column mobile-section-seperator'>
              <label>Mosaic Score</label>
              {company.mosaic && company.mosaic.overall && <div className={blueStyle}>{company.mosaic.overall}</div>}
              {company.mosaic && company.mosaic.momentum && <div className={blueStyle}>Momentum - {company.mosaic.momentum}</div>}
              {company.mosaic && company.mosaic.market && <div className={blueStyle}>Market - {company.mosaic.market}</div>}
              {company.mosaic && company.mosaic.money && <div className={blueStyle}>Money - {company.mosaic.money}</div>}
              {company.mosaic && company.mosaic.management && <div className={blueStyle}>Management - {company.mosaic.management}</div>}
              {company.mosaic && <span onClick={() => setShowModal(true)}>What is a Mosaic Score?</span>}
            </div>
            <div className='column three sixteen-mobile unicorn-details-column mobile-section-seperator'>
              <label>Key Investors</label>
              <div className={blueStyle}>{company.investors ? company.investors : '-'}</div>
            </div>
          </div>
        </div>
      </div>
      {company.officers &&
        <div className='unicorn-details-section'>
          <div className='column unicorn-details-column sixteen'>
            <label className='light'>Key Officers</label>
          </div>
          <UnicornDetailsOfficers officers={company.officers} style={blueStyle} />
        </div>
      }
    </>
  )
}

export default UnicornDetailsSummary
