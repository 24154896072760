import { Modal } from 'components'
import Button from '../../Global/Button'
import { Link } from 'react-router-dom'

const HasActiveOfferErrorModal = ({ hideModal }) => {
  return (
    <Modal mode='primary' size='md' hideModal={hideModal} modalHeader='Active Offer in Progress' crossToClose>
      <div className='centered'>
        <div className='b_18_regular message'>Purchasing shares is not available while you have an active sell offer. </div>
        <br/>
        <Button onClick={() => {
          hideModal()
        }}>OK</Button>
        <div className='b_18_regular space-above-sm'>
          Need help?{' '}
          <Link to='/contact' className='link primary contact-us modal-support'>
            Contact Us
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default HasActiveOfferErrorModal
