import { countries } from 'components/FBOAddBank/partials/bankCountries'
import * as yup from 'yup'

export const schema = yup.object().shape({
  bankCountry: yup
    .string()
    .required('Please select a bank country.')
    .test('null', 'Please select a bank country.', (val) => val !== null),
  swiftBic: yup
    .string()
    .ensure()
    .when('bankCountry', {
      is: (c) => c !== countries.USA,
      then: yup
        .string()
        .required('SWIFT/BIC must be 8-11 digits.')
        .test(
          'len',
          'SWIFT/BIC must be 8-11 digits.',
          (val) => val?.length >= 8 && val?.length <= 11
        )
    }),
  accountNumber: yup
    .mixed()
    .when('bankCountry', {
      is: (c) => c === countries.CAN,
      then: yup
        .string()
        .required('Account Number must be between 7-12 digits.')
        .test(
          'len',
          'Account Number must be between 7-12 digits.',
          (val) => val?.length >= 7 && val?.length <= 12
        )
    })
    .when('bankCountry', {
      is: (c) => c === countries.USA, // eslint-disable-line no-dupe-keys
      then: yup // eslint-disable-line no-dupe-keys
        .string()
        .required('Account Number must be between 8-12 digits.')
        .test(
          'len',
          'Account Number must be between 8-12 digits.',
          (val) => val?.length >= 8 && val?.length <= 12
        )
    })
    .when('bankCountry', {
      is: (c) => c === countries.AUS || c === countries.INTL || c === countries.EU, // eslint-disable-line no-dupe-keys
      then: yup // eslint-disable-line no-dupe-keys
        .string()
        .required('Please enter the account number.')
        .test(
          'len',
          'Please enter the account number.',
          (val) => val?.length > 0
        )
    }),
  routingNumber: yup
    .string()
    .ensure()
    .when('bankCountry', {
      is: (c) => c === countries.CAN,
      then: yup
        .string()
        .test('first digit', 'Routing number must begin with a 0.', (val) => val?.charAt(0) === '0' && val?.length > 0)
        .test('len', 'Routing number must be 8 digits.', (val) => val?.length === 9)
    })
    .when('bankCountry', {
      is: (c) => c === countries.USA, // eslint-disable-line no-dupe-keys
      then: yup // eslint-disable-line no-dupe-keys
        .string()
        .test('len', 'Routing Number must be 9 digits.', (val) => val?.length === 9)
    }),
  bsb: yup
    .string()
    .ensure()
    .when('bankCountry', {
      is: (c) => c === countries.AUS,
      then: yup
        .string()
        .test('len', 'Please enter a BSB.', (val) => val?.length > 0)
        .test('len', 'BSB must be 6 digits.', (val) => val?.replace(/[_ -]/g, '')?.length === 6)
    }),
  sortCode: yup
    .string()
    .ensure()
    .when('bankCountry', {
      is: (c) => c === countries.UK,
      then: yup
        .string()
        .test('len', 'Please enter a Sort Code.', (val) => val?.length > 0)
        .test('len', 'Sort Code must be 6 digits.', (val) => val?.replace(/[_ -]/g, '')?.length === 6)
    }),
  iban: yup
    .string()
    .ensure()
    .when('bankCountry', {
      is: (c) => c === countries.UK || c === countries.EU,
      then: yup
        .string()
        .required('Please enter the IBAN.')
        .test('len', 'Please enter the IBAN.', (val) => val?.length > 0 && val?.length <= 34)
    }),
  document: yup.string().required('Please upload an account statement.')
})
