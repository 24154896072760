const parseOrderConfirmed = (data) => {
  return {
    order: {
      orderId: data?.order?.orderId,
      amount: data?.order?.amount,
      shares: data?.order?.shares,
      company: {
        iconUrl: data?.order?.company?.iconUrl,
        name: data?.order?.company?.name
      }
    }
  }
}

export default parseOrderConfirmed
