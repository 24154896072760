import UnicornDetailsKeyPartners from './UnicornDetailsKeyPartners'
import UnicornDetailsOfficers from './UnicornDetailsOfficers'

const UnicornDetailsPartners = ({ company, isLoggedIn }) => {
  const blueStyle = !isLoggedIn ? 'blur-info' : ''
  return (
    <>
      {company.partners && company.partners.length > 0 &&
        <div className='unicorn-details-section'>
          <div className='unicorn-details-column'>
            <label className='light'>Key Partners & Customers</label>
          </div>
          <UnicornDetailsKeyPartners partners={company.partners} style={blueStyle}/>
        </div>
      }
      {company.boardMemebers && company.boardMemebers.length > 0 &&
      <div className='unicorn-details-section'>
        <div className='unicorn-details-column'>
          <div>Officers & Board of Directors</div>
        </div>
        {company.boardMemebers && <UnicornDetailsOfficers officers={company.boardMemebers} style={blueStyle} />}
      </div>
      }
    </>
  )
}

export default UnicornDetailsPartners
