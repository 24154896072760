import OrderModalAccountItem from 'components/ConfrimOrder/partials/OrderModalAccountItem'
import { images } from 'assets'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const UpholdAccounts = ({
  hideModal,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction
}) => {
  const { upholdError, upholdCards } = useSelector((state) => state.walletSlice)

  return (
    <div className='order-uphold'>
      <div className='order-modal-account-header'>
        <div className='b_18_semibold'>
          <img src={images['wallet-uphold']} alt='upholad' />
          Uphold
        </div>
        <span className='b_18_regular note'>Fees may apply</span>
      </div>
      {upholdError && (
        <div className='b_18_regular order-uphold-error'>
          Please check your email. {upholdError}
        </div>
      )}
      {upholdCards?.map((account) => (
        <OrderModalAccountItem
          key={account.id}
          account={account}
          onSelect={(acc) => {
            setSelectedAccount({ ...acc, fundingMethod: 'Uphold' })
            if (insufficientError) {
              setInsufficientError(null)
            }
            hideModal()
            setShowWireInstruction(false)
            Mixpanel.track('Select Funding Method', { 'Funding Method': 'Uphold', Currency: acc.currency })
          }}
        />
      ))}
    </div>
  )
}

export default UpholdAccounts
