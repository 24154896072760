import { formatDecimal } from 'utils'

const SellOrderGains = ({ breakdown }) => {
  let { avgCostPerShare, totalCost, totalGain } = breakdown || {}
  avgCostPerShare = formatDecimal(avgCostPerShare)
  totalCost = formatDecimal(totalCost)
  totalGain = formatDecimal(totalGain)

  return (
    <>
      <div className='sell-offer-summary-container__row m-4'>
        <span className='sell-offer-summary-container__row__cost-basis share-copy'>Average Cost / Share</span>
        <span className='sell-offer-summary-container__row__cost-basis share-copy'>{avgCostPerShare}</span>
      </div>
      <div className='sell-offer-summary-container__row m-4'>
        <span className='sell-offer-summary-container__row__cost-basis share-copy'>Total Cost</span>
        <span className='sell-offer-summary-container__row__cost-basis share-copy'>{totalCost}</span>
      </div>
      <div className='sell-offer-summary-container__row footer'>
        <span className='b_18_semibold sell-offer-summary-container__row__cost-basis copy'>Total Gain</span>
        <span className='b_18_semibold sell-offer-summary-container__row__cost-basis copy'>{totalGain}</span>
      </div>
    </>
  )
}

export default SellOrderGains
