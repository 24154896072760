import { images } from 'assets'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const icon = {
  greenplus: images.greenplus,
  greenminus: images.greenminus,
  greyplus: images.greyplus,
  greyminus: images.greyminus
}

const LinqtoSlider = ({
  value = 0,
  min = 0,
  max = 1,
  step = 1,
  defaultValue = false,
  onAfterChange = () => {},
  onChange = () => {},
  onMinusBtnClick = () => {},
  onPlusBtnClick = () => {},
  disabled = false,
  plusBtnDisabled = false,
  minusBtnDisabled = false
}) => {
  return (
    <>
      <div
        className='slider-btn'
        onClick={() => {
          if (!minusBtnDisabled && !disabled) { onMinusBtnClick() }
        }}
      >
        <img
          alt='minus'
          src={
            minusBtnDisabled || disabled
              ? icon.greyminus
              : icon.greenminus
          }
        />
      </div>
      <Slider
        className='b_18_regular slider'
        railStyle={{ height: '4px' }}
        handleStyle={{
          border: 'none',
          height: '28px',
          width: '28px',
          marginTop: '-12px',
          boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12)',
          backgroundColor: disabled ? '#F4F5F4' : '#FFFFFF'
        }}
        trackStyle={{ backgroundColor: disabled ? '#899A9C' : '#0039EC', height: '4px' }}
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        onChange={onChange}
        onAfterChange={onAfterChange}
        value={value}
        disabled={disabled}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      />
      <div
        className='slider-btn'
        onClick={() => {
          if (!plusBtnDisabled && !disabled) { onPlusBtnClick() }
        }}
      >
        <img
          alt='plus'
          src={
            plusBtnDisabled || disabled
              ? icon.greyplus
              : icon.greenplus
          }
        />
      </div>
    </>
  )
}

export default LinqtoSlider
