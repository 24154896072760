import { useEffect } from 'react'
import { PageLoading } from 'components'
import Button from '../Global/Button'
import { Mixpanel, seoTitleTemplate, toCamelCase, formatDecimal } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderConfirmation } from 'slices/placeOrderSlice'
import { useQuery } from 'hooks/useQuery'

const OrderConfirmed = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()
  const { order, pageLoading } = useSelector(state => state.placeOrderSlice)

  const { orderId, company } = order
  const minimumInvestmentAmount = query.get('minimumInvestmentAmount')
  const type = toCamelCase(query.get('type'))

  useEffect(() => dispatch(getOrderConfirmation({ orderId: match.params.id })).then(({ meta, payload }) => {
    if (meta.requestStatus === 'fulfilled') {
      Mixpanel.track('View Order Confirmed Page', {
        'Company Name': payload?.order?.company?.name,
        'Minimum investment amount': minimumInvestmentAmount,
        'Amount selected': payload?.order?.amount,
        'Estimated shares': payload?.order?.shares,
        'Payment method': type,
        'Order ID': match?.params?.id
      })
    }
  }
  ), [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
        <PageLoading />
      </>
    )
  }

  if (!orderId) {
    return null
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
      <div className='page-container sell-offer-container'>
        <div className='inner-container'>
          <div className='sell-offer-header buy-order-header'>
            <h1>Order Confirmed</h1>
            <div className='sell-offer-company-name'>
              <img src={company?.iconUrl} />
              <div className='company-name'>{company?.name}</div>
            </div>
          </div>
          <div className='order-confirmed-container'>
            <div className='order-confirmed-row'>
              <span>Order ID</span>
              <span>{orderId}</span>
            </div>
            <div className='order-confirmed-row'>
              <span>Estimated Shares</span>
              <span>{order.shares}</span>
            </div>
            <div className='order-confirmed-row'>
              <span className='b_18_semibold'>Investment Amount</span>
              <span className='b_18_semibold'>{formatDecimal(order.amount)}</span>
            </div>
          </div>
          <div className='b_18_regular centered dark'>Thank you for your purchase.</div>
          <div className='btn-group two order-confirmed-cta'>
            <Button
              mode='primary-alt'
              onClick={() => {
                Mixpanel.track('Click Make Another Purchase on Buy Order Confirmation Page')
                history.push('/products')
              }}
            >Make Another Purchase</Button>
            <Button
              onClick={() => {
                Mixpanel.track('Click View Details on Buy Order Confirmation Page')
                history.push(`/order/${orderId}`)
              }}
            >View Details</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderConfirmed
