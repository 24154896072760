import { formatDecimal, formatShares } from 'utils'

const SellOrderCostBasisItem = ({ transaction, handleClick }) => {
  const { name, shares, amount } = transaction || {}
  return (
    <div className='sell-offer-summary-container__row'>
      <div className='sell-offer-summary-container__row__cost-basis order-container'>
        <span className='b_18_semibold sell-offer-summary-container__row__cost-basis order-copy pointer' onClick={handleClick}>{name}</span>
        <span className='sell-offer-summary-container__row__cost-basis share-copy'>{formatShares(shares)} shares</span>
      </div>
      <span className='sell-offer-summary-container__row__copy'>{formatDecimal(amount)}</span>
    </div>
  )
}

export default SellOrderCostBasisItem
