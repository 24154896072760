import { useFormContext } from 'react-hook-form'

const Checkbox = ({
  label,
  name,
  value,
  disabled = false,
  ariaLabel = '',
  onChange = () => {},
  checkboxClass = '',
  children
}) => {
  const { register, formState: { errors } } = useFormContext()
  return (
    <div className={`input-group checkbox ${errors[name] ? 'error' : ''} ${checkboxClass !== '' && checkboxClass}`}>
      <label className='checkbox-label'>
        <input
          type='checkbox'
          {...register(name, {
            onChange
          })}
          value={value}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        <div className='checkmark' />
        <span className='b_18_regular checkbox-label__text'>{label || children}</span>
      </label>
    </div>
  )
}

export default Checkbox
