import { Modal, Content, Wrapper, Row } from 'components'
import Button from '../../Global/Button'

const CRDSubmitSuccessModal = ({ hideModal }) => {
  return (
    <Modal mode='error' size='md' crossToClose hideModal={hideModal} modalHeader='CRD # is Under Review by Our Team'>
      <Wrapper className='crd-modal-container'>
        <Content className='container'>
          <div className='b_18_semibold'>Your CRD# was successfully submitted.</div>
          <div className='message'>Our team will review your submission right away, and
            confirm that your license is active. You’ll receive an email when you are verified.
          </div>
        </Content>
        <Row className='btn-group centered'>
          <Button onClick={hideModal}>OK</Button>
        </Row>
      </Wrapper>
    </Modal>
  )
}

export default CRDSubmitSuccessModal
