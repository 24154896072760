import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import parseOrderDetail from 'actions/parsing/parseOrderDetail'
import api from '../apis/private.js'

const initialState = {
  company: {},
  order: {},
  documents: [],
  uphold: {},
  message: 'init',
  filterOrders: [],
  pageLoading: false,
  submitLoading: false
}

export const getOrderDetail = createAsyncThunk('getOrderDetail', (id, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .get(`/page/orderDetail/${id}`)
    .then(res => {
      return fulfillWithValue({ ...res.data, order: parseOrderDetail(res.data.order) })
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDetail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getOrderDetail.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getOrderDetail.rejected, (state) => {
        state.pageLoading = false
      })
  }
})

export default orderSlice.reducer
