import Modal from '../../Global/Modal'

const AccreditationTypesModal = ({
  setShowAccreditationTypesModal,
  status
}) => {
  return (
    <Modal
      mode='primary'
      size='md'
      hideModal={() => setShowAccreditationTypesModal(false)}
      modalHeader=''
      clickToClose
      pressToClose
      crossToClose
      innerStyle='accreditation-types-modal'
    >
      <div className='b_18_regular'>
        To sign up for Linqto membership online, you must be an{' '}
        <strong>Accredited Investor</strong>. If you meet the criteria for one
        or more of the categories below, you are considered to be accredited.
        <ul>
          {Object.values(status).map((ele, id) => {
            return <li key={`li-${id}`}>{ele}</li>
          })}
        </ul>
      </div>
    </Modal>
  )
}

export default AccreditationTypesModal
