import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'actions/CommonActions'
import api from '../apis/private.js'
import parseProfile from '../actions/parsing/parsingProfile'
import _ from 'lodash'

const initialState = {
  pageLoading: false,
  userProfile: {},
  trustedContact: {},
  entity: {},
  entities: [],
  brokerInfos: [],
  hasCompletedOrder: false,
  hasKyc: true,
  isAccredited: false,
  isUserProfileComplete: false,
  submitEntityLoading: 'init',
  profileLoading: false,
  initialValues: {},
  deleteLoading: false
}

export const getUserProfile = createAsyncThunk(
  'getUserProfile',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/userProfile')
      .then((res) => {
        return fulfillWithValue({
          ...res.data,
          allSectors: res.data?.allSectors,
          trustedContact: res.data?.trustedContact || {},
          initialValues: { ...parseProfile(res.data) }
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const submitEntityCreation = createAsyncThunk(
  'submitEntityCreation',
  (_, { dispatch, rejectWithValue }) => {
    return api.post('/page/userProfile/createEntityRequest').catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
  }
)

export const editProfile = createAsyncThunk(
  'editProfile',
  (formValues, { dispatch, rejectWithValue, fulfillWithValue }) => {
    if (_.isEmpty(formValues?.trustedContact)) { delete formValues.trustedContact }
    return api
      .post('/page/userProfile', { ...formValues })
      .then((res) => {
        return fulfillWithValue({
          ...res.data,
          initialValues: { ...parseProfile(res.data) }
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)
export const deleteTrustedContact = createAsyncThunk(
  'deleteTrustedContact',
  (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return api
      .post('/page/userProfile/deleteTrustedContact')
      .then((res) => {
        return fulfillWithValue('success')
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfile: (state) => {
      state.isUserProfileComplete = false
      state.isAccredited = false
      state.hasCompletedOrder = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(submitEntityCreation.pending, (state) => {
        state.submitEntityLoading = 'loading'
      })
      .addCase(submitEntityCreation.fulfilled, (state) => {
        state.submitEntityLoading = 'success'
      })
      .addCase(submitEntityCreation.rejected, (state) => {
        state.submitEntityLoading = 'error'
      })
      .addCase(editProfile.pending, (state) => {
        state.profileLoading = true
      })
      .addCase(editProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          profileLoading: false,
          ...payload
        }
      })
      .addCase(editProfile.rejected, (state) => {
        state.profileLoading = false
      })
      .addCase(deleteTrustedContact.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteTrustedContact.fulfilled, (state, { payload }) => {
        return {
          ...state,
          deleteLoading: false,
          ...payload
        }
      })
      .addCase(deleteTrustedContact.rejected, (state) => {
        state.deleteLoading = false
      })
  }
})

export const { resetProfile } = profileSlice.actions

export default profileSlice.reducer
