import { useState } from 'react'
import Tooltip from '../../Global/Tooltip'
import BrokerListItem from './BrokerListItem'
import { useSelector } from 'react-redux'

const BrokerList = ({ tooltip }) => {
  const { brokerInfos, hasCompletedOrder } = useSelector(
    (state) => state.profile
  )

  const [showHover, setShowHover] = useState(false)
  if (hasCompletedOrder) {
    return (
      <div className='box entity-box broker-box'>
        <div className='entity-list broker-list'>
          <div
            className='entity-list-title'
            onMouseEnter={() => setShowHover(true)}
            onMouseLeave={() => setShowHover(false)}
          >
            <div className='b_18_semibold list-title'>Brokerage Account Information</div>
            <Tooltip
              content={tooltip}
              showHover={showHover}
              setShowHover={setShowHover}
            />
          </div>
          {brokerInfos.length > 0 &&
            brokerInfos.map((f, i) => (
              <BrokerListItem
                key={`broker-info-${i}`}
                name={f.name}
                brokerage={f.brokerage}
                exists={f.exists}
                entityId={f.entityId}
              />
            ))}
        </div>
      </div>
    )
  }
  return <></>
}

export default BrokerList
