import { images } from 'assets'
import { seoTitleTemplate, getDomain } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'

const NotFound = () => {
  return (
    <>
      <SeoMeta title={seoTitleTemplate('404 Not Found')} />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='not-found-container'>
            <h1>We&apos;re sorry, this page does not exist.</h1>
            <div className='or-svg-container'>
              <div className='or-line'></div>
              <img alt='404-not-found' src={images['404']} height={45} width={40} />
              <div className='or-line'></div>
            </div>
            <div className='back-to-invest-container'>
              <p>Let&apos;s get you back to investing.</p>
              <a className='btn primary-alt lg' href={getDomain('/', true)}>Home</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
