import { images } from 'assets'
import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { Mixpanel, getDomain } from 'utils'

const MobileNav = ({ items, filterNavByStatus, setToggleStatus }) => {
  const { pathname } = useLocation()
  const [toggleDropdown, setToggleDropdown] = useState('')
  const [signOut, setSignOut] = useState(null)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  // On mount, we search through the nav items to set the current dropdown based on the url pathname (if found)
  useEffect(() => {
    items.map(item => {
      if (item && item.submenu) {
        item.submenu.forEach(subItem => {
          if (subItem.route === pathname) {
            setToggleDropdown(item.name)
          }
          if (subItem.tag === 'signout') {
            setSignOut(subItem)
          }
        })
      }
    })
  }, [items])

  useEffect(() => {
    const appHeight = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    appHeight()
    window.addEventListener('resize', appHeight)
  }, [])

  const handleClick = (item) => {
    if (item.submenu && item.submenu.length) {
      setToggleDropdown(item.name === toggleDropdown ? '' : item.name)
    } else {
      Mixpanel.track('Click Main Nav', { 'Main Nav Item': item?.name })
      setToggleStatus(false)
    }
  }

  return (
    <ul className='main-nav mobile-active'>
      <div className='mobile-container'>
        {filterNavByStatus(items).map((item) => (
          <span key={item.tag} className={`animate-up ${item.name === toggleDropdown ? 'down' : 'up'}`}>
            <NavLink to={item.route !== '/' && item.route} onClick={() => handleClick(item)}>
              <div className={`mobile-nav-items ${toggleDropdown === item.name ? 'cobalt' : ''}`}>
                {item.name}
                {item.submenu && item.submenu.length && <img src={images['chevron-down']} height={20} width={20} />}
              </div>
            </NavLink>
            {item.submenu && item.submenu.length && item.submenu.map((subItem) => (
              ((toggleDropdown === item.name) && subItem.showOnMobile) ? (
                subItem.cms && window.location.hostname.includes('app') ? <a
                  key={`nav-${subItem.tag}`}
                  href={getDomain(subItem.route, subItem?.cms)}
                  onClick={() => handleClick(subItem)}
                ><p>{subItem.name}</p>
                </a>
                  : <NavLink key={subItem.tag} to={subItem.route} onClick={() => handleClick(subItem)}><p>{subItem.name}</p></NavLink>
              ) : null
            ))}
          </span>
        ))}
      </div>
      {signOut && <div className='mobile-nav-items sign-out-btn' onClick={signOut?.action}>Sign Out</div>}
    </ul>
  )
}

export default MobileNav
