import AddEntity from './AddEntity'
import Entities from './Entities'
import { useSelector } from 'react-redux'

const ProfileEntity = ({ tooltip }) => {
  const { userProfile: { entities }, isAccredited } = useSelector((state) => state.profile)
  if (isAccredited) {
    return (
      <div className='box entity-box'>
        <AddEntity />
        <Entities entities={entities} tooltip={tooltip} />
      </div>
    )
  }
  return <></>
}

export default ProfileEntity
