import React from 'react'

import { images } from 'assets'

const LinkedAccount = ({ isConnected, connect, disconnect, copy, title, linkCopy, disconnectCopy, image, accountConntected = false }) => {
  if (!accountConntected) {
    return (
      <div className='mt-20'>
        <div className='b_18_regular uphold-wallet-row uphold-wallet-row-header'>
          <div className='uphold-wallet-row uphold-wallet-row-left'>
            <img src={images[image]} alt='uphold' />
            <span>{title}</span>
          </div>
          <div>
            {!isConnected && linkCopy === 'Under Review' && (
              <span className='no-wrap link gray'>
                {linkCopy}
              </span>
            )}
            {!isConnected && linkCopy !== 'Under Review' && (
              <a className='no-wrap link primary' onClick={connect}>
                {linkCopy}
              </a>
            )}
            {isConnected && (
              <a className='no-wrap link primary' onClick={disconnect}>
                {disconnectCopy}
              </a>
            )}
          </div>
        </div>
        <div className='b_16_regular uphold-wallet-not-connected'>{copy}</div>
      </div>
    )
  } else {
    return (
      <div className='b_18_regular link-account-container'>
        <a className='link primary' onClick={connect}>Add an Account</a>
      </div>)
  }
}

export default LinkedAccount
