
const countries = {
  USA: 'United States',
  CAN: 'Canada',
  AUS: 'Australia',
  UK: 'United Kingdom and Ireland',
  EU: 'European Union',
  INTL: 'International'
}

const bankCodes = {
  'United States': 'aba',
  'Canada': 'ca_cpa', // eslint-disable-line quote-props
  'Australia': 'au_bsb', // eslint-disable-line quote-props
  'United Kingdom and Ireland': 'gb_sort_code',
  'European Union': 'swift',
  'International': 'swift' // eslint-disable-line quote-props
}
export {
  countries,
  bankCodes
}
