const parseConfirmOrder = ({ isUpholdOtpRequired, company, documents, order, isAccredited, accounts, maxOrderLinqtoBucksPercent, linqtoBucks }) => {
  return {
    isUpholdOtpRequired,
    company,
    documents: documents.map(d => ({
      name: d.name,
      url: d.url
    })),
    order: {
      ...order,
      seriesName: order.seriesName ? order.seriesName : '',
      shares: order.shares ? order.shares : '',
      sharePrice: order.sharePrice ? order.sharePrice : '',
      upholdExchangeRate: order.upholdExchangeRate ? order.upholdExchangeRate.toFixed(4) : ' ',
      linqtoFee: !isNaN(order.linqtoFee) ? order.linqtoFee : '',
      amount: order.amount ? order.amount : 0,
      total: order.total ? order.total : 0,
      entityName: order.entityName ? order.entityName : '',
      linqtoBucksUsed: order.linqtoBucksUsed ? order.linqtoBucksUsed : 0,
      riaName: order.riaName || null,
      isPriceChange: order?.isPriceChange || null
    },
    isAccredited,
    accounts,
    maxOrderLinqtoBucksPercent,
    linqtoBucks
  }
}

export default parseConfirmOrder
