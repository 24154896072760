import Button from 'components/Global/Button'

const StickyBeginOrderBtn = ({ stickyHeader }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (!stickyHeader) return null
  else {
    return (
      <div className='sticky-header'>
        <Button
          customClass='place-order-button'
          onClick={scrollToTop}
        >Begin Order</Button>
      </div>)
  }
}

export default StickyBeginOrderBtn
