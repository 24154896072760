import Modal from '../../Global/Modal'
import Button from '../../Global/Button'
import { useHistory } from 'react-router-dom'

const SuccessModal = ({ setSubmitSuccess, status }) => {
  const history = useHistory()
  const hideModal = () => {
    setSubmitSuccess(false)
    status = ''
  }

  return (
    <>
      <Modal
        mode='primary'
        size='md'
        modalHeader='Successful Save'
        innerStyle='success-modal'
        hideModal={hideModal}
      >
        <div className='b_18_regular'>
          Your broker information has been saved successfully!
        </div>
        <div className='btn-group centered'>
          <Button onClick={() => history.push('/profile')}>
            Return to Basic Information
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default SuccessModal
