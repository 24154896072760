import { useState, useEffect } from 'react'
import { ConditionalRender, PageLoading } from 'components'
import parsingUnicorns from '../../actions/parsing/parsingUnicorns'
import SeoMeta from 'components/Global/SeoMeta'
import UnicornDetailsHeader from './partials/UnicornDetailsHeader'
import UnicornDetailsTabs from './partials/UnicornDetailsTabs'
import UnicornDetailsSummary from './partials/UnicornDetailsSummary'
import UnicornDetailsFunding from './partials/UnicornDetailsFunding'
import UnicornDetailsPartners from './partials/UnicornDetailsPartners'
import UnicornMosaicModal from './partials/UnicornMosaicModal'
import { Mixpanel } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { getUnicornsDetails, setInterestedInUnicorn } from 'slices/unicornsSlice'

const UnicornDetails = ({ match }) => {
  const dispatch = useDispatch()
  const { company, isLoggedIn, buttonLoading } = useSelector(state => state.unicornsSlice)
  const [activeIndex, setActiveIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [interestedUnicron, setInterestedUnicorn] = useState({})
  const [parsedCompany, setParsedCompany] = useState({})
  const unicornId = match.params.id

  const setInterested = async (urlName, interested) => {
    const res = await dispatch(setInterestedInUnicorn({ urlName, interested }))
    if (res?.payload?.status === 200) {
      setInterestedUnicorn(interested)
      if (interested) {
        Mixpanel.track('Click I want to see this on Linqto', { 'Company Name': company.name })
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (unicornId) {
        await dispatch(getUnicornsDetails({ unicornId }))
        setLoading(false)
      }
    }
    fetchData()
  }, [unicornId])

  useEffect(() => {
    if (unicornId) {
      setParsedCompany(parsingUnicorns(company, isLoggedIn))
    }
    if (company?.name) {
      Mixpanel.track('View Market Insights Company Page', { 'Company Name': company.name })
    }
  }, [company, isLoggedIn, unicornId])

  useEffect(() => {
    setInterestedUnicorn(company.isInterested)
  }, [company.isInterested])

  if (loading) {
    return (
      <>
        <SeoMeta title={'Linqto Market Insights | Linqto | private investing made simple'} />
        <PageLoading />
      </>)
  }

  return (
    <>
      <SeoMeta title={`${company.name} | Linqto Market Insights`} />
      <div className='unicorns-details-container'>
        {company && <UnicornDetailsHeader isLoggedIn={isLoggedIn} company={parsedCompany} setInterestedUnicorn={setInterested} buttonLoading={buttonLoading} interestedUnicron={interestedUnicron}/>}
        <div className='page-container'>
          <div className='inner-container'>
            <div className='container'>
              <UnicornDetailsTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} company={parsedCompany}/>
              <ConditionalRender isVisible={activeIndex === 0 }>
                <UnicornDetailsSummary company={parsedCompany} isLoggedIn={isLoggedIn} setShowModal={setShowModal} />
              </ConditionalRender>
              <ConditionalRender isVisible={activeIndex === 1 }>
                <UnicornDetailsFunding company={parsedCompany} isLoggedIn={isLoggedIn} />
              </ConditionalRender>
              <ConditionalRender isVisible={activeIndex === 2 }>
                <UnicornDetailsPartners company={parsedCompany} isLoggedIn={isLoggedIn} />
              </ConditionalRender>
            </div>
          </div>
        </div>
      </div>
      <ConditionalRender isVisible={showModal}>
        <UnicornMosaicModal setShowModal={setShowModal}/>
      </ConditionalRender>
    </>
  )
}

export default UnicornDetails
