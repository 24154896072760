import { useEffect, useState, useRef } from 'react'
import { PageLoading, Wrapper } from 'components'
import ProductList from './partials/ProductList'
import InvestHeader from './partials/InvestHeader'
import ProductFilters from './partials/ProductFilters'
import { CircleLoader } from '../Global/ImageWithLoader'
import { useHistory } from 'react-router-dom'
import SeoMeta from 'components/Global/SeoMeta'
import _ from 'lodash'
import { seoTitleTemplate, getPageScrollPosition, setPageScrollPosition, Mixpanel } from 'utils'
import InvestAccountBanner from './partials/InvestAccountBanner'
import ScheduleMeetingsButton from '../Global/ScheduleMeetingsButton'
import { useDispatch, useSelector } from 'react-redux'
import { getInvestPage, toggleScheduleMeeting, trackScheduleMeeting } from 'slices/investSlice'
import useFirstRender from 'hooks/useFirstRender'
// import SellHowItWorksModal from 'components/Global/SellHowItWorksModal'
import LinqtoCarousel from 'components/Global/LinqtoCarousel'

const Invest = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const pageTitle = seoTitleTemplate('Invest')
  const pageDescription = 'Linqto is private investing made simple. Browse our product list to learn more about our offerings and to begin your journey in private equity investing.'
  // eslint-disable-next-line
  const { needToCompleteInvestorProfile, cashPaymentEnabled, wirePaymentEnabled, hasWalletDeposit, isLoggedIn, verticals, scheduleMeetingWithSalesUrl, showScheduleMeeting, loading, hasSellableShares } = useSelector(state => state.investSlice)
  const { investContentCards } = useSelector(state => state.commonSlice)
  const history = useHistory()
  const url = new URL(window.location)
  const params = new URLSearchParams(url.search)
  const intialSort = params.get('sort') || ''
  const intialVertical = params.get('vertical') || ''
  const intialTerm = params.get('term') || ''
  const [query, setQuery] = useState(intialTerm)
  const [vertical, setVertical] = useState(intialVertical)
  const [sort, setSort] = useState(intialSort)
  const [topScroll, setTopScroll] = useState(0)
  const searchInput = useRef()
  const sectorSelect = useRef()
  const sortSelect = useRef()
  const [showBanner, setShowBanner] = useState(false)
  // const [showSellHowItWorks, setShowSellHowItWorks] = useState(false)
  const firstRender = useFirstRender()

  const dispatch = useDispatch()

  useEffect(async () => {
    await dispatch(getInvestPage({ vertical, query, sort, firstRender }))
    setPageLoading(false)
    getPageScrollPosition()
  }, [vertical, query, sort])

  useEffect(() => {
    const onScroll = (event) => {
      setTopScroll(window.pageYOffset)
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem('dismissAccountBanner')) {
      setShowBanner(false)
    } else if (isLoggedIn && (needToCompleteInvestorProfile || (!hasWalletDeposit && cashPaymentEnabled && !wirePaymentEnabled))) {
      setShowBanner(true)
    } else {
      setShowBanner(false)
    }
  }, [isLoggedIn, needToCompleteInvestorProfile, hasWalletDeposit, cashPaymentEnabled, wirePaymentEnabled])

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = query
    }
  })

  useEffect(() => {
    if (sessionStorage.getItem('dismissScheduleMeeting')) {
      dispatch(toggleScheduleMeeting(false))
    }
  }, [showScheduleMeeting])

  // comment this out temporary so user's localstorage won't keep get set.
  // useEffect(() => {
  //   if (hasSellableShares && !localStorage.getItem('how-it-works-modal-dismissed')) {
  //     Mixpanel.track('View ATS Modal')
  //     setShowSellHowItWorks(true)
  //     localStorage.setItem('how-it-works-modal-dism issed', true)
  //   }
  // }, [hasSellableShares])

  const buildQueryURL = (sort, vertical, term) => {
    let urlString = '/products/'
    if ((sort && sort !== '') || vertical || term) {
      urlString += '?'
      if (sort && sort !== '') {
        urlString += `sort=${sort}`
      }
      if (vertical && vertical !== '') {
        urlString += `&vertical=${encodeURIComponent(vertical)}`
      }
      if (term && term !== '') {
        urlString += `&term=${term}`
      }
    }
    return urlString
  }

  const getParams = (type, value) => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    const vertical = (type === 'vertical') ? value : params.get('vertical')
    const term = (type === 'term') ? value : params.get('term')
    const sort = (type === 'sort') ? value : params.get('sort')
    return { sort, vertical, term }
  }

  const changeSearchFilter = (item, type) => {
    if (type === 'sort') setSort(item)
    if (type === 'vertical') setVertical(item)
    const { sort, vertical, term } = getParams(type, item)
    window.history.replaceState(null, null, buildQueryURL(sort, vertical, term))
  }

  const debounceFun = _.debounce(function (val) {
    Mixpanel.track('Search on Invest Page', { 'Search Term': val })
    searchInvestList(val)
  }, 250, {})

  const searchInvestList = (val) => {
    setQuery(val)
    searchInput.current.focus()
    const { sort, vertical, term } = getParams('term', val)
    window.history.replaceState(null, null, buildQueryURL(sort, vertical, term))
  }

  const showProductDetails = (urlName) => {
    setPageScrollPosition(topScroll)
    history.push(`/products/${urlName}`)
  }

  const dismissScheduleMeeting = () => {
    Mixpanel.track('Dismiss Meet with Expert')
    dispatch(trackScheduleMeeting({ type: 'dismiss' }))
    dispatch(toggleScheduleMeeting(false))
    sessionStorage.setItem('dismissScheduleMeeting', true)
  }

  const scheduleMeeting = () => {
    Mixpanel.track('Click Meet With Expert')
    dispatch(trackScheduleMeeting({ type: 'click' }))
    window.open(scheduleMeetingWithSalesUrl)
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <PageLoading />
    </>
  }

  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <Wrapper className='page-container invest-container'>
        <div className='inner-container'>
          <div className='container'>
            {showBanner && <InvestAccountBanner setShowBanner={setShowBanner} />}
            {isLoggedIn && investContentCards?.length ? (
              <div className={`carousel-container ${!showBanner ? 'm-80' : 'm-16'}`}>
                <LinqtoCarousel data={investContentCards} page='Invest' />
              </div>) : null}
            <InvestHeader />
            <ProductFilters
              changeSearchFilter={changeSearchFilter}
              verticals={verticals}
              debounceFun={debounceFun}
              searchInputRef={searchInput}
              sectorSelectRef={sectorSelect}
              sortSelectRef={sortSelect}
              vertical={vertical}
              sort={sort}
              term={query}
              setQuery={setQuery}
              loading={loading}
            />
            {!loading && <ProductList showProductDetails={showProductDetails} hasFilter={vertical || query}/>}
            {loading && <CircleLoader /> }
          </div>
        </div>
      </Wrapper>
      {showScheduleMeeting && <ScheduleMeetingsButton scheduleMeeting={scheduleMeeting} dismissScheduleMeeting={dismissScheduleMeeting} text='Meet with Expert'/>}
      {/* {showSellHowItWorks && <SellHowItWorksModal setShowSellHowItWorks={setShowSellHowItWorks} />} */}
    </>
  )
}

export default Invest
