import { useGoogleLogin } from '@react-oauth/google'
import { images } from 'assets'
import { Mixpanel } from 'utils'

const GoogleSignInBtn = ({ copy, onOAuthSuccess, type }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      onOAuthSuccess(tokenResponse.access_token, 'Google')
    },
    onError: errorResponse => console.log(errorResponse)
  })

  return <div className='b_16_semibold signup-oath-btn' onClick={() => {
    Mixpanel.track(`Click Google OAuth on Sign ${type === 'Signup' ? 'Up' : 'In'} Page`)
    googleLogin()
  }}>
    <img alt='google logo' src={images['google-btn']}/>
    {copy}
  </div>
}

export default GoogleSignInBtn
