import Button from 'components/Global/Button'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { useQuery } from 'hooks/useQuery'
import { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { confirmSellOffer, getSellOfferSummary } from 'slices/sellOfferSlice'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SellOfferHeader from '../partials/SellOfferHeader'
import SellOfferSummaryContainer from './SellOfferSummaryContainer'

const SellOfferSummary = () => {
  const query = useQuery()
  const methods = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const { handleSubmit } = methods
  const { pageLoading } = useSelector(state => state.sellOffer)

  const companyUrlName = query.get('companyUrlName')
  const shareCount = query.get('shares')
  const sharePrice = query.get('amount')
  const entityId = query.get('entityId')

  useEffect(() => {
    dispatch(getSellOfferSummary({ companyUrlName, shareCount, sharePrice, entityId })).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Create Sell Offer Review Page (ATS)', { 'Company Name': payload?.companyName })
      }
    })
  }, [])

  const onSubmit = async () => {
    const { meta, payload } = await dispatch(confirmSellOffer({ companyUrlName, shareCount, sharePrice, entityId }))
    if (meta.requestStatus === 'fulfilled') {
      history.push(`/sell-order-confirmed?offerId=${payload?.offerId}`)
    }
  }

  const handleBack = event => {
    event.preventDefault()
    history.goBack()
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Offer Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Offer Summary')} />
      <FormProvider {...methods}>
        <div className='page-container sell-offer-container'>
          <form className='inner-container' onSubmit={handleSubmit(onSubmit)}>
            <SellOfferHeader title='Offer Summary' />
            <div className='sell-offer-page-container'>
              <SellOfferSummaryContainer titleCopy='Please review your sell offer.' />
              <span className='customer-support-copy'>
              Offers remain active until cancelled or sold. All sales are final.
              </span>
              <div className='btn-group right'>
                <Button mode='tertiary' style={{ marginRight: '10px' }} onClick={handleBack}>Go Back</Button>
                <Button>Create Offer</Button>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
    </>
  )
}

export default SellOfferSummary
